import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
	Button,
	CheckBox,
	ComboBox,
	DatePicker,
	DatePickerField,
	Form,
	InputField,
	Tooltip,
	Yup,
	dateMeta,
	notifyUser,
	showFailure,
	showSuccess,
	useSimpleMessage
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

// const timeOffSchema = Yup.object().shape({
// 	fromDate: Yup.date().required(),
// 	toDate: Yup.date().required()
// });

const repeatTimeOffSchema = Yup.object().shape({
	selectedRepeatDay: Yup.string().required(),
	fromDate: Yup.date().required(),
	toDate: Yup.date().required()
});

const TimeOffForm = ({ dateOptions, alredyAppliedDays, getTimeOff, handlerModal }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [member] = useState(organisation.members?.find((m) => m.person === person.person));
	const [isPaid, setIsPaid] = useState(false);
	const [bookForOthers, setBookForOthers] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [templateSaving, setTemplateSaving] = useState(false);
	const [repeatWeekly, setRepeatWeekly] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {}, []);

	useEffect(() => {}, [selectedEmployee]);

	const { setMessage } = useSimpleMessage();
	const daysOfTheWeek = [
		{ id: 0, name: "Sunday", description: "Sunday" },
		{ id: 1, name: "Monday", description: "Monday" },
		{ id: 2, name: "Tuesday", description: "Tuesday" },
		{ id: 3, name: "Wednesday", description: "Wednesday" },
		{ id: 4, name: "Thursday", description: "Thursday" },
		{ id: 5, name: "Friday", description: "Friday" },
		{ id: 6, name: "Saturday", description: "Saturday" }
	];
	const initValues = {
		selectedRepeatDay: daysOfTheWeek[new Date().getDay()].id,
		fromDate: dateMeta(new Date()).date
	};
	let adjustedFromDate = startDate;
	let adjustedToDate = endDate;
	return (
		<>
			<h4 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">Time Off Dates</h4>

			<Form
				validationSchema={repeatWeekly ? repeatTimeOffSchema : null}
				enableReinitialize={true}
				disabled={templateSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					let adjustedFromDate = startDate;
					let adjustedToDate = endDate;

					if (startDate) {
						adjustedFromDate = new Date(startDate);
						adjustedFromDate.setHours(12, 0, 0, 0); // Adjust the time to noon
					}

					if (endDate) {
						adjustedToDate = new Date(endDate);
						adjustedToDate.setHours(12, 0, 0, 0); // Adjust the time to noon
					}
					if (repeatWeekly) {
						data.fromDate = data.fromDate ? dateMeta(data.fromDate).baseEpoch : dateMeta(new Date()).baseEpoch;
						const selectedDay = daysOfTheWeek.find((day) => day.id === data.selectedRepeatDay);
						data.selectedRepeatDay = selectedDay.name;
						data.toDate = dateMeta(data.toDate).baseEpoch;
						data.isPaid = false;
					} else {
						delete data.selectedRepeatDay;
						data.fromDate = dateMeta(adjustedFromDate).baseEpoch;
						data.toDate = dateMeta(adjustedToDate).baseEpoch;
					}
					try {
						setTemplateSaving(true);
						const memberID = bookForOthers && selectedEmployee ? selectedEmployee : member.orgUserId;
						const template = {
							...data,
							...{
								organisationID: organisation?.id,
								memberID: memberID,
								status: "PENDING",
								isPaid: isPaid
							}
						};
						await orgUtils.saveTimeOff(template);
						organisation?.owner &&
							(await notifyUser(
								[organisation.owner],
								`${personUtils.displayName(
									organisation.members.find((k) => k.person === person.person)
								)} has a time off request awaiting your approval`
							));
						setTemplateSaving(false);
						resetForm();
						await getTimeOff();
						setMessage(
							showSuccess({
								title: "Time Off Requested to Manager successfully."
							})
						);
						handlerModal("", "close");
					} catch (error) {
						console.log(error);
						setMessage(
							showFailure({
								title: "Unable to Request Time Off.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				{(person.isAdmin || person.assignedRequests) && (
					<div className="mt-2 w-full">
						<CheckBox
							label="Book for another employee?"
							name="bookForOthers"
							labelTextColour="text-gray-700 -ml-1"
							onChange={() => setBookForOthers(!bookForOthers)}
						/>
					</div>
				)}
				{bookForOthers && (
					<ComboBox
						label="Select Employee"
						name="selectedEmployee"
						labelTextColour="text-gray-700"
						options={organisation.members.map((member) => ({
							id: member?.orgUserId,
							name: personUtils.displayName(member),
							description: personUtils.displayName(member)
						}))}
						value={selectedEmployee}
						onValueChange={(value) => {
							setSelectedEmployee(value.id ? value.id : value);
						}}
					/>
				)}

				<div className="p-1 mr-2 mt-3 flex items-center">
					<input
						id="repeatWeekly"
						aria-describedby="repeatWeekly"
						name="repeatWeekly"
						type="checkbox"
						className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
						defaultChecked={repeatWeekly}
						onChange={(e) => {
							setRepeatWeekly(e.target.checked);
							setIsPaid(false);
						}}
					/>
					<Tooltip trigger={["hover"]} overlay={"Apply for time off on the selected day till the selected date"} placement="top">
						<label htmlFor="repeatWeekly" className="ml-2 block text-sm font-medium text-darkww-700">
							Repeat
						</label>
					</Tooltip>
				</div>
				<div className="w-76 px-2 w-full">
					{repeatWeekly && (
						<>
							<div className="mt-2 w-full pr-14">
								<ComboBox
									label="Repeat Time Off for"
									name="selectedRepeatDay"
									labelTextColour="text-gray-700"
									options={daysOfTheWeek}
									initValue={initValues.selectedRepeatDay}
								/>
							</div>
							<div className="mt-3 w-full">
								<p className="block text-sm font-medium text-gray-700">From date</p>
								<DatePickerField
									name="fromDate"
									minDate={new Date()}
									placeholderText="Request off starting"
									className="mt-1 border border-gray-300 rounded-md w-full"
								/>
							</div>
							<div className="mt-3 w-full">
								<p className="block text-sm font-medium text-gray-700">To Date</p>
								<DatePickerField
									name="toDate"
									minDate={new Date()}
									placeholderText="Request off till"
									className="mt-1 border border-gray-300 rounded-md w-full"
								/>
							</div>
						</>
					)}
					{!repeatWeekly && (
						<>
							<div className="mt-3 w-full">
								<p className="block text-sm font-medium text-gray-700">From Date</p>
								<DatePicker
									minDate={new Date()}
									className="mt-1 border border-gray-300 rounded-md w-full"
									autocomplete="off"
									dateFormat="dd/MM/yyyy"
									calendarStartDay={1}
									placeholderText="First Day Off"
									selectsStart
									selected={adjustedFromDate}
									startDate={adjustedFromDate}
									endDate={adjustedToDate}
									onChange={(date) => setStartDate(date)}
									isClearable={true}
								/>
							</div>
							<div className="mt-3 w-full">
								<p className="block text-sm font-medium text-gray-700">To Date</p>
								<DatePicker
									className="mt-1 border border-gray-300 rounded-md w-full"
									autocomplete="off"
									dateFormat="dd/MM/yyyy"
									calendarStartDay={1}
									placeholderText="Last Day Off"
									selectsStart
									minDate={adjustedFromDate}
									selected={adjustedToDate}
									startDate={startDate}
									endDate={adjustedToDate}
									onChange={(date) => setEndDate(date)}
									isClearable={true}
								/>
							</div>
							<div className="mt-2 w-full pl-2">
								<CheckBox
									label="Take as paid holiday?"
									name="isPaid"
									labelTextColour="text-gray-700 -ml-1"
									onChange={() => setIsPaid(!isPaid)}
								/>
							</div>
						</>
					)}
					<div className="mt-3 with-full ">
						<InputField label="Add a Note?" name="note" labelTextColour="text-dark" />
					</div>
					<div className="mt-5 flex flex-row justify-between">
						<div className="flex justify-end gap-5">
							<Button type="submit" label="Save" disabled={templateSaving} />
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

const ChooseOffDays = ({ open, handlerModal, month, timeOff, getTimeOff }) => {
	const { person } = useContext(PersonContext);
	let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;
	const allDays = timeOff?.filter((value) => value.memberID === person?.organisations?.items[activeOrg]?.id);
	const filteredMonth = month?.filter(({ date }) => new Date(date) > new Date());

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto jaii" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-72">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full flex flex-col px-2 py-3">
								<div className="">
									<TimeOffForm
										dateOptions={filteredMonth}
										alredyAppliedDays={allDays}
										getTimeOff={getTimeOff}
										handlerModal={handlerModal}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ChooseOffDays;

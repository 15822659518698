import React, { useContext, useState, useEffect } from "react";
import { formateEpochToShortMonthDate, useNavigate } from "ww-framework";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { personUtils, PersonContext, orgUtils } from "ww-stores";

export default function InvitationsList() {
	const navigate = useNavigate();

	const { person, setPerson } = useContext(PersonContext);
	const [invitations, setInvitations] = useState([]);

	const getInvitations = async (email) => {
		const invite = await personUtils.getAllInvitations(email);
		setInvitations(invite.filter((k) => k.status !== "Accepted"));
	};
	useEffect(() => {
		if (person?.email) getInvitations(person.email);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [person]);
	const acceceptInvitation = async (e, invite) => {
		const { orgMemberID, id } = invite;
		let orgMember = {};
		if (!person.id) {
			orgMember = await personUtils.saveProfile(person);
		} else {
			orgMember = person;
		}
		const newOrg = await orgUtils.updateMember(orgMemberID, orgMember);
		await personUtils.actionInvitation(id, "ACCEPT");
		const newMember = {
			...orgMember,
			...{
				currentOrganisation: {
					id: newOrg.id,
					owner: newOrg.owner,
					name: newOrg.name,
					phone: newOrg?.phone || "",
					hrEmail: newOrg?.hrEmail || "",
					paidBreak: newOrg?.paidBreak || false,
					allPaidBreak: newOrg?.allPaidBreak || false,
					disableClockIn: newOrg?.disableClockIn || false,
					enablePhoto: newOrg?.enablePhoto || false,
					address: newOrg?.address || "",
					StaffCost: newOrg?.StaffCost || ""
				}
			}
		};
		setPerson(newMember);
		await getInvitations(person?.email);
		navigate(0);
	};
	return (
		<>
			<div className="lg:max-w-6xl lg:mx-auto lg:px-8">
				<div className="mt-4 pb-6">
					<div className="flex flex-col mt-6">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
									{invitations.length > 0 ? (
										<table className="min-w-full divide-y divide-gray-200 table-auto">
											<thead className="bg-gray-50">
												<tr>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
													>
														Organisation Name
													</th>

													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
													>
														Created At
													</th>
													<th
														scope="col"
														className="pl-7 px-4 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
													>
														Status
													</th>
												</tr>
											</thead>
											<tbody className="bg-white divide-y divide-gray-200">
												{invitations.map((invite, index) => (
													<tr key={index}>
														<td className="px-6 py-2 whitespace-nowrap">
															<div className="flex items-center">
																<div className="ml-4">
																	<div className="text-sm font-medium text-gray-900">Organisation</div>
																	<div className="text-sm hidden sm:block text-gray-500">
																		{invite.orgMember.organisation.name}
																	</div>
																</div>
															</div>
														</td>
														<td className="px-6 py-2 whitespace-nowrap">
															<div className="flex items-center">
																<div className="ml-4">
																	<div className="text-sm text-gray-700">
																		{formateEpochToShortMonthDate(
																			new Date(invite?.updatedAt).valueOf() / 1000,
																			true
																		)}
																	</div>
																</div>
															</div>
														</td>
														<td className="px-6 py-2 whitespace-nowrap">
															<div className="flex items-center">
																<div className="ml-4">
																	<div className="flex flex-row">
																		{invite.status !== "Accepted" && (
																			<button
																				className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
																				onClick={(e) => acceceptInvitation(e, invite)}
																			>
																				<CheckCircleIcon className="w-6 h-6" />
																				<span className="sm:block hidden">Accept</span>
																			</button>
																		)}
																	</div>
																</div>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<h3 className="text-md font-bold text-gray-500 tracking-wide text-center py-6 mx-auto">
											You do not have any pending invitations.
											{/* <br />
											Make sure your admin have used correct email.
											<br />
											Check after You are invited. */}
											<br />
											<a href="/dashboard">Click here to Go Back to dashboard</a>
										</h3>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

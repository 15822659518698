/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      person
      firstName
      lastName
      username
      salary
      employeePhone
      emergencyNo
      avatarURI
      pin
      organisations {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        person
        firstName
        lastName
        username
        salary
        employeePhone
        emergencyNo
        avatarURI
        pin
        organisations {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      address
      phone
      StaffCost
      logoURI
      hrEmail
      vat
      paidBreak
      allPaidBreak
      enablePhoto
      disableClockIn
      startDay
      lateClockOutNotificationHours
      foodPercentage
      drinkPercentage
      staffInputhourlyRate
      staffClockInView
      staffHolidayPay
      members {
        items {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      roles {
        items {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      timeOff {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          organisationID
          fromDate
          toDate
          repeatDay
          status
          createdAt
          isPaid
          note
          updatedAt
          owner
        }
        nextToken
      }
      request {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      template {
        items {
          id
          name
          memberID
          organisationID
          shifts
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      report {
        items {
          id
          takings
          target
          till
          food
          drinks
          note
          organisationID
          reportDate
          reportDay
          yearMonth
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      giveAway {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shiftId
          shift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      message {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toMemberID
          filterChat
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readStatus
          updatedAt
          owner
        }
        nextToken
      }
      roleMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toRoleID
          toRole {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      departments {
        items {
          id
          organisationID
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscription {
        items {
          id
          organisationID
          refrenceId
          quantity
          SubscriptionStatus
          cancelAtPeriodEnd
          expiryAt
          createdAt
          updatedAt
          lastQuantityUpdatedAt
          owner
        }
        nextToken
      }
      deptMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          toDeptID
          toDept {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      orgAllMessage {
        items {
          id
          message
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      checkIns {
        items {
          id
          organisationID
          name
          duration
          unit
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      enableStaffTimeOff
      enableStaffPreferences
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        phone
        StaffCost
        logoURI
        hrEmail
        vat
        paidBreak
        allPaidBreak
        enablePhoto
        disableClockIn
        startDay
        lateClockOutNotificationHours
        foodPercentage
        drinkPercentage
        staffInputhourlyRate
        staffClockInView
        staffHolidayPay
        members {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        roles {
          items {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        timeOff {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            organisationID
            fromDate
            toDate
            repeatDay
            status
            createdAt
            isPaid
            note
            updatedAt
            owner
          }
          nextToken
        }
        request {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        template {
          items {
            id
            name
            memberID
            organisationID
            shifts
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        report {
          items {
            id
            takings
            target
            till
            food
            drinks
            note
            organisationID
            reportDate
            reportDay
            yearMonth
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        giveAway {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            shiftId
            shift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        message {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toMemberID
            filterChat
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readStatus
            updatedAt
            owner
          }
          nextToken
        }
        roleMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toRoleID
            toRole {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        departments {
          items {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscription {
          items {
            id
            organisationID
            refrenceId
            quantity
            SubscriptionStatus
            cancelAtPeriodEnd
            expiryAt
            createdAt
            updatedAt
            lastQuantityUpdatedAt
            owner
          }
          nextToken
        }
        deptMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toDeptID
            toDept {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        orgAllMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        checkIns {
          items {
            id
            organisationID
            name
            duration
            unit
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        enableStaffTimeOff
        enableStaffPreferences
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStripeSubscription = /* GraphQL */ `
  query GetStripeSubscription($id: ID!) {
    getStripeSubscription(id: $id) {
      id
      organisationID
      refrenceId
      quantity
      SubscriptionStatus
      cancelAtPeriodEnd
      expiryAt
      createdAt
      updatedAt
      lastQuantityUpdatedAt
      owner
    }
  }
`;
export const listStripeSubscriptions = /* GraphQL */ `
  query ListStripeSubscriptions(
    $filter: ModelStripeSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        refrenceId
        quantity
        SubscriptionStatus
        cancelAtPeriodEnd
        expiryAt
        createdAt
        updatedAt
        lastQuantityUpdatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrganisationMember = /* GraphQL */ `
  query GetOrganisationMember($id: ID!) {
    getOrganisationMember(id: $id) {
      id
      organisationID
      personID
      orgUsername
      email
      roleID
      departmentID
      status
      employeeFile
      huorlyRate
      employeeNu
      empRateUnit
      organisation {
        id
        name
        address
        phone
        StaffCost
        logoURI
        hrEmail
        vat
        paidBreak
        allPaidBreak
        enablePhoto
        disableClockIn
        startDay
        lateClockOutNotificationHours
        foodPercentage
        drinkPercentage
        staffInputhourlyRate
        staffClockInView
        staffHolidayPay
        members {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        roles {
          items {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        timeOff {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            organisationID
            fromDate
            toDate
            repeatDay
            status
            createdAt
            isPaid
            note
            updatedAt
            owner
          }
          nextToken
        }
        request {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        template {
          items {
            id
            name
            memberID
            organisationID
            shifts
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        report {
          items {
            id
            takings
            target
            till
            food
            drinks
            note
            organisationID
            reportDate
            reportDay
            yearMonth
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        giveAway {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            shiftId
            shift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        message {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toMemberID
            filterChat
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readStatus
            updatedAt
            owner
          }
          nextToken
        }
        roleMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toRoleID
            toRole {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        departments {
          items {
            id
            organisationID
            name
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        subscription {
          items {
            id
            organisationID
            refrenceId
            quantity
            SubscriptionStatus
            cancelAtPeriodEnd
            expiryAt
            createdAt
            updatedAt
            lastQuantityUpdatedAt
            owner
          }
          nextToken
        }
        deptMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            toDeptID
            toDept {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        orgAllMessage {
          items {
            id
            message
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        checkIns {
          items {
            id
            organisationID
            name
            duration
            unit
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        enableStaffTimeOff
        enableStaffPreferences
        createdAt
        updatedAt
        owner
      }
      person {
        id
        person
        firstName
        lastName
        username
        salary
        employeePhone
        emergencyNo
        avatarURI
        pin
        organisations {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      invitations {
        items {
          id
          orgMemberID
          email
          status
          orgMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      preferences {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          organisationID
          preferenceDate
          repeat
          repeatEndDate
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      noteParticularDay {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          note
          organisationID
          noteDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fromRequest {
        items {
          id
          organisationID
          fromMemberID
          fromMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          memberID
          toMember {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fromShiftId
          fromShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          toShiftId
          toShift {
            id
            organisationID
            baseDay
            shiftStart
            shiftFilter
            shiftEnd
            roleID
            role {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            hasBreak
            breakStart
            breakEnd
            weekStartDay
            breakDuration
            notes {
              nextToken
            }
            shiftStatus
            isShiftPublished
            unPaidBreak
            clockIn
            clockOut
            clockInBreak
            newPay
            setAsClose
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          expirationTime
          note
          updatedAt
          owner
        }
        nextToken
      }
      assignedAdmin
      assignedRequests
      assignedSuperAdmin
      isPayRoll
      disciplinary
      employeeNotes
      startDate
      completedCheckIns {
        items {
          id
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          checkInID
          organisationID
          note
          date
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrganisationMembers = /* GraphQL */ `
  query ListOrganisationMembers(
    $filter: ModelOrganisationMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisationMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      organisationID
      name
      labelColour
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      id
      organisationID
      baseDay
      shiftStart
      shiftFilter
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      weekStartDay
      breakDuration
      notes {
        items {
          id
          shiftID
          note
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shiftStatus
      isShiftPublished
      unPaidBreak
      clockIn
      clockOut
      clockInBreak
      newPay
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($id: ID!) {
    getNotes(id: $id) {
      id
      shiftID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNotess = /* GraphQL */ `
  query ListNotess(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shiftID
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPreference = /* GraphQL */ `
  query GetPreference($id: ID!) {
    getPreference(id: $id) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      organisationID
      preferenceDate
      repeat
      repeatEndDate
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        organisationID
        preferenceDate
        repeat
        repeatEndDate
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNoteParticularDay = /* GraphQL */ `
  query GetNoteParticularDay($id: ID!) {
    getNoteParticularDay(id: $id) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      note
      organisationID
      noteDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNoteParticularDays = /* GraphQL */ `
  query ListNoteParticularDays(
    $filter: ModelNoteParticularDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNoteParticularDays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        note
        organisationID
        noteDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      name
      memberID
      organisationID
      shifts
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        memberID
        organisationID
        shifts
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      organisationID
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTimeOff = /* GraphQL */ `
  query GetTimeOff($id: ID!) {
    getTimeOff(id: $id) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      organisationID
      fromDate
      toDate
      repeatDay
      status
      createdAt
      isPaid
      note
      updatedAt
      owner
    }
  }
`;
export const listTimeOffs = /* GraphQL */ `
  query ListTimeOffs(
    $filter: ModelTimeOffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeOffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        organisationID
        fromDate
        toDate
        repeatDay
        status
        createdAt
        isPaid
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      toShiftId
      toShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        memberID
        toMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fromShiftId
        fromShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        toShiftId
        toShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        expirationTime
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAllMemberSwapShift = /* GraphQL */ `
  query GetAllMemberSwapShift($id: ID!) {
    getAllMemberSwapShift(id: $id) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fromShiftId
      fromShift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAllMemberSwapShifts = /* GraphQL */ `
  query ListAllMemberSwapShifts(
    $filter: ModelAllMemberSwapShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllMemberSwapShifts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fromShiftId
        fromShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        roleID
        expirationTime
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      takings
      target
      till
      food
      drinks
      note
      organisationID
      reportDate
      reportDay
      yearMonth
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        takings
        target
        till
        food
        drinks
        note
        organisationID
        reportDate
        reportDay
        yearMonth
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGiveAway = /* GraphQL */ `
  query GetGiveAway($id: ID!) {
    getGiveAway(id: $id) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      expirationTime
      note
      updatedAt
      owner
    }
  }
`;
export const listGiveAways = /* GraphQL */ `
  query ListGiveAways(
    $filter: ModelGiveAwayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiveAways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shiftId
        shift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        expirationTime
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAllMemberGiveAway = /* GraphQL */ `
  query GetAllMemberGiveAway($id: ID!) {
    getAllMemberGiveAway(id: $id) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shiftId
      shift {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      roleID
      expirationTime
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAllMemberGiveAways = /* GraphQL */ `
  query ListAllMemberGiveAways(
    $filter: ModelAllMemberGiveAwayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllMemberGiveAways(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shiftId
        shift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        roleID
        expirationTime
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toMemberID
      filterChat
      toMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readStatus
      updatedAt
      owner
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        toMemberID
        filterChat
        toMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        readStatus
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRoleMessage = /* GraphQL */ `
  query GetRoleMessage($id: ID!) {
    getRoleMessage(id: $id) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toRoleID
      toRole {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const listRoleMessages = /* GraphQL */ `
  query ListRoleMessages(
    $filter: ModelRoleMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        toRoleID
        toRole {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDeptMessage = /* GraphQL */ `
  query GetDeptMessage($id: ID!) {
    getDeptMessage(id: $id) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      toDeptID
      toDept {
        id
        organisationID
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const listDeptMessages = /* GraphQL */ `
  query ListDeptMessages(
    $filter: ModelDeptMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeptMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        toDeptID
        toDept {
          id
          organisationID
          name
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAllMessage = /* GraphQL */ `
  query GetAllMessage($id: ID!) {
    getAllMessage(id: $id) {
      id
      message
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const listAllMessages = /* GraphQL */ `
  query ListAllMessages(
    $filter: ModelAllMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEnquiry = /* GraphQL */ `
  query GetEnquiry($id: ID!) {
    getEnquiry(id: $id) {
      id
      email
      phone
      message
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEnquirys = /* GraphQL */ `
  query ListEnquirys(
    $filter: ModelEnquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnquirys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        message
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      organisationID
      title
      description
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      comment {
        items {
          id
          comment
          memberID
          noticeId
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          readBy
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        title
        description
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        comment {
          items {
            id
            comment
            memberID
            noticeId
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            readBy
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNoticeComments = /* GraphQL */ `
  query GetNoticeComments($id: ID!) {
    getNoticeComments(id: $id) {
      id
      comment
      memberID
      noticeId
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      readBy
      updatedAt
      owner
    }
  }
`;
export const listNoticeCommentss = /* GraphQL */ `
  query ListNoticeCommentss(
    $filter: ModelNoticeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNoticeCommentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comment
        memberID
        noticeId
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStock = /* GraphQL */ `
  query GetStock($id: ID!) {
    getStock(id: $id) {
      id
      organisationID
      fromMemberID
      fromMember {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      stockLavel
      item
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listStocks = /* GraphQL */ `
  query ListStocks(
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        stockLavel
        item
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShiftTemplate = /* GraphQL */ `
  query GetShiftTemplate($id: ID!) {
    getShiftTemplate(id: $id) {
      id
      organisationID
      shiftStart
      shiftEnd
      roleID
      role {
        id
        organisationID
        name
        labelColour
        createdAt
        updatedAt
        owner
      }
      hasBreak
      breakStart
      breakEnd
      breakDuration
      unPaidBreak
      setAsClose
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listShiftTemplates = /* GraphQL */ `
  query ListShiftTemplates(
    $filter: ModelShiftTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShiftTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        shiftStart
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        breakDuration
        unPaidBreak
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      organisationID
      folderId
      name
      type
      size
      tag
      fileURI
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        folderId
        name
        type
        size
        tag
        fileURI
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      organisationID
      name
      files {
        items {
          id
          organisationID
          folderId
          name
          type
          size
          tag
          fileURI
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        name
        files {
          items {
            id
            organisationID
            folderId
            name
            type
            size
            tag
            fileURI
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExtraPayDay = /* GraphQL */ `
  query GetExtraPayDay($id: ID!) {
    getExtraPayDay(id: $id) {
      id
      organisationID
      baseDay
      newPay
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExtraPayDays = /* GraphQL */ `
  query ListExtraPayDays(
    $filter: ModelExtraPayDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtraPayDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        baseDay
        newPay
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPunch = /* GraphQL */ `
  query GetPunch($id: ID!) {
    getPunch(id: $id) {
      id
      organisationID
      shiftID
      in
      breaks
      out
      baseDay
      imageReferences
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPunchs = /* GraphQL */ `
  query ListPunchs(
    $filter: ModelPunchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPunchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        shiftID
        in
        breaks
        out
        baseDay
        imageReferences
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($id: ID!) {
    getCheckIn(id: $id) {
      id
      organisationID
      name
      duration
      unit
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationID
        name
        duration
        unit
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCompletedCheckIn = /* GraphQL */ `
  query GetCompletedCheckIn($id: ID!) {
    getCompletedCheckIn(id: $id) {
      id
      memberID
      member {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      checkInID
      organisationID
      note
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompletedCheckIns = /* GraphQL */ `
  query ListCompletedCheckIns(
    $filter: ModelCompletedCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompletedCheckIns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        checkInID
        organisationID
        note
        date
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfileByPerson = /* GraphQL */ `
  query GetProfileByPerson(
    $person: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProfileByPerson(
      person: $person
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        person
        firstName
        lastName
        username
        salary
        employeePhone
        emergencyNo
        avatarURI
        pin
        organisations {
          items {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgStripeSubscription = /* GraphQL */ `
  query GetOrgStripeSubscription(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStripeSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgStripeSubscription(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        refrenceId
        quantity
        SubscriptionStatus
        cancelAtPeriodEnd
        expiryAt
        createdAt
        updatedAt
        lastQuantityUpdatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMemberByOrgId = /* GraphQL */ `
  query GetMemberByOrgId(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrganisationMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMemberByOrgId(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        personID
        orgUsername
        email
        roleID
        departmentID
        status
        employeeFile
        huorlyRate
        employeeNu
        empRateUnit
        organisation {
          id
          name
          address
          phone
          StaffCost
          logoURI
          hrEmail
          vat
          paidBreak
          allPaidBreak
          enablePhoto
          disableClockIn
          startDay
          lateClockOutNotificationHours
          foodPercentage
          drinkPercentage
          staffInputhourlyRate
          staffClockInView
          staffHolidayPay
          members {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          roles {
            items {
              id
              organisationID
              name
              labelColour
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          timeOff {
            items {
              id
              memberID
              organisationID
              fromDate
              toDate
              repeatDay
              status
              createdAt
              isPaid
              note
              updatedAt
              owner
            }
            nextToken
          }
          request {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          template {
            items {
              id
              name
              memberID
              organisationID
              shifts
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          report {
            items {
              id
              takings
              target
              till
              food
              drinks
              note
              organisationID
              reportDate
              reportDay
              yearMonth
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          giveAway {
            items {
              id
              organisationID
              fromMemberID
              memberID
              shiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          message {
            items {
              id
              message
              organisationID
              fromMemberID
              toMemberID
              filterChat
              createdAt
              readStatus
              updatedAt
              owner
            }
            nextToken
          }
          roleMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toRoleID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          departments {
            items {
              id
              organisationID
              name
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          subscription {
            items {
              id
              organisationID
              refrenceId
              quantity
              SubscriptionStatus
              cancelAtPeriodEnd
              expiryAt
              createdAt
              updatedAt
              lastQuantityUpdatedAt
              owner
            }
            nextToken
          }
          deptMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              toDeptID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          orgAllMessage {
            items {
              id
              message
              organisationID
              fromMemberID
              createdAt
              readBy
              updatedAt
              owner
            }
            nextToken
          }
          checkIns {
            items {
              id
              organisationID
              name
              duration
              unit
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          enableStaffTimeOff
          enableStaffPreferences
          createdAt
          updatedAt
          owner
        }
        person {
          id
          person
          firstName
          lastName
          username
          salary
          employeePhone
          emergencyNo
          avatarURI
          pin
          organisations {
            items {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        invitations {
          items {
            id
            orgMemberID
            email
            status
            orgMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        preferences {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            status
            organisationID
            preferenceDate
            repeat
            repeatEndDate
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        noteParticularDay {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            note
            organisationID
            noteDate
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fromRequest {
          items {
            id
            organisationID
            fromMemberID
            fromMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            memberID
            toMember {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            fromShiftId
            fromShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            toShiftId
            toShift {
              id
              organisationID
              baseDay
              shiftStart
              shiftFilter
              shiftEnd
              roleID
              memberID
              hasBreak
              breakStart
              breakEnd
              weekStartDay
              breakDuration
              shiftStatus
              isShiftPublished
              unPaidBreak
              clockIn
              clockOut
              clockInBreak
              newPay
              setAsClose
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            expirationTime
            note
            updatedAt
            owner
          }
          nextToken
        }
        assignedAdmin
        assignedRequests
        assignedSuperAdmin
        isPayRoll
        disciplinary
        employeeNotes
        startDate
        completedCheckIns {
          items {
            id
            memberID
            member {
              id
              organisationID
              personID
              orgUsername
              email
              roleID
              departmentID
              status
              employeeFile
              huorlyRate
              employeeNu
              empRateUnit
              assignedAdmin
              assignedRequests
              assignedSuperAdmin
              isPayRoll
              disciplinary
              employeeNotes
              startDate
              createdAt
              updatedAt
              owner
            }
            checkInID
            organisationID
            note
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getInvitationsByEmail = /* GraphQL */ `
  query GetInvitationsByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgMemberID
        email
        status
        orgMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgShifts = /* GraphQL */ `
  query GetOrgShifts(
    $organisationID: ID
    $shiftStart: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgShifts(
      organisationID: $organisationID
      shiftStart: $shiftStart
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getWeekOrgShifts = /* GraphQL */ `
  query GetWeekOrgShifts(
    $organisationID: ID
    $weekStartDayShiftStart: ModelShiftGetWeekOrgShiftsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWeekOrgShifts(
      organisationID: $organisationID
      weekStartDayShiftStart: $weekStartDayShiftStart
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgWeekShifts = /* GraphQL */ `
  query GetOrgWeekShifts(
    $organisationID: ID
    $weekStartDay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgWeekShifts(
      organisationID: $organisationID
      weekStartDay: $weekStartDay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgMemberShifts = /* GraphQL */ `
  query GetOrgMemberShifts(
    $organisationID: ID
    $memberID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgMemberShifts(
      organisationID: $organisationID
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        baseDay
        shiftStart
        shiftFilter
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        weekStartDay
        breakDuration
        notes {
          items {
            id
            shiftID
            note
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        shiftStatus
        isShiftPublished
        unPaidBreak
        clockIn
        clockOut
        clockInBreak
        newPay
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNotesByShiftId = /* GraphQL */ `
  query GetNotesByShiftId(
    $shiftID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotesByShiftId(
      shiftID: $shiftID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shiftID
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPreferenceOrgMember = /* GraphQL */ `
  query GetPreferenceOrgMember(
    $memberID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPreferenceOrgMember(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        organisationID
        preferenceDate
        repeat
        repeatEndDate
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgPreferences = /* GraphQL */ `
  query GetOrgPreferences(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgPreferences(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        organisationID
        preferenceDate
        repeat
        repeatEndDate
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNoteParticularDayOrgMember = /* GraphQL */ `
  query GetNoteParticularDayOrgMember(
    $memberID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteParticularDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNoteParticularDayOrgMember(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        note
        organisationID
        noteDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgNoteParticularDay = /* GraphQL */ `
  query GetOrgNoteParticularDay(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteParticularDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgNoteParticularDay(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        note
        organisationID
        noteDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgTemplate = /* GraphQL */ `
  query GetOrgTemplate(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgTemplate(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        memberID
        organisationID
        shifts
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgTimeOff = /* GraphQL */ `
  query GetOrgTimeOff(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeOffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgTimeOff(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        organisationID
        fromDate
        toDate
        repeatDay
        status
        createdAt
        isPaid
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgMemberTimeOff = /* GraphQL */ `
  query GetOrgMemberTimeOff(
    $organisationID: ID
    $memberID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeOffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgMemberTimeOff(
      organisationID: $organisationID
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        organisationID
        fromDate
        toDate
        repeatDay
        status
        createdAt
        isPaid
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgRequest = /* GraphQL */ `
  query GetOrgRequest(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgRequest(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        memberID
        toMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fromShiftId
        fromShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        toShiftId
        toShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        expirationTime
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgMemFromRequest = /* GraphQL */ `
  query GetOrgMemFromRequest(
    $fromMemberID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgMemFromRequest(
      fromMemberID: $fromMemberID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        memberID
        toMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fromShiftId
        fromShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        toShiftId
        toShift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        expirationTime
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgReport = /* GraphQL */ `
  query GetOrgReport(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgReport(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        takings
        target
        till
        food
        drinks
        note
        organisationID
        reportDate
        reportDay
        yearMonth
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMonthOrgReports = /* GraphQL */ `
  query GetMonthOrgReports(
    $organisationID: ID
    $yearMonthReportDate: ModelReportGetMonthOrgReportsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMonthOrgReports(
      organisationID: $organisationID
      yearMonthReportDate: $yearMonthReportDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        takings
        target
        till
        food
        drinks
        note
        organisationID
        reportDate
        reportDay
        yearMonth
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgMemberGiveAway = /* GraphQL */ `
  query GetOrgMemberGiveAway(
    $organisationID: ID
    $fromMemberID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGiveAwayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgMemberGiveAway(
      organisationID: $organisationID
      fromMemberID: $fromMemberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shiftId
        shift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        expirationTime
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgGiveAway = /* GraphQL */ `
  query GetOrgGiveAway(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGiveAwayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgGiveAway(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shiftId
        shift {
          id
          organisationID
          baseDay
          shiftStart
          shiftFilter
          shiftEnd
          roleID
          role {
            id
            organisationID
            name
            labelColour
            createdAt
            updatedAt
            owner
          }
          memberID
          member {
            id
            organisationID
            personID
            orgUsername
            email
            roleID
            departmentID
            status
            employeeFile
            huorlyRate
            employeeNu
            empRateUnit
            organisation {
              id
              name
              address
              phone
              StaffCost
              logoURI
              hrEmail
              vat
              paidBreak
              allPaidBreak
              enablePhoto
              disableClockIn
              startDay
              lateClockOutNotificationHours
              foodPercentage
              drinkPercentage
              staffInputhourlyRate
              staffClockInView
              staffHolidayPay
              enableStaffTimeOff
              enableStaffPreferences
              createdAt
              updatedAt
              owner
            }
            person {
              id
              person
              firstName
              lastName
              username
              salary
              employeePhone
              emergencyNo
              avatarURI
              pin
              createdAt
              updatedAt
              owner
            }
            invitations {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            fromRequest {
              nextToken
            }
            assignedAdmin
            assignedRequests
            assignedSuperAdmin
            isPayRoll
            disciplinary
            employeeNotes
            startDate
            completedCheckIns {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          hasBreak
          breakStart
          breakEnd
          weekStartDay
          breakDuration
          notes {
            items {
              id
              shiftID
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          shiftStatus
          isShiftPublished
          unPaidBreak
          clockIn
          clockOut
          clockInBreak
          newPay
          setAsClose
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        expirationTime
        note
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgMessage = /* GraphQL */ `
  query GetOrgMessage(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgMessage(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        toMemberID
        filterChat
        toMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        readStatus
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgRoleMessage = /* GraphQL */ `
  query GetOrgRoleMessage(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoleMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgRoleMessage(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        toRoleID
        toRole {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgDeptMessage = /* GraphQL */ `
  query GetOrgDeptMessage(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeptMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgDeptMessage(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        toDeptID
        toDept {
          id
          organisationID
          name
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOrgAllMessage = /* GraphQL */ `
  query GetOrgAllMessage(
    $organisationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAllMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgAllMessage(
      organisationID: $organisationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommentsByNoticeId = /* GraphQL */ `
  query GetCommentsByNoticeId(
    $noticeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoticeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByNoticeId(
      noticeId: $noticeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        comment
        memberID
        noticeId
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        readBy
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStockByOrg = /* GraphQL */ `
  query GetStockByOrg(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStockByOrg(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        fromMemberID
        fromMember {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        stockLavel
        item
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShiftTemplateByOrg = /* GraphQL */ `
  query GetShiftTemplateByOrg(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShiftTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShiftTemplateByOrg(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        shiftStart
        shiftEnd
        roleID
        role {
          id
          organisationID
          name
          labelColour
          createdAt
          updatedAt
          owner
        }
        hasBreak
        breakStart
        breakEnd
        breakDuration
        unPaidBreak
        setAsClose
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFileByOrg = /* GraphQL */ `
  query GetFileByOrg(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFileByOrg(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        folderId
        name
        type
        size
        tag
        fileURI
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFolderByOrg = /* GraphQL */ `
  query GetFolderByOrg(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFolderByOrg(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        name
        files {
          items {
            id
            organisationID
            folderId
            name
            type
            size
            tag
            fileURI
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExtraPayByDate = /* GraphQL */ `
  query GetExtraPayByDate(
    $organisationID: ID
    $baseDay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExtraPayDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getExtraPayByDate(
      organisationID: $organisationID
      baseDay: $baseDay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        baseDay
        newPay
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPunchByDate = /* GraphQL */ `
  query GetPunchByDate(
    $organisationID: ID
    $baseDay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPunchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPunchByDate(
      organisationID: $organisationID
      baseDay: $baseDay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        shiftID
        in
        breaks
        out
        baseDay
        imageReferences
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCheckInOrgMember = /* GraphQL */ `
  query GetCheckInOrgMember(
    $memberID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCheckInOrgMember(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        member {
          id
          organisationID
          personID
          orgUsername
          email
          roleID
          departmentID
          status
          employeeFile
          huorlyRate
          employeeNu
          empRateUnit
          organisation {
            id
            name
            address
            phone
            StaffCost
            logoURI
            hrEmail
            vat
            paidBreak
            allPaidBreak
            enablePhoto
            disableClockIn
            startDay
            lateClockOutNotificationHours
            foodPercentage
            drinkPercentage
            staffInputhourlyRate
            staffClockInView
            staffHolidayPay
            members {
              nextToken
            }
            roles {
              nextToken
            }
            preferences {
              nextToken
            }
            noteParticularDay {
              nextToken
            }
            timeOff {
              nextToken
            }
            request {
              nextToken
            }
            template {
              nextToken
            }
            report {
              nextToken
            }
            giveAway {
              nextToken
            }
            message {
              nextToken
            }
            roleMessage {
              nextToken
            }
            departments {
              nextToken
            }
            subscription {
              nextToken
            }
            deptMessage {
              nextToken
            }
            orgAllMessage {
              nextToken
            }
            checkIns {
              nextToken
            }
            enableStaffTimeOff
            enableStaffPreferences
            createdAt
            updatedAt
            owner
          }
          person {
            id
            person
            firstName
            lastName
            username
            salary
            employeePhone
            emergencyNo
            avatarURI
            pin
            organisations {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          invitations {
            items {
              id
              orgMemberID
              email
              status
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          preferences {
            items {
              id
              memberID
              status
              organisationID
              preferenceDate
              repeat
              repeatEndDate
              note
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          noteParticularDay {
            items {
              id
              memberID
              note
              organisationID
              noteDate
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fromRequest {
            items {
              id
              organisationID
              fromMemberID
              memberID
              fromShiftId
              toShiftId
              status
              createdAt
              expirationTime
              note
              updatedAt
              owner
            }
            nextToken
          }
          assignedAdmin
          assignedRequests
          assignedSuperAdmin
          isPayRoll
          disciplinary
          employeeNotes
          startDate
          completedCheckIns {
            items {
              id
              memberID
              checkInID
              organisationID
              note
              date
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        checkInID
        organisationID
        note
        date
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

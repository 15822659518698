import React, { useContext, useEffect, useState } from "react";
import { Button, Form, TextArea, Yup, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils } from "ww-stores";

const employeeNoteSchema = Yup.object().shape({
	employeeNotes: Yup.string().nullable()
});

const disciplinarySchema = Yup.object().shape({
	disciplinary: Yup.string().nullable()
});

const StaffNotes = ({ currentMemberDetail, userProfile }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);
	const { members } = organisation;
	const [disciplinary, setDisciplinary] = useState("");
	const [notes, setNotes] = useState("");
	const { setMessage } = useSimpleMessage();

	const memberDetails = members.find((orgMember) => orgMember.orgUserId === currentMemberDetail.orgUserId);
	useEffect(() => {
		async function fetchUserDetails() {
			// Filter the correct organisation's details using currentOrganisation
			const currentOrgDetails = userProfile.organisations.items.find((org) => org.organisationID === loggedInPerson.currentOrganisation.id);

			if (currentOrgDetails) {
				setDisciplinary(currentOrgDetails.disciplinary ?? "");
				setNotes(currentOrgDetails.employeeNotes ?? "");
			} else {
				console.error("Current organisation details not found for the user.");
			}
		}

		fetchUserDetails();
	}, []);

	const addEmployeeNotes = async (data) => {
		try {
			await orgUtils.updateOrgMember(data, memberDetails.orgUserId);

			setMessage(
				showSuccess({
					title: `Notes Added Successfully.`
				})
			);
			setNotes(data.employeeNotes);
		} catch (err) {
			console.error("Error adding employee notes:", err);
		}
	};

	const addDisciplinary = async (data) => {
		try {
			await orgUtils.updateOrgMember(data, memberDetails.orgUserId);

			setMessage(
				showSuccess({
					title: `Disciplinary Added Successfully.`
				})
			);
			setDisciplinary(data.disciplinary);
		} catch (err) {
			console.error("Error adding disciplinary:", err);
		}
	};

	return (
		<div className="py-1">
			<div className="mt-2 pt-1 pb-3">
				<Form
					validationSchema={employeeNoteSchema}
					initialValues={{ employeeNotes: notes }}
					enableReinitialize={true}
					onSubmit={async (data, { resetForm }) => {
						try {
							addEmployeeNotes(data);
						} catch (err) {}
					}}
				>
					<div className="h-full flex pt-1 items-end col-span-9 sm:col-span-9 lg:col-span-3">
						<div className="w-full">
							<TextArea label="Employee Notes" labelTextColour="text-black" name="employeeNotes" />
						</div>
					</div>
					<div className="inline-block flex justify-end">
						<Button type="submit" label="Save Notes" />
					</div>
				</Form>
			</div>
			<hr className="my-2" />
			<div>
				<Form
					validationSchema={disciplinarySchema}
					initialValues={{ disciplinary: disciplinary }}
					enableReinitialize={true}
					onSubmit={async (data, { resetForm }) => {
						try {
							addDisciplinary(data);
						} catch (err) {}
					}}
				>
					<div className="h-full flex pt-3 items-end col-span-9 sm:col-span-9 lg:col-span-3">
						<div className="w-full">
							<TextArea label="Disciplinary" labelTextColour="text-black" name="disciplinary" />
						</div>
					</div>
					<div className="inline-block flex justify-end">
						<Button type="submit" label="Save Disciplinary" />
					</div>
				</Form>
			</div>
		</div>
	);
};

export default StaffNotes;

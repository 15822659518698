import { BellIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Avatar, Link, Menu, Transition, useLocation, useNavigate } from "ww-framework";
import logoImage from "ww-images/workwall-logo.svg";
import { OrganisationContext, PersonContext, SubscriptionContext, personUtils } from "ww-stores";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Header = ({ children, shadow = true }) => {
	const { person, setPerson } = useContext(PersonContext);
	const { setOrganisation } = useContext(OrganisationContext);
	const { subscription } = useContext(SubscriptionContext);
	const [alertVisible, setAlertVisible] = useState(true);
	const [associatedOrganisations, setAssociatedOrganisations] = useState(person?.organisations?.items ?? []);
	const navigate = useNavigate();
	let location = useLocation();

	useEffect(() => {
		setAssociatedOrganisations(person?.organisations?.items ?? []);
	}, [person]);

	const viewCalendar = () => {
		navigate(`${location.pathname === "/dashboard" ? "" : location.pathname}/calendar`, {
			state: {
				from: location.pathname || "/dashboard",
				backgroundLocation: location.pathname || "/dashboard",
				file: "/Organisation/Calendar",
				fullScreen: true
			}
		});
	};
	const switchCurrentOrg = async (index) => {
		let activeOrganisation = person.organisations.items[index];
		setPerson({ ...person, currentOrganisation: activeOrganisation });
		window.sessionStorage.setItem("activeOrg", index);
		navigate(0);
	};
	const signOut = async () => {
		const emptyPerson = await personUtils.signOut();
		// window.sessionStorage.removeItem("activeOrg");
		window.sessionStorage.removeItem("mam");
		setPerson(emptyPerson);
		setOrganisation({});
		navigate("/employee-manager-signin");
	};
	return (
		<div className="h-screen flex flex-col overflow-hidden bg-gray-100">
			{person.isAdmin && subscription?.SubscriptionStatus === "incomplete" && alertVisible && (
				<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
					<strong className="font-bold">Bad news. </strong>
					<span className="block sm:inline">Payment failed, we will retry to deduct payment again tommorow.</span>
					<span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setAlertVisible(false)}>
						<svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
							<title>Close</title>
							<path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
						</svg>
					</span>
				</div>
			)}
			<div className={`relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 ${shadow ? "shadow" : ""} lg:border-none`}>
				<div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
					<div className="flex">
						<div className="flex items-center flex-shrink-0 px-1 sm:px-4">
							<Link to="/dashboard" className="py-1 pt-2">
								<img className="h-8 w-auto sm:h-10" src={logoImage} alt="Workwall logo" />
							</Link>
							<div className="px-1">
								<Link
									to="/dashboard"
									style={{ fontFamily: `Futura, "Trebuchet MS", Arial, sans-serif`, color: `#626666` }}
									className="text-xl font-extrabold lg:text-2xl"
								>
									WorkWall
								</Link>
							</div>
						</div>
					</div>

					<div className="ml-4 flex items-center md:ml-6">
						{false && (
							<button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500">
								<span className="sr-only">View notifications</span>
								<BellIcon className="h-6 w-6" aria-hidden="true" />
							</button>
						)}
						{associatedOrganisations.length > 1 && (
							<Menu as="div" className="ml-3 relative">
								{({ open }) => (
									<>
										<Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
											<span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
												<span className="sr-only">Open user menu for </span>
												{person.currentOrganisation.name}
											</span>
											<span className="block ml-3 text-gray-700 text-sm font-medium lg:hidden">
												<span className="sr-only">Open user menu for </span>
												Locations
											</span>
											<ChevronDownIcon
												className="flex-shrink-0 ml-1 mr-2 h-4 w-4 sm:h-5 sm:w-5 text-gray-400 lg:block"
												aria-hidden="true"
											/>
										</Menu.Button>
										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items
												static
												className="origin-top-right absolute right-0 md:mr-2 mt-2 w-32 sm:w-36 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
											>
												{associatedOrganisations.map((org, index) => (
													<Menu.Item key={org.id} onClick={(e) => switchCurrentOrg(index)}>
														{() => (
															<div
																className={`block px-4 py-2 text-sm cursor-pointer ${
																	person?.currentOrganisation?.id === org?.organisation?.id
																		? "bg-lightww-600 text-white"
																		: "text-gray-700 "
																}`}
															>
																{org.organisation.name}
															</div>
														)}
													</Menu.Item>
												))}
												{(person?.isAdmin || person?.assignedAdmin) && (
													<Menu.Item>
														{() => (
															<Link
																to="/administration/report"
																className="block px-4 py-2 text-sm cursor-pointer text-gray-700"
															>
																Overall - Report
															</Link>
														)}
													</Menu.Item>
												)}
											</Menu.Items>
										</Transition>
									</>
								)}
							</Menu>
						)}
						<Menu as="div" className="ml-3 relative">
							{({ open }) => (
								<>
									<Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
										<Avatar user={person} size="h-10 w-10 mr-2" />
										<span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
											<span className="sr-only">Open user menu for </span>
											{person?.username
												? person.username
												: person?.firstName || person?.lastName
												? `${person?.firstName} ${person?.lastName}`
												: person?.organisations?.items[0]?.orgUsername || "No Name"}
										</span>
										<ChevronDownIcon className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" aria-hidden="true" />
									</Menu.Button>
									<Transition
										show={open}
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											static
											className="origin-top-right absolute right-0 md:mr-2 mt-2 w-32 sm:w-36 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
										>
											{!person?.isPayRoll && (
												<>
													<Menu.Item>
														{({ active }) => (
															<Link
																to="/dashboard"
																className={classNames(
																	active ? "bg-gray-100" : "",
																	"block px-4 py-2 text-sm text-gray-700"
																)}
															>
																Home
															</Link>
														)}
													</Menu.Item>
													<Menu.Item>
														{({ active }) => (
															<Link
																to="/profile"
																className={classNames(
																	active ? "bg-gray-100" : "",
																	"block px-4 py-2 text-sm text-gray-700"
																)}
															>
																Profile
															</Link>
														)}
													</Menu.Item>

													{!person?.isAdmin && !person?.assignedSuperAdmin && person.currentOrganisation ? (
														<div className="block">
															<Menu.Item onClick={viewCalendar}>
																{({ active }) => (
																	<div
																		className={classNames(
																			active ? "bg-gray-100 cursor-pointer" : "",
																			"block px-4 py-2 text-sm text-gray-700 cursor-pointer"
																		)}
																	>
																		Calendar
																	</div>
																)}
															</Menu.Item>
														</div>
													) : (
														(person?.isAdmin || person?.assignedSuperAdmin) && (
															<Menu.Item>
																{({ active }) => (
																	<Link
																		to="/administration"
																		className={classNames(
																			active ? "bg-gray-100" : "",
																			"block px-4 py-2 text-sm text-gray-700"
																		)}
																	>
																		Administration
																	</Link>
																)}
															</Menu.Item>
														)
													)}
													<div className="block">
														<Menu.Item>
															{({ active }) => (
																<Link
																	to="/drive"
																	className={classNames(
																		active ? "bg-gray-100" : "",
																		"block px-4 py-2 text-sm text-gray-700"
																	)}
																>
																	Documents
																</Link>
															)}
														</Menu.Item>
													</div>
													<div className="block">
														<Menu.Item>
															{({ active }) => (
																<Link
																	to="/support"
																	className={classNames(
																		active ? "bg-gray-100" : "",
																		"block px-4 py-2 text-sm text-gray-700"
																	)}
																>
																	Support
																</Link>
															)}
														</Menu.Item>
													</div>
												</>
											)}
											<div className="w-full border-t border-gray-300" />
											<Menu.Item onClick={signOut}>
												{({ active }) => (
													<div
														className={classNames(
															active ? "bg-gray-100 cursor-pointer" : "",
															"block px-4 py-2 text-sm text-gray-700 cursor-pointer"
														)}
													>
														Logout
													</div>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</>
							)}
						</Menu>
					</div>
				</div>
			</div>
			<main className="h-full flex-1 relative z-0 overflow-y-auto">{children}</main>
		</div>
	);
};

export default Header;

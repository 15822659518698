import React, { useRef, useState, useEffect } from "react";
import tw from "twin.macro";
import Features from "./components/features/ThreeColWithSideImage";
import MainFeature from "./components/features/TwoColWithButton";
import FeatureWithSteps from "./components/features/TwoColWithSteps";
import MainFeature2 from "./components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import Hero from "./components/hero/TwoColumnWithInput";
import Pricing from "./components/pricing/ThreePlans";
import AnimationRevealPage from "./helpers/AnimationRevealPage";
// import Testimonial from "./components/testimonials/TwoColumnWithImageAndRating";
import { CurrencyEuroIcon } from "@heroicons/react/outline";
import { ReactComponent as UpTrend } from "feather-icons/dist/icons/trending-up.svg";
import GetStarted from "./components/cta/GetStarted";
import FAQ from "./components/faqs/SingleCol";
import Footer from "./components/footers/FiveColumnWithBackground";
import allScreensImageSrc from "./images/all_screens.png";
import macHeroScreenshot2ImageSrc from "./images/laptop_onboarding.png";
import macHeroScreenshotImageSrc from "./images/laptop_screen.png";
import { determineCurrency, fetchLocationData } from "../../ww-framework/Utils/currencyUtils";

export const LandingPage = () => {
	const idRef = useRef(null);
	const [disableAnimation, setDisableAnimation] = useState(false);
	const Subheading = tw.span`flex justify-center uppercase tracking-widest font-bold text-lightww`;
	const HighlightedText = tw.span`text-lightww`;
	const [currencySymbol, setCurrencySymbol] = useState("€"); // Default to €

	useEffect(() => {
		// Detect the user's locale and set the currency symbol accordingly
		fetchLocationData().then((data) => {
			if (data) {
				const countryCode = data.countryCode;
				const currency = determineCurrency(countryCode);
				setCurrencySymbol(currency);
			}
		});
	}, []);

	const scrollTo = () => {
		setDisableAnimation(true);
	};
	const handleMenuClick = (value = false) => {
		setDisableAnimation(value);
	};
	return (
		<AnimationRevealPage disabled={disableAnimation} handleMenuClick={handleMenuClick}>
			<Hero roundedHeaderButton={true} scrollTo={scrollTo} />
			<Subheading>Demo Videos</Subheading>
			<div className="md:grid gap-4 grid-cols-2 mt-3">
				<iframe
					className="w-full"
					width="700"
					height="400"
					src="https://www.youtube.com/embed/B9JLXPYnpWM"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen></iframe>
				<iframe
					className="w-full mt-4 md:mt-0"
					width="700"
					height="400"
					src="https://www.youtube.com/embed/kB6Z89pgYoc"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen></iframe>
			</div>
			<Features
				id={"features"}
				subheading={<Subheading>Benefits</Subheading>}
				customRef={idRef}
				heading={
					<>
						Features designed through constant <HighlightedText> Customer Feedback.</HighlightedText>
					</>
				}
			/>

			<MainFeature2
				id={"aboutUs"}
				subheading={<Subheading>VALUES</Subheading>}
				heading={
					<>
						Our end goal is for you to <HighlightedText>increase profits.</HighlightedText>
					</>
				}
				imageSrc={allScreensImageSrc}
				showDecoratorBlob={false}
				features={[
					{
						Icon: CurrencyEuroIcon,
						title: "Immediate Results",
						description:
							"We have made our prices as affordable as possible & scalable so that every venue can increase their productivity & efficiency to start saving time & money immediately.",
						iconContainerCss: tw`bg-green-300 text-green-800`
					},
					{
						Icon: UpTrend,
						title: "Long-term Benefits",
						description:
							"In the long run, happier healthier staff means less turnover. As a result, improved experience, productivity & energy will improve customer retention.",
						iconContainerCss: tw`bg-red-300 text-red-800`
					}
				]}
			/>
			<MainFeature
				subheading={<Subheading>We feel your Pain</Subheading>}
				imageSrc={macHeroScreenshotImageSrc}
				imageBorder={false}
				imageDecoratorBlob={true}
			/>
			<FeatureWithSteps
				subheading={<Subheading>STEPS</Subheading>}
				heading={
					<>
						Easy to <HighlightedText>Get Started.</HighlightedText>
					</>
				}
				textOnLeft={false}
				imageSrc={macHeroScreenshot2ImageSrc}
				imageDecoratorBlob={false}
				decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-50 translate-x-1/2 left-1/2`}
			/>

			<Pricing
				id={"pricing"}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "blue"
				}}
				subheading={<Subheading>Pricing</Subheading>}
				heading={
					<>
						Affordable & Flexible <HighlightedText>Plans.</HighlightedText>
					</>
				}
				plans={[
					// {
					// 	name: "Personal",
					// 	price: "$17.99",
					// 	duration: "Monthly",
					// 	mainFeature: "For Individuals",
					// 	features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
					// },
					{
						name: "Flexible & all for just",
						price: `${currencySymbol}4 / user`,
						duration: "Monthly",
						mainFeature: "Introductory Price",
						features: [
							"Roster Templates",
							"Clock-In Systems",
							"Multi-Location",
							"Financial Analytics",
							"Daily Reports",
							"Messaging",
							"Daily Reminders",
							"Shift Notes",
							"Notifications",
							"Staff Costing",
							"Live Costing",
							"Overtime Alerts",
							"Absence Management",
							"Shift Swapping",
							"HR Check-ins",
							"Qualification/Visa Reminders",
							`${currencySymbol}80 min./ venue/ month`
						],
						featured: true
					}
					// {
					// 	name: "Enterprise",
					// 	price: "$57.99",
					// 	duration: "Monthly",
					// 	mainFeature: "For Large Companies",
					// 	features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
					// }
				]}
			/>
			{/* <Testimonial
				subheading={<Subheading>Testimonials</Subheading>}
				heading={
					<>
						Our Clients <HighlightedText>Love Us.</HighlightedText>
					</>
				}
				testimonials={[
					{
						stars: 5,
						profileImageSrc:
							"https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
						heading: "Amazing User Experience",
						quote: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
						customerName: "Charlotte Hale",
						customerTitle: "Director, Delos Inc."
					},
					{
						stars: 5,
						profileImageSrc:
							"https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
						heading: "Love the Developer Experience and Design Principles !",
						quote: "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
						customerName: "Adam Cuppy",
						customerTitle: "Founder, EventsNYC"
					}
				]}
			/> */}
			<FAQ
				id={"faqs"}
				subheading={<Subheading>FAQS</Subheading>}
				heading={
					<>
						You have <HighlightedText>Questions ?</HighlightedText>
					</>
				}
				faqs={[
					{
						question: "How is WorkWall a virtual staffroom?",
						answer:
							"WorkWall will create an online space where all of your employees can interact with each other, managers, their rosters and all the information they need to make sure they are kept up-to-date with what's happening in your business & when."
					},
					{
						question: "How do I save money?",
						answer:
							"Initially you will see financial gains through saved manager hours with increased team productivity & efficiency. As well-being & morale improve, huge profits can be gained by reducing staff turnover (€5,000/employee) & improving customer retention (by just 5% could mean 95% increase in profits)."
					},
					{
						question: "How much time will I save?",
						answer:
							"We understand that every venue is different but our research showed that for a venue with as little as ten staff it can take 3-4 hours to check availability, make a roster, make sure it is OK for everyone and have to find cover and make changes when it isn't. With the combination of our features we aim to reduce that to about 20 minutes every week."
					},

					{
						question: "How will WorkWall improve our communication?",
						answer:
							"Leave notes on shifts, for every employee working a particular day & make multiple requests among staff. Message boards and a designated work chat for paticular roles & departments to increase employee engagement, knowledge & awareness. All linked to immediate push notifications on mobile & desktop."
					},
					{
						question: "Where is WorkWall based?",
						answer:
							"WorkWall is an Irish company with it's founders and origins from Cork City. We want to build a product that will combine our famous Irish hospitality, skills in tech & be just as easy to get on with!"
					},

					{
						question: "What's next for WorkWall?",
						answer:
							"We have a long journey ahead where we hope to see huge improvements thoroughout various sectors of shift-based employment through a vast array of features and software. Come join us on this journey and be part of the team that helps improve the lives of thousands of employees in these sectors. Follow our our social media channels below."
					}
				]}
			/>
			<GetStarted />
			<Footer />
		</AnimationRevealPage>
	);
};

import { CameraIcon, CheckIcon, ChevronDownIcon, TagIcon } from "@heroicons/react/solid";
import { Storage } from "aws-amplify";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
	Avatar,
	Button,
	CheckBox,
	ComboBox,
	ConfirmModal,
	DayHourSegments,
	DayMinuteSegments,
	DaySegments,
	Form,
	InputField,
	LinkButton,
	Menu,
	ReactTooltip,
	Transition as Transitions,
	Yup,
	currentDateEpoch,
	secondsToHm,
	showFailure,
	useSimpleMessage
} from "ww-framework";
import { CalendarDataContext, OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const calculateTotalTime = (shiftArray) => {
	return shiftArray.reduce((previousTotal, currentShift) => {
		return previousTotal + (currentShift.shiftEnd - currentShift.shiftStart) / 60 / 60;
	}, 0);
};

const MemberListItem = ({ selected, active, option: person, preferences = [], timeOff = [] }) => {
	const memberPreference = preferences && preferences.find((value) => value.memberID === person.orgUserId);
	return (
		<div className={`${person.isWorking ? "opacity-60" : "opacity-100"} `}>
			<div className="flex items-center">
				<div style={{ backgroundColor: person?.roleLabelColour }} className="h-6 w-2 mr-2"></div>
				<Avatar user={person} size="h-6 w-6" />
				<span
					className={classNames(
						selected ? "font-semibold" : "font-normal",
						"ml-3 block truncate w-full justify-between flex p-1",
						person.isOnLeave ? "text-red-700" : "",
						memberPreference?.status === "PREFER_NOT"
							? "bg-pink-200 hover:text-gray-600"
							: memberPreference?.status === "PREFER"
							? "bg-green-200 hover:text-gray-600"
							: ""
					)}
				>
					<span>{person.id ? personUtils.displayName(person) : person.name}</span>{" "}
					<span>{person.shiftInfo ? "(" + calculateTotalTime(person.shiftInfo) + " hrs)" : ""}</span>
					{person.isOnLeave && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="ml-2 h-5 w-5  text-lg text-900 text-red-700"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth="2"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
							/>
						</svg>
					)}
				</span>
			</div>

			{selected ? (
				<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center px-4")}>
					<CheckIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			) : null}
		</div>
	);
};

const RoleListOption = ({ selected, active, option: role }) => {
	return (
		<>
			<div className="flex items-center">
				<TagIcon style={{ color: role?.labelColour }} className="h-6 w-6" aria-hidden="true" />
				<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{role?.name}</span>
			</div>

			{selected ? (
				<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
					<CheckIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			) : null}
		</>
	);
};

const ShiftForm = ({ record, activeDay, cancelCB, rebuildCalendar, memberList, preferences, timeOff, shiftPunch }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);
	const { punchData } = useContext(CalendarDataContext);
	const [staffMembers, setStaffMembers] = useState([]);
	const [shiftSaving, setShiftSaving] = useState(false);
	const [showBreak, setShowBreak] = useState(false);
	const [showImageModal, setShowImageModal] = useState(false);
	const [imageToShow, setImageToShow] = useState("");
	const [unPaidBreak, setUnPaidBreak] = useState(organisation?.paidBreak || record?.unPaidBreak ? true : false);
	// const [breakValue, setBreakValue] = useState();
	const [roles, setRoles] = useState([]);
	const [showDelete, setShowDelete] = useState(false);
	const { setMessage } = useSimpleMessage();
	const [open, setOpen] = useState(false);
	const [openWorkingModal, setOpenWorkingModal] = useState(false);
	const disableClockIn = organisation?.disableClockIn === "YES";

	const [breakSet] = useState([
		{ id: 15, name: 15, description: "15 Mins" },
		{ id: 30, name: 30, description: "30 Mins" },
		{ id: 45, name: 45, description: "45 Mins" },
		{ id: 60, name: 60, description: "60 Mins" },
		{ id: "Set", name: "Set", description: "Set" }
	]);
	const todaysEpoch = currentDateEpoch();
	const [newPay, setNewPay] = useState(record?.newPay || null);
	const [showBreakTime, setShowBreakTime] = useState(
		record?.breakDuration === (0 || null) && record?.breakStart && record?.breakEnd ? true : false
	);
	const [showCustomNewPay, setShowCustomNewPay] = useState(record.newPay && ![0, 50, 100].includes(record.newPay) ? true : false);
	const shiftPunchBreaks = shiftPunch?.breaks || [];
	const shiftSchema = Yup.object().shape({
		startHour: Yup.string().required(),
		endHour: Yup.string().required(),
		startMinute: Yup.string().required(),
		endMinute: Yup.string().required(),
		person: Yup.string().nullable(),
		role: Yup.string().required(),
		setAsClose: Yup.boolean(),
		hasBreak: Yup.boolean(),
		breakDuration: Yup.string().when("hasBreak", {
			is: (hasBreak) => hasBreak === true,
			then: Yup.string().required()
		}),
		breakStart: Yup.number().when("breakDuration", {
			is: (breakDuration) => breakDuration === "Set",
			then: Yup.number()
				.required("Required")
				.when("startHour", (startHour, schema) => {
					return schema.test({
						test: (breakStart) => !!breakStart && breakStart > parseInt(startHour) * 3600,
						message: "Break starts before shift!"
					});
				})
				.when("endHour", (endHour, schema) => {
					return schema.test({
						test: (breakStart) => !!breakStart && breakStart < parseInt(endHour) * 3600,
						message: "Break starts before shifts!"
					});
				})
		}),
		breakEnd: Yup.number().when("breakDuration", {
			is: (breakDuration) => breakDuration === "Set",
			then: Yup.number()
				.required("Required")
				.when("breakStart", (breakStart, schema) => {
					return schema.test({
						test: (breakEnd) => !!breakStart && breakEnd > breakStart,
						message: "Break ends before it even starts!"
					});
				})
				.when("endHour", (endHour, schema) => {
					return schema.test({
						test: (breakEnd) => !!breakEnd && breakEnd < parseInt(endHour) * 3600,
						message: "Break ends after shift!"
					});
				})
		})
	});
	let endTime = "";
	const breakList = ({ selected, active, option: person }) => {
		return (
			<div onClick={() => updateBreak(person.id)}>
				<div className="flex items-center">
					<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{person.name}</span>
				</div>

				{selected ? (
					<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center px-4")}>
						<CheckIcon className="h-5 w-5" aria-hidden="true" />
					</span>
				) : null}
			</div>
		);
	};

	if (record) {
		endTime = record?.shiftEnd - record?.baseDay;
		if (endTime > 86400) {
			endTime = endTime - 86400;
		}
	}
	const imageExistsForPunch = (punchType, timestamp) => {
		return shiftPunch?.imageReferences?.some((ref) => ref.includes(`${punchType}:${timestamp}`));
	};

	// Function to fetch and show image
	const showImage = async (punchType, timestamp) => {
		const imageRef = shiftPunch?.imageReferences?.find((ref) => ref.includes(`${punchType}:${timestamp}`));
		if (imageRef) {
			try {
				const imageUrl = await Storage.get(imageRef.split(":")[2]);
				setImageToShow(imageUrl);
				setShowImageModal(true);
			} catch (error) {
				console.error("Error fetching image:", error);
			}
		}
	};
	// modified secondsToHm function to return undefined if the value is 00 and returnType is hour
	const secondsToHmMod = (second, returnType) => {
		const result = secondsToHm(second, returnType);
		if (result === "00") {
			if (returnType === "hour") {
				return undefined;
			}
			if (returnType === "minute") {
				const HourResult = secondsToHm(second, "hour");
				if (HourResult === "00") {
					return undefined;
				}
			}
		}
		return result;
	};

	const initValues = {
		id: record?.id || "",
		startHour: secondsToHmMod(record?.shiftStart - record?.baseDay, "hour") || "12",
		startMinute: secondsToHmMod(record?.shiftStart - record?.baseDay, "minute") || "00",
		endHour: record.setAsClose
			? secondsToHmMod(endTime, "hour") || "00"
			: endTime === 86400
			? "00"
			: endTime !== null && endTime !== undefined
			? secondsToHmMod(endTime, "hour") || "00"
			: "20",
		endMinute: endTime === 86400 ? "00" : endTime !== null && endTime !== undefined ? secondsToHmMod(endTime, "minute") || "00" : "00",
		person: record?.memberID || null,
		role: record?.roleID || "",
		setAsClose: record?.setAsClose || false,
		hasBreak: record?.hasBreak || false,
		unPaidBreak: organisation?.paidBreak || record?.unPaidBreak ? true : false,
		breakDuration: record?.breakDuration > 0 ? record?.breakDuration : record?.breakStart && record?.breakEnd ? "Set" : 0,
		breakStart: record?.hasBreak > 0 && record?.breakStart - record?.baseDay > 0 ? record?.breakStart - record?.baseDay : "",
		breakEnd: record?.hasBreak > 0 && record?.breakStart - record?.baseDay > 0 ? record?.breakEnd - record?.baseDay : "",
		punchInH: secondsToHmMod(shiftPunch?.in - shiftPunch?.baseDay, "hour") || undefined,
		punchInM: secondsToHmMod(shiftPunch?.in - shiftPunch?.baseDay, "minute") || undefined,
		punchOutH: secondsToHmMod(shiftPunch?.out - shiftPunch?.baseDay, "hour") || undefined,
		punchOutM: secondsToHmMod(shiftPunch?.out - shiftPunch?.baseDay, "minute") || undefined,
		customPayRate: record?.newPay,
		breaks: shiftPunchBreaks
	};
	useEffect(() => {
		if (initValues.id === "" && organisation?.paidBreak === true) {
			initValues.unPaidBreak = true;
			setUnPaidBreak(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation, initValues.id, initValues.unPaidBreak]);
	const deleteShift = async (shiftId) => {
		try {
			await orgUtils.deleteShift(shiftId);
			const punch = punchData.find((l) => l?.shiftID === shiftId);
			if (punch) {
				await orgUtils.deletePunch(punch.id);
			}
			rebuildCalendar(activeDay.date);
			cancelCB();
			/*setMessage(
		showSuccess({
		  title: "Shift removed successfully."
		})
	  );*/
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to remove Shift.",
					subTitle: error.message
				})
			);
		}
	};

	useEffect(() => {
		const { members, roles } = organisation;
		const memberTimeOff = timeOff.filter((k) => k.fromDate <= activeDay.baseEpoch && k.toDate >= activeDay.baseEpoch);
		const membersCombo = members.map((member, index) => {
			const isWorking = activeDay?.allUnfilteredShift.find((shift) => shift.memberID === member.orgUserId) ? true : false;
			const isOnLeave = memberTimeOff.filter((e) => e.memberID === member.orgUserId).length > 0 ? true : false;
			return {
				...member,
				...{
					id: member.orgUserId,
					description: personUtils.displayName(member),
					isWorking: isWorking,
					shiftInfo: memberList[member.orgUserId],
					isOnLeave: isOnLeave
				}
			};
		});
		membersCombo.sort((a, b) => a.isWorking - b.isWorking);
		membersCombo.sort((x, y) => (x.isOnLeave === y.isOnLeave ? 0 : x.isOnLeave ? 1 : -1));
		membersCombo.unshift({
			id: null,
			name: "(Empty)",
			description: "(Empty)",
			isWorking: false
		});
		setStaffMembers(membersCombo);
		const orgRoles = Array.isArray(roles) ? roles : Array.isArray(roles?.items) ? roles.items : [];
		const rolesCombo = orgRoles.map((role) => {
			return { ...role, description: role?.name };
		});
		setRoles(rolesCombo); // TODO: fix the way "roles" is built up in the system. It's inconsistent, should be set in organisation.js for consistency.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	useEffect(() => {
		setShowBreak(initValues.hasBreak);
	}, [initValues.hasBreak]);

	const updateBreak = (value) => {
		if (value === "Set") {
			setShowBreakTime(true);
		} else {
			setShowBreakTime(false);
		}
		// setBreakValue(value);
	};
	const updateNewPay = (value) => {
		setNewPay(value);
		if (value === "CUSTOM") {
			setShowCustomNewPay(true);
		} else {
			setShowCustomNewPay(false);
		}
	};
	const selectedStaff = async (init, value) => {
		if (value?.isOnLeave && init !== value.orgUserId) {
			setOpen(true);
		} else if (value?.isWorking && init !== value.orgUserId) {
			setOpenWorkingModal(true);
		}
	};

	const handlerModal = (e, type) => {
		e.preventDefault();
		if (type === "onLeave") {
			setOpen(false);
		} else if (type === "working") {
			setOpenWorkingModal(false);
		}
	};

	const updateMemberList = async (role) => {
		if (!initValues.role && initValues.person) return;
		const { id, labelColour } = role;
		const { members } = organisation;
		const membersCombo = members.map((member) => {
			const memberTimeOff = timeOff.filter((k) => k.fromDate <= activeDay.baseEpoch && k.toDate >= activeDay.baseEpoch);
			const isWorking = activeDay?.shifts.find((shift) => shift.memberID === member.orgUserId) ? true : false;
			const isOnLeave = memberTimeOff.filter((e) => e.memberID === member.orgUserId).length > 0 ? true : false;

			const selectedRole = member?.roleIDs && member?.roleIDs.includes(id) ? true : false;
			return {
				...member,
				...{
					id: member.orgUserId,
					description: personUtils.displayName(member),
					isWorking: isWorking,
					shiftInfo: memberList[member.orgUserId],
					selectedRole: selectedRole,
					roleLabelColour: selectedRole ? labelColour : null,
					isOnLeave: isOnLeave
				}
			};
		});
		membersCombo.sort((a, b) => a.isWorking - b.isWorking);
		membersCombo.sort((x, y) => (x.selectedRole === y.selectedRole ? 0 : x.selectedRole ? -1 : 1));
		membersCombo.sort((x, y) => (x.isOnLeave === y.isOnLeave ? 0 : x.isOnLeave ? 1 : -1));
		membersCombo.unshift({
			id: null,
			name: "(Empty)",
			description: "(Empty)",
			isWorking: false
		});
		setStaffMembers(membersCombo);
	};
	const BreakFields = React.memo(() => {
		return (
			<>
				{shiftPunchBreaks.map((breakData, index) => {
					const { start, end } = JSON.parse(breakData);
					return (
						<div key={index} className="mt-2">
							{/* Break Start */}
							<div className="flex items-center justify-between">
								<label className="text-sm font-medium text-white">Break Start</label>
								{imageExistsForPunch("break-in", start) && (
									<button type="button" onClick={() => showImage("break-in", start)} className="ml-2">
										<CameraIcon className="h-5 w-5 text-white" />
									</button>
								)}
							</div>
							<div className="flex justify-center mt-1">
								<InputField
									id={`${"breaks[" + index + "]punchBreakStartH"}`}
									name={`${"breaks[" + index + "]punchBreakStartH"}`}
									initValue={secondsToHm(start - shiftPunch.baseDay, "hour") || "00"}
									labelTextColour="text-white"
								/>
								<div className="text-white px-4 self-center">:</div>
								<InputField
									id={`${"breaks[" + index + "]punchBreakStartM"}`}
									name={`${"breaks[" + index + "]punchBreakStartM"}`}
									initValue={secondsToHm(start - shiftPunch.baseDay, "minute") || "00"}
									labelTextColour="text-white"
								/>
							</div>

							{/* Break End */}
							<div className="flex items-center justify-between mt-2">
								<label className="text-sm font-medium text-white">Break End</label>
								{imageExistsForPunch("break-out", end) && (
									<button type="button" onClick={() => showImage("break-out", end)} className="ml-2">
										<CameraIcon className="h-5 w-5 text-white" />
									</button>
								)}
							</div>
							<div className="flex justify-center mt-1">
								<InputField
									id={`${"breaks[" + index + "]punchBreakEndH"}`}
									name={`${"breaks[" + index + "]punchBreakEndH"}`}
									initValue={secondsToHm(end - shiftPunch.baseDay, "hour") || "00"}
									labelTextColour="text-white"
								/>
								<div className="text-white px-4 self-center">:</div>
								<InputField
									id={`${"breaks[" + index + "]punchBreakEndM"}`}
									name={`${"breaks[" + index + "]punchBreakEndM"}`}
									initValue={secondsToHm(end - shiftPunch.baseDay, "minute") || "00"}
									labelTextColour="text-white"
								/>
							</div>
						</div>
					);
				})}
			</>
		);
	});

	return (
		<>
			<ConfirmModal
				handlerModal={(e) => handlerModal(e, "onLeave")}
				open={open}
				className="text-base font-medium text-gray-900"
				title="This employee has booked this day off"
				subTitle="Are you sure?"
				confirm="Assign this Employee"
				cancel="Choose another"
			/>

			<ConfirmModal
				handlerModal={(e) => handlerModal(e, "working")}
				open={openWorkingModal}
				className="text-base font-medium text-gray-900"
				title="This employee is already working this day"
				subTitle="Are you sure?"
				confirm="Assign this Employee"
				cancel="Choose another"
			/>

			{showImageModal && (
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						{/* Background overlay, close modal on click */}
						<div
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							aria-hidden="true"
							onClick={() => setShowImageModal(false)}
						></div>

						{/* Modal content */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
										<img src={imageToShow} alt="Punch Image" className="w-full" />
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-darkww-400 text-base font-medium text-white hover:bg-lightww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => setShowImageModal(false)}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			<Form
				validationSchema={shiftSchema}
				disabled={shiftSaving}
				initialValues={initValues}
				enableReinitialize={true}
				onSubmit={async (data, { resetForm }) => {
					try {
						setShiftSaving(true);
						const { members } = organisation;

						// Get the original shift details (assuming these are available in the `record` prop)
						const originalShiftDetails = {
							startHour: secondsToHmMod(record?.shiftStart - record?.baseDay, "hour"),
							startMinute: secondsToHmMod(record?.shiftStart - record?.baseDay, "minute"),
							endHour: secondsToHmMod(record?.shiftEnd - record?.baseDay, "hour"),
							endMinute: secondsToHmMod(record?.shiftEnd - record?.baseDay, "minute"),
							role: record?.roleID,
							person: record?.memberID,
							newPay: record?.newPay
						};

						const shiftDetails = {
							...data,
							...{
								newPay: newPay === "CUSTOM" ? parseInt(data?.customPayRate) : parseInt(newPay),
								organisationID: organisation.id,
								day: activeDay,
								start: data.startHour * 60 * 60 + data.startMinute * 60,
								end: data.endHour * 60 * 60 + data.endMinute * 60,
								breakStart: data.breakStart ? parseInt(activeDay.baseEpoch + data.breakStart) : null,
								breakEnd: data.breakEnd ? parseInt(activeDay.baseEpoch + data.breakEnd) : null
							}
						};

						const selectedMember = members.find((m) => m.orgUserId === data.person);
						if (selectedMember?.isWorking) {
							setOpenWorkingModal(true);
							setShiftSaving(false);
							return;
						}

						if (data?.person) {
							const member = members.find((m) => m.orgUserId === data.person);
							if (member && member.email && loggedInPerson.organisations.items.length > 0) {
								const allOtherOrganisations = loggedInPerson.organisations.items
									.filter((k) => k.organisationID !== loggedInPerson.currentOrganisation.id)
									.map((orgmember) => orgmember.organisation);

								const shiftsInOtherLocations = await Promise.all(
									allOtherOrganisations.map(async (org) => {
										let data = await orgUtils.getDayShifts(org.id, activeDay?.baseEpoch);
										return data?.items;
									})
								);

								const otherShift = shiftsInOtherLocations.flat().find((k) => k?.member?.email === member.email);
								if (otherShift) {
									setShiftSaving(false);
									console.log("Shift conflict found:", otherShift);
									return setMessage(
										showFailure({
											title: "Unable to save Shift.",
											subTitle: `Employee is already rostered in ${otherShift?.member?.organisation?.name}`
										})
									);
								}
							}
						}

						// Check if there are changes in shift details compared to the original shift
						const isShiftChange =
							data.startHour !== originalShiftDetails.startHour ||
							data.startMinute !== originalShiftDetails.startMinute ||
							data.endHour !== originalShiftDetails.endHour ||
							data.endMinute !== originalShiftDetails.endMinute ||
							data.role !== originalShiftDetails.role ||
							data.person !== originalShiftDetails.person ||
							(newPay !== undefined && newPay !== originalShiftDetails.newPay) ||
							data.breakDuration !== originalShiftDetails.breakDuration;

						// Check if changes are exclusively related to punch data
						const punchFields = ["punchInH", "punchInM", "punchOutH", "punchOutM", "breaks"];
						const isPunchChange = punchFields.some((field) => data[field] !== undefined && data[field] !== "");
						const isOnlyPunchChange = isPunchChange && !isShiftChange;

						// Save the shift only if there are actual shift changes
						if (isShiftChange) {
							await orgUtils.saveShift(shiftDetails);
						}
						// else if (isOnlyPunchChange) :  Only punch data changes detected, skipping shift save.

						// Save punch data if present, independently of shift save
						if (shiftPunch && shiftPunch?.id) {
							const breaks = data?.breaks?.map((l) =>
								JSON.stringify({
									start: parseInt(l.punchBreakStartH) * 60 * 60 + parseInt(l.punchBreakStartM) * 60 + shiftPunch.baseDay,
									end: parseInt(l.punchBreakEndH) * 60 * 60 + parseInt(l.punchBreakEndM) * 60 + shiftPunch.baseDay
								})
							);

							const pundhData = {
								...shiftPunch,
								in: parseInt(data.punchInH) * 60 * 60 + parseInt(data.punchInM) * 60 + shiftPunch.baseDay,
								breaks: breaks ?? [],
								out: parseInt(data.punchOutH) * 60 * 60 + parseInt(data.punchOutM) * 60 + shiftPunch.baseDay
							};

							await orgUtils.savePunchOfMember(pundhData);
						} else if (
							shiftDetails.id &&
							activeDay.baseEpoch <= todaysEpoch &&
							(parseInt(shiftDetails.punchInM) !== 0 ||
								parseInt(shiftDetails.punchInH) !== 0 ||
								parseInt(shiftDetails.punchOutH) !== 0 ||
								parseInt(shiftDetails.punchOutM) !== 0)
						) {
							if (
								data.punchInH ||
								(shiftDetails.punchInH !== undefined &&
									shiftDetails.punchInM !== undefined &&
									shiftDetails.punchOutH !== undefined &&
									shiftDetails.punchOutM !== undefined)
							) {
								const pundhData = {
									shiftID: shiftDetails.id,
									organisationID: shiftDetails.organisationID,
									in: parseInt(shiftDetails.punchInH) * 60 * 60 + parseInt(shiftDetails.punchInM) * 60 + activeDay.baseEpoch,
									out: parseInt(shiftDetails.punchOutH) * 60 * 60 + parseInt(shiftDetails.punchOutM) * 60 + activeDay.baseEpoch,
									baseDay: activeDay.baseEpoch
								};
								// Saving Punch Data for Existing Shift:
								await orgUtils.savePunchOfMember(pundhData);
							}
							// else : Punch data not defined, skipping punch creation
						}

						rebuildCalendar(activeDay.date);
						setShiftSaving(false);
						resetForm();
						cancelCB();
					} catch (error) {
						console.error("Error in Shift Form Submission:", error);
						setShiftSaving(false);
						setMessage(
							showFailure({
								title: "Unable to save Shift.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-72 flex flex-col pl-2 sm:pl-10">
					<div className="mt-2 -ml-2 width-full flex justify-between">
						<div className=" max-w-[115px]">
							<Menu as="div" className="relative">
								{({ open }) => (
									<div
										label="pay rate"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<Menu.Button className="text-sm font-medium text-white border-2 ml-0.5 mb-0.5 border-transparent rounded-md px-1 flex justify-start items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500">
											Pay Rate
											<ChevronDownIcon className="h-4 w-4 text-white-700 sm:block" aria-hidden="true" />
										</Menu.Button>

										<Transitions
											show={open}
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute mt-2 px-1.5 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
												<div className="sm:block" onClick={() => updateNewPay(0)}>
													<div className="ml-1.5 underline">Pay Rate</div>
													<Menu.Item>
														<div
															className={`block px-2 py-1.5 text-sm cursor-pointer ${
																newPay === 0 ? "bg-lightww-600 text-white" : "text-gray-700"
															}`}
														>
															Standard
														</div>
													</Menu.Item>
												</div>
												<div className="sm:block" onClick={() => updateNewPay(50)}>
													<Menu.Item>
														<div
															className={`block px-2 py-1.5 text-sm cursor-pointer ${
																newPay === 50 ? "bg-lightww-600 text-white" : "text-gray-700"
															}`}
														>
															+50 %
														</div>
													</Menu.Item>
												</div>
												<div className="sm:block" onClick={() => updateNewPay(100)}>
													<Menu.Item>
														<div
															className={`block px-2 py-1.5 text-sm cursor-pointer ${
																newPay === 100 ? "bg-lightww-600 text-white" : "text-gray-700"
															}`}
														>
															+100 %
														</div>
													</Menu.Item>
												</div>
												<div className="sm:block" onClick={() => updateNewPay("CUSTOM")}>
													<Menu.Item>
														<div
															className={`block px-2 py-1.5 text-sm cursor-pointer ${
																newPay && ![0, 50, 100].includes(newPay)
																	? "bg-lightww-600 text-white"
																	: "text-gray-700"
															}`}
														>
															Custom
														</div>
													</Menu.Item>
												</div>
											</Menu.Items>
										</Transitions>
									</div>
								)}
							</Menu>
						</div>
						<div className="flex items-center">
							<CheckBox
								name="hasBreak"
								label="Include break"
								labelTextColour="text-white"
								onChange={() => setShowBreak(!showBreak)}
								checked={record?.hasBreak || false}
							/>
						</div>
					</div>
					{showCustomNewPay && <InputField name="customPayRate" labelTextColour="text-white" />}
					<div className="mt-5">
						<label className="block text-sm font-medium text-white">Start Time</label>
						<div className="flex justify-center">
							<ComboBox name="startHour" labelTextColour="text-white" options={DayHourSegments} initValue={initValues.startHour} />

							<div className="text-white px-4 self-center">:</div>
							<ComboBox
								name="startMinute"
								labelTextColour="text-white"
								options={DayMinuteSegments}
								initValue={initValues.startMinute}
							/>
						</div>
					</div>

					{!disableClockIn && initValues.id && activeDay.baseEpoch <= todaysEpoch && (
						<div className="mt-5">
							<div className="flex items-center justify-between">
								<label className="text-sm font-medium text-white">Clock In {!shiftPunch?.in && "(Not Punched Yet)"}</label>
								{imageExistsForPunch("punch-in", shiftPunch?.in) && (
									<button type="button" onClick={() => showImage("punch-in", shiftPunch?.in)} className="ml-2">
										<CameraIcon className="h-5 w-5 text-white" />
									</button>
								)}
							</div>
							<div className="flex justify-center items-center">
								<ComboBox name="punchInH" labelTextColour="text-white" options={DayHourSegments} initValue={initValues.punchInH} />
								<div className="flex items-center text-white px-4">:</div>
								<div className="flex items-center">
									<InputField name="punchInM" labelTextColour="text-white" initValue={initValues.punchInM} type="number" />
								</div>
							</div>
						</div>
					)}

					{showBreak && (
						<>
							<div className="flex justify-center mt-5 mb-4">
								<ComboBox
									label="Break"
									name="breakDuration"
									labelTextColour="text-white"
									options={breakSet}
									ComboBoxOption={breakList}
									initValue={initValues.breakDuration}
									getSelectedValueOnChange={(value) => updateBreak(value.id)}
								/>
								<CheckBox
									name="unPaidBreak"
									label="Unpaid"
									labelTextColour="text-white"
									onChange={() => setUnPaidBreak(!unPaidBreak)}
									checked={unPaidBreak}
								/>
							</div>
							{showBreakTime && (
								<div className="flex justify-center">
									<div className="w-32">
										<ComboBox
											label="Start"
											name="breakStart"
											labelTextColour="text-white"
											options={DaySegments}
											initValue={initValues.breakStart}
										/>
									</div>
									<div className="text-white px-2 self-end mb-2">to</div>
									<div className="w-32">
										<ComboBox
											label="End"
											name="breakEnd"
											labelTextColour="text-white"
											options={DaySegments}
											initValue={initValues.breakEnd}
										/>
									</div>
								</div>
							)}
						</>
					)}

					{!disableClockIn && shiftPunch && shiftPunch.id && shiftPunchBreaks.length > 0 && (
						<div className="mt-5">
							<label className="block text-sm font-medium text-white">Breaks (Clocked-in)</label>
							<BreakFields />
						</div>
					)}

					<ReactTooltip />
					<div className="mt-5">
						<div className="flex justify-between">
							<label className="flex justify-start text-sm font-medium text-white">End Time</label>
							<div className="flex items-center">
								<CheckBox
									name="setAsClose"
									label="To Close"
									labelTextColour="text-white"
									checked={record?.setAsClose || false}
									toolTip="Display this time as Close on their shift"
								/>
							</div>
						</div>
						<div className="flex justify-center">
							<ComboBox name="endHour" labelTextColour="text-white" options={DayHourSegments} initValue={initValues?.endHour} />

							<div className="text-white px-4 self-center">:</div>

							<ComboBox name="endMinute" labelTextColour="text-white" options={DayMinuteSegments} initValue={initValues.endMinute} />
						</div>
					</div>
					{!disableClockIn && initValues.id && activeDay.baseEpoch <= todaysEpoch && (
						<div className="mt-5">
							<div className="flex items-center justify-between">
								<label className="text-sm font-medium text-white">Clock Out {!shiftPunch?.out && "(Not Punched Yet)"}</label>
								{imageExistsForPunch("punch-out", shiftPunch?.out) && (
									<button type="button" onClick={() => showImage("punch-out", shiftPunch?.out)} className="ml-2">
										<CameraIcon className="h-5 w-5 text-white" />
									</button>
								)}
							</div>
							<div className="flex justify-center items-center">
								<ComboBox name="punchOutH" labelTextColour="text-white" options={DayHourSegments} initValue={initValues.punchOutH} />
								<div className="flex items-center text-white px-4">:</div>
								<div className="flex items-center">
									<InputField name="punchOutM" labelTextColour="text-white" initValue={initValues.punchOutM} type="number" />
								</div>
							</div>
						</div>
					)}
					<div className="mt-5">
						<ComboBox
							label="Roles"
							name="role"
							labelTextColour="text-white"
							custom={true}
							options={roles}
							ComboBoxOption={RoleListOption}
							initValue={initValues.role}
							getSelectedValueOnChange={(value) => updateMemberList(value)}
						/>
					</div>
					<div className="mt-5">
						<ComboBox
							label="Staff Member"
							name="person"
							labelTextColour="text-white"
							options={staffMembers}
							ComboBoxOption={MemberListItem}
							initValue={initValues.person || null}
							preferences={preferences}
							getSelectedValueOnChange={(value) => selectedStaff(initValues.person, value)}
						/>
					</div>
					<div className="mt-5 flex flex-row justify-between">
						<div className="py-2">
							{record?.id && (
								<CheckBox
									name="delete"
									label="Delete"
									labelTextColour="text-white"
									onChange={() => setShowDelete(!showDelete)}
									checked={showDelete}
								/>
							)}
						</div>
						<div className="flex justify-end gap-5">
							{!showDelete ? (
								<>
									<LinkButton label="Cancel" className=" text-white font-semibold hover:text-gray-400" onClick={cancelCB} />
									<Button type="submit" label="Save" disabled={shiftSaving} />
								</>
							) : (
								<>
									<LinkButton
										label="Cancel"
										className=" text-white font-semibold hover:text-gray-400"
										onClick={() => setShowDelete(!showDelete)}
									/>
									<Button
										type="button"
										label="Confirm"
										onClick={() => deleteShift(record?.id)}
										colors="bg-red-500 hover:bg-red-600 focus:ring-red-600"
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

export default ShiftForm;

import { memo, useContext, useEffect, useState } from "react";
import {
    Avatar,
    LinkButton,
    Logo,
    formateEpoch
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import AddNotice from "./AddNotice";
import AddNoticeComments from "./AddNoticeComments";

const NoticeBoard = ({ currentMember, noticeModel, setNoticeModel, editNotice, setEditNotice, setChatType, setActiveMemberChat }) => {
    const { organisation } = useContext(OrganisationContext);
    const { members } = organisation;
    const { person } = useContext(PersonContext);
    const [notice, setNotice] = useState([]);
    const [noticeCommentModel, setNoticeCommentModel] = useState(false);

    useEffect(() => {
        getAllOrgNotices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    const getAllOrgNotices = async () => {
        if (organisation?.id) {
            const data = await orgUtils.getNotice(organisation.id);
            setNotice(data?.items);
        }
    };
    const handlerChooseModuleModal = (e, type = false) => {
        setNoticeModel(false);
    };
    const handlerChooseCommentModuleModal = (e, type = false) => {
        setNoticeCommentModel(false);
    };
    const handlerNoticeModel = (type = true) => {
        setNoticeModel(true);
    };

    const deleteNotice = async (noticeToDelete) => {
        if (noticeToDelete.comment && noticeToDelete.comment.items.length > 0)
            //delete comments as well if exists
            await Promise.all(
                noticeToDelete.comment.items.map(async (c) => {
                    await orgUtils.deleteNoticeComment(c.id);
                })
            );
        await orgUtils.deleteNotice(noticeToDelete.id);
        await getAllOrgNotices();
    };

    const commentsModel = async (selectedNotice) => {
        setEditNotice(selectedNotice);
        setNoticeCommentModel(true);
    };
    const MemberAvatar = memo(({ member, style = "" }) => <Avatar user={member} size="h-8 w-8" twClasses={`${style} z-20`} />); // TODO: find out why this isn't working. Still seeing flickering on avatars!!!

    return (
        <>
            <div className="relative flex justify-between p-3 border-b border-darkww-100">
                <div className="flex">
                    <Logo org={organisation} size="h-8 w-8" />
                    <span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">Notices</span>
                </div>
                <LinkButton
                    label="Add Notice to Board"
                    className="bg-darkww-600 text-white p-1 px-2 mr-6 rounded-md font-bold hover:text-darkww-900"
                    onClick={() => {
                        setEditNotice({});
                        handlerNoticeModel();
                    }}
                />
            </div>

            <AddNotice
                record={editNotice}
                open={noticeModel}
                getAllOrgNotices={getAllOrgNotices}
                handlerModal={handlerChooseModuleModal}
            />
            <AddNoticeComments
                record={editNotice}
                open={noticeCommentModel}
                getAllOrgNotices={getAllOrgNotices}
                handlerModal={handlerChooseCommentModuleModal}
                MemberAvatar={MemberAvatar}
            />
            <div className="">
                <div className="sm:hidden col-span-2 py-6  text-right text-sm font-medium">
                    <button
                        className="sm:hidden ml-auto order-2 mt-1 mr-1 flex p-1 text-white rounded-md bg-darkww-500 focus:outline-none focus:ring-2 ring-white sm:-mr-2"
                        onClick={(e) => {
                            setChatType("wholeOrganisation");
                            setActiveMemberChat("");
                        }}
                    >
                        back
                    </button>
                </div>
                <div className="mx-auto container py-5 px-6">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6">
                        {notice?.length > 0 &&
                            notice?.map((n) => {
                                let unReadComments =
                                    (n?.comment?.items &&
                                        n?.comment?.items?.length &&
                                        n?.comment?.items.filter((k) => !k?.readBy?.includes(currentMember?.orgUserId))?.length) ||
                                    0;
                                return (
                                    <div
                                        className="w-full h-auto flex flex-col justify-between dark:bg-darkww-300 bg-white dark:border-gray-700 rounded-lg border border-gray-400 mb-5 py-4 px-3"
                                        key={n.id}
                                    >
                                        <div>
                                            <div className="mb-3 flex gap-2 items-center content-start text-white justify-start">
                                                <MemberAvatar member={members?.find((k) => k?.id === n?.member?.personID)} />
                                                {personUtils.displayName(n?.member?.person)}
                                            </div>
                                            <h4 className="text-gray-800 dark:text-gray-100 content-end justify-end inline-flex font-bold mb-3">
                                                {n?.title}
                                            </h4>
                                            <p className="text-gray-800 dark:text-gray-100 text-sm">{n?.description}</p>
                                        </div>
                                        <div>
                                            <div className="flex items-end mt-3 justify-end text-gray-800 dark:text-gray-100">
                                                <p className="text-sm">{formateEpoch(Date.parse(n.createdAt) / 1000)}</p>
                                                {(person?.isAdmin ||
                                                    person?.assignedAdmin ||
                                                    currentMember?.orgUserId === n.memberID) && (
                                                        <>
                                                            <div className="w-8 h-8 rounded-full bg-gray-800 dark:bg-gray-100 dark:text-gray-800 text-white ml-3 flex items-center justify-center">
                                                                <LinkButton
                                                                    label={
                                                                        <svg
                                                                            xmlns="http://www.assignedAdminw3.org/2000/svg"
                                                                            className="icon icon-tabler icon-tabler-pencil"
                                                                            width={20}
                                                                            height={20}
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                                                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                                                                        </svg>
                                                                    }
                                                                    className=""
                                                                    onClick={() => {
                                                                        setEditNotice(n);
                                                                        handlerNoticeModel();
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="w-8 h-8 rounded-full bg-gray-800 dark:bg-gray-100 dark:text-gray-800 text-white flex ml-2 items-center justify-center">
                                                                <LinkButton
                                                                    label={
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth={1.5}
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                            />
                                                                        </svg>
                                                                    }
                                                                    className=""
                                                                    onClick={() => {
                                                                        deleteNotice(n);
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                <div className="w-8 h-8 rounded-full bg-gray-800 dark:bg-gray-100 dark:text-gray-800 text-white flex ml-2 items-center justify-center">
                                                    <LinkButton
                                                        label={
                                                            <div className="relative">
                                                                {unReadComments > 0 ? (
                                                                    <div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-3 left-4">
                                                                        {unReadComments}
                                                                    </div>
                                                                ) : (
                                                                    n?.comment?.items &&
                                                                    n?.comment?.items?.length > 0 && (
                                                                        <div className="rounded-full bg-lightww-300 w-5 h-5 text-white center absolute flex items-center justify-center top-3 left-4">
                                                                            {n?.comment?.items?.length}
                                                                        </div>
                                                                    )
                                                                )}
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="w-6 h-6"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        }
                                                        className=""
                                                        onClick={() => {
                                                            commentsModel(n);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NoticeBoard;
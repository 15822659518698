import { Dialog, Transition } from "@headlessui/react";
import { ArrowNarrowRightIcon, ArrowSmLeftIcon, ArrowSmRightIcon, BriefcaseIcon, ChatIcon, ClockIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useContext, useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { Button, epochToTimeStr, fillMonth, fillWeek } from "ww-framework";
import { orgUtils, PersonContext } from "ww-stores";
import ClockInClockOutForm from "../Organisation/ClockInClockOutForm";
import NoteParticularDay from "./../Organisation/NoteParticularDay";
import ShiftNote from "./../Organisation/ShiftNote";
import GiveAway from "./GiveAway";
import SwapOption from "./SwapShift";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const ShiftCard = ({
	shift,
	punchData,
	onConfirm,
	currentDay,
	noteParticularDay,
	ChooseModule,
	currentWeek,
	buildCalendar,
	setActiveDay,
	setEditShift,
	setModuleType,
	setOverLayOpen,
	setModelType
}) => {
	const { shiftStart, shiftEnd, hasBreak, breakStart, breakEnd, role, breakDuration, notes, shiftStatus, setAsClose } = shift;
	const totalHours = (shiftEnd - shiftStart) / 60 / 60;
	const { person } = useContext(PersonContext);
	const [chooseModuleOpen, setChooseModuleOpen] = useState(false);
	const [swapStaffOpen, setSwapStaffOpen] = useState(false);
	const [giveAwayOpen, setGiveAwayOpen] = useState(false);
	const noteParticular = noteParticularDay && noteParticularDay.find((value) => value.noteDate === currentDay?.baseEpoch);
	const shiftPunchData = punchData.find((punch) => punch?.shiftID === shift?.id);

	const pickNewDay = (currentDay, shift = "", e = "", type = "") => {
		if (e !== "") e.stopPropagation();
		if (!person.isAdmin && shift === "") return null;
		setActiveDay(currentDay);
		setEditShift(shift);
		setModuleType(type);
		setOverLayOpen(true);
	};
	const showChooseModelPopup = (e = "", shift) => {
		if (e !== "") e.stopPropagation();
		if (shift.shiftStart < new Date(new Date().toUTCString()).valueOf() / 1000) {
			setEditShift(shift);
			setActiveDay(currentDay);
			setModelType("checkInCheckOut");
			setChooseModuleOpen(true);
		} else {
			setModelType("SwapAndGiveAway");
			setChooseModuleOpen(true);
		}
	};
	const handlerChooseModuleModal = (e, type) => {
		setChooseModuleOpen(false);
		if (type === "swap") setSwapStaffOpen(true);
		if (type === "give") setGiveAwayOpen(true);
	};
	const handlerSwapStaffOpenModal = (e, type) => {
		setSwapStaffOpen(false);
	};
	const handlerGiveAwayOpenModal = (e, type) => {
		setGiveAwayOpen(false);
	};
	const rebuild = async () => {
		await buildCalendar();
	};
	return (
		<>
			<ChooseModule open={chooseModuleOpen} handlerModal={handlerChooseModuleModal} setChooseModuleOpen={setChooseModuleOpen} />
			<SwapOption
				open={swapStaffOpen}
				handlerModal={handlerSwapStaffOpenModal}
				shift={shift}
				person={person}
				currentWeek={currentWeek}
				currentDay={currentDay}
				buildCalendar={rebuild}
			/>
			<GiveAway
				open={giveAwayOpen}
				handlerModal={handlerGiveAwayOpenModal}
				shift={shift}
				person={person}
				currentWeek={currentWeek}
				currentDay={currentDay}
				buildCalendar={rebuild}
			/>

			<div className="flex bg-white w-full p-2 h-full rounded-none sm:rounded-full border border-gray-300 shadow-sm hover:bg-sky-200">
				<div className="w-28 h-28 rounded-full hidden sm:block" style={{ backgroundColor: role?.labelColour }} />
				<div
					className="w-6 h-full block sm:hidden cursor-pointer"
					style={{ backgroundColor: role?.labelColour }}
					onClick={(e) => showChooseModelPopup(e, shift, currentDay)}
				/>
				<div
					className="w-44 flex flex-col justify-center h-full ml-4 pl-2 cursor-pointer"
					onClick={(e) => showChooseModelPopup(e, shift, currentDay)}
				>
					{person.currentOrganisation.staffClockInView === "YES" && shiftPunchData?.in && (
						<div className="bg-green-600 text-white font-semibold px-2 py-1 -mt-6 sm:-mt-7 w-fit rounded-md shadow-sm">
							<ClockIcon className="h-4 w-4 mb-1 font-semibold inline-block" /> {epochToTimeStr(shiftPunchData?.in)}
						</div>
					)}
					<div className="text-sm font-medium text-gray-500 mt-1 truncate hidden sm:block">{"Start Time"}</div>
					<div className="text-3xl font-semibold text-gray-700">{epochToTimeStr(shiftStart)}</div>
					<div className="text-sm font-medium text-gray-500 truncate sm:hidden">{"TO"}</div>
					<div className="text-xl font-bold text-darkww-700 mt-2 hidden sm:block">{role?.name}</div>
					<div className="text-3xl font-semibold text-gray-700 sm:hidden">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
					{person.currentOrganisation.staffClockInView === "YES" && shiftPunchData?.out && (
						<div className="bg-red-600 text-white px-2 py-1 font-semibold sm:mt-2 w-fit sm:hidden -mb-6 rounded-md shadow-sm">
							<ClockIcon className="h-4 w-4 mb-1 inline-block" /> {epochToTimeStr(shiftPunchData?.out)}
						</div>
					)}
				</div>
				<div
					className="w-48 sm:flex flex-col justify-center h-full pl-2 -mt-4 hidden cursor-pointer"
					onClick={(e) => showChooseModelPopup(e, shift, currentDay)}
				>
					{person.currentOrganisation.staffClockInView === "YES" && shiftPunchData?.out && (
						<div className="bg-red-600 text-white px-2 py-1 w-fit font-semibold sm:-mt-8 mb-1 rounded-md shadow-sm">
							<ClockIcon className="h-4 w-4 mb-1 inline-block" /> {epochToTimeStr(shiftPunchData?.out)}
						</div>
					)}
					<div className="text-sm font-medium text-gray-500 truncate">{"End Time"}</div>
					<div className="text-3xl font-semibold text-gray-700">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
				</div>
				<div className="w-52 h-full pt-1 sm:pt-2 cursor-pointer" onClick={(e) => showChooseModelPopup(e, shift, currentDay)}>
					{!setAsClose && (
						<div>
							<span className="text-4xl sm:text-5xl font-extrabold text-gray-700">{totalHours}</span>
							<span className="text-md pl-1 text-darkww-900">hrs</span>
						</div>
					)}
					{hasBreak && (
						<div className="text-gray-500 text-sm flex flex-row items-center mt-2">
							<BriefcaseIcon className="h-6 w-6 px-1" />
							{breakDuration ? `${breakDuration} Mins` : `${epochToTimeStr(breakStart)} - ${epochToTimeStr(breakEnd)}`}
						</div>
					)}
					<div className="text-lg font-bold pl-1 text-darkww-700 mt-2 sm:hidden">{role?.name}</div>
				</div>
				<div className="relative align-end mb-8">
					<button
						className={classNames(
							shiftStatus === "CONFIRM" ? "" : "",
							"text-white font-bold p-3 border-1 rounded-full cursor-pointer shadow-md  absolute -bottom-8 right-1 md:-right-3"
						)}
						onClick={(e) => onConfirm(shift)}
					>
						{shiftStatus === "CONFIRM" ? (
							<svg
								className="w-5 sm:w-8 h-5 sm:h-8"
								fill="none"
								stroke="limegreen"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
						) : (
							<svg
								className="w-5 sm:w-8 h-5 sm:h-8 hover:stroke-white border-white"
								fill="none"
								stroke="gray"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
						)}
					</button>
					{noteParticular?.id !== undefined && (
						<div
							className="font-bold p-2 border-1 rounded-full cursor-pointer absolute -bottom- right-6 sm:right-10 text-gray-500"
							onClick={(e) => pickNewDay(currentDay, shift, e, "noteParticular")}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 sm:w-8 h-6 sm:h-8"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth="2"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
								/>
							</svg>
						</div>
					)}
					{notes?.items.length > 0 ? (
						<>
							<div
								className="hidden sm:block text-gray-500 font-bold p-2 border-1 mr-4 md:mr-0 rounded-full cursor-pointer absolute -bottom- -right-4"
								onClick={(e) => pickNewDay(currentDay, shift, e, "note")}
							>
								<ChatIcon className="w-8 h-8" />
							</div>
							<div
								className="block sm:hidden text-gray-500 font-bold p-2 border-1 rounded-full cursor-pointer absolute  -bottom- -right-2"
								onClick={(e) => pickNewDay(currentDay, shift, e, "note")}
							>
								<ChatIcon className="w-6 h-6" />
							</div>
						</>
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
};

export const MyShifts = () => {
	const { person } = useContext(PersonContext);
	const [activeDate, setActiveDate] = useState(new Date(/*`2022-01-31` Just for testing/replicating different days*/));
	const [currentWeek, setCurrentWeek] = useState();
	const [weekDays, setWeekDays] = useState([]);
	const [totalHours, setTotalHours] = useState(0);
	const [noteParticularDay, setNoteParticularDay] = useState();
	const [activeDay, setActiveDay] = useState();
	const [editShift, setEditShift] = useState();
	const [moduleType, setModuleType] = useState();
	const [overLayOpen, setOverLayOpen] = useState(false);
	const [modelType, setModelType] = useState("SwapAndGiveAway");
	const [punchData, setPunchData] = useState([]);

	useEffect(() => {
		OneSignal.init({ appId: "4b328419-1b0f-48a6-b0ab-8a101539be5e" });
		OneSignal.showSlidedownPrompt();

		OneSignal.setExternalUserId(person.sub);
	}, [person]);

	const firstDayOfWeek = weekDays[0];
	const changeCalendar = (direction) => {
		const daysToMove = direction === "DOWN" ? -7 : 7;
		const newDate = new Date(firstDayOfWeek.date);
		newDate.setDate(firstDayOfWeek?.date?.getDate() + daysToMove);

		setActiveDate(newDate); // This will trigger a new calendar in the useEffect hook.
	};
	const buildCalendar = () => {
		async function loadCalendar() {
			try {
				if (activeDate) {
					const member = person.currentOrganisation?.members?.find((k) => k?.person === person?.person);

					const newCalendarDays = fillWeek(activeDate, person?.currentOrganisation?.startDay);

					const {
						first: { baseEpoch: startDate },
						last: { baseEpoch: endDate },
						days
					} = newCalendarDays;

					let shifts = await orgUtils.getShifts(person.currentOrganisation?.id, startDate, endDate, person?.isAdmin);
					// if (person?.isAdmin) {
					// 	shifts = shifts.filter((s) =>
					// 		s.member?.departmentID !== null && member?.departmentIDs.length > 0
					// 			? arrayDiff(s.member?.departmentID || [], member?.departmentIDs)
					// 			: s?.memberID === member?.orgUserId
					// 	);
					// } removed as it was causing current week to filter too many shifts
					const daysWithShifts = days.map((calDay) => {
						const { baseEpoch } = calDay;
						const shiftsFound = shifts.filter((shift) => shift.baseDay === baseEpoch && shift?.member?.personID === person.id);
						return { ...calDay, ...{ shifts: shiftsFound }, ...{ allShifts: shifts } };
					});

					const myShifts = shifts.filter((shift) => shift?.member?.personID === person?.id);
					try {
						// Fetch punch data for the week
						const fetchedPunchData = await orgUtils.getPunchBetweenDay(person.currentOrganisation.id, startDate, endDate);
						setPunchData(fetchedPunchData.items); // Assuming the data you're interested in is in the `items` array
					} catch (err) {
						console.error(err);
					}
					setTotalHours(
						myShifts.reduce((previousTotal, currentShift) => {
							return previousTotal + (currentShift.shiftEnd - currentShift.shiftStart) / 60 / 60;
						}, 0)
					);

					setCurrentWeek({ ...newCalendarDays, ...{ shifts: myShifts }, ...{ allShifts: shifts } });
					setWeekDays(daysWithShifts);
				}
			} catch (err) {
				console.error(err);
			}
		}
		setTimeout(() => loadCalendar(), 500); // Just add a half second delay to avoid jumpyness.
	};
	const rebuild = async () => {
		await buildCalendar();
	};
	const getNoteParticularDay = async () => {
		try {
			if (activeDate) {
				let newCalendarDays;
				newCalendarDays = fillMonth(activeDate, true, person?.currentOrganisation?.startDay);
				const {
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = newCalendarDays;
				const data = await orgUtils.getNoteParticularDay(person.currentOrganisation?.id, startDate, endDate);
				setNoteParticularDay(data.items);
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (person?.id) {
			buildCalendar();
			getNoteParticularDay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDate, person]);

	const onConfirm = async (record) => {
		let endTime = "";

		if (record) {
			endTime = record?.shiftEnd - record?.baseDay;
			if (endTime > 86400) {
				endTime = endTime - 86400;
			}
		}
		const updatedRecord = {
			...record,
			start: record?.shiftStart - record?.baseDay || "",
			end: endTime || "",
			role: record?.roleID,
			day: { baseEpoch: record.baseDay },
			person: record.memberID,
			shiftStatus: "CONFIRM"
		};
		await orgUtils.saveShift(updatedRecord);
		await buildCalendar();
	};
	useEffect(() => {
		if (weekDays && weekDays.length) {
			const unSeenShifts = [];
			weekDays.forEach((weekDay) => {
				if (weekDay?.shifts.length) {
					weekDay.shifts.forEach((shift) => {
						if (shift.shiftStatus === "UN_SEEN") {
							unSeenShifts.push(shift);
						}
					});
				}
			});
			if (unSeenShifts?.length) {
				unSeenShifts.map((shift) => {
					let endTime = "";
					if (shift) {
						endTime = shift?.shiftEnd - shift?.baseDay;
						if (endTime > 86400) {
							endTime = endTime - 86400;
						}
					}
					const updatedRecord = {
						...shift,
						start: shift?.shiftStart - shift?.baseDay || "",
						end: endTime || "",
						role: shift?.roleID,
						day: { baseEpoch: shift.baseDay },
						person: shift.memberID,
						shiftStatus: "SEEN"
					};
					// NOTE: If want to check status for response we can handle using the promise from next line
					return orgUtils.saveShift(updatedRecord);
				});
			}
		}
	}, [weekDays]);
	const ShiftAndNoteButton = ({ handlerModal }) => (
		<>
			<Button label="Swap Shift" colors="bg-darkww-500 hover:bg-darkww-700 focus:ring-white" onClick={(e) => handlerModal(e, "swap")} />
			<Button
				type="button"
				label="Give Away"
				onClick={(e) => handlerModal(e, "give")}
				colors="bg-darkww-500 hover:bg-darkww-700 focus:ring-white"
			/>
		</>
	);
	const ChooseModule = ({ open, handlerModal, setChooseModuleOpen }) => {
		return (
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
					<div className="flex items-center justify-center h-full w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="bg-white transform rounded-lg w-fit w-72">
								<span
									className="inline-block absolute top-0 right-0 mr-2 mt-4 cursor-pointer"
									onClick={(e) => handlerModal(e, "close")}
								>
									<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								<div className="w-full flex flex-col px-2 py-3">
									<div className="flex flex justify-center gap-2">
										{/* <ShiftAndNoteButton handlerModal={handlerModal} /> */}
										{modelType === "SwapAndGiveAway" ? (
											<ShiftAndNoteButton handlerModal={handlerModal} />
										) : (
											modelType === "checkInCheckOut" && (
												<ClockInClockOutForm
													record={editShift}
													cancelCB={() => {
														setEditShift({});
														setChooseModuleOpen(false);
													}}
													rebuildCalendar={rebuild}
													activeDay={activeDay}
												/>
											)
										)}
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};
	return (
		<>
			<Transition.Root show={overLayOpen} as={Fragment}>
				<Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={setOverLayOpen}>
					<div className="absolute inset-0 overflow-hidden">
						<Transition.Child
							as={Fragment}
							enter="ease-in-out duration-500"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in-out duration-500"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<div style={{ width: 500 }} className="relative px-5">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-1 right-8 -ml-8 pt-2 pr- flex sm:-ml-10 sm:pr-4">
											<button
												type="button"
												className="rounded-md text-gray-300 hover:text-white focus:outline-none"
												onClick={() => setOverLayOpen(false)}
											>
												<span className="sr-only">Close panel</span>
												<XIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									{moduleType === "note" ? (
										<div className="col-span-12 sm:col-span-3 h-full">
											<ShiftNote
												shift={editShift}
												activeDay={activeDay}
												rebuildCalendar={rebuild}
												setOverLayOpen={setOverLayOpen}
											/>
										</div>
									) : (
										moduleType === "noteParticular" && (
											<div className="col-span-12 sm:col-span-3 h-full">
												<NoteParticularDay
													note={noteParticularDay.find((value) => value.noteDate === activeDay?.baseEpoch)}
													activeDay={activeDay}
													getNoteParticularDay={getNoteParticularDay}
													setOverLayOpen={setOverLayOpen}
													organisation={person.currentOrganisation}
													person={person}
												/>
											</div>
										)
									)}
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<div className="w-full mt-3">
				<div className="w-72 sm:w-full sm:max-w-lg px-2 mx-auto flex mt-4">
					<div className="flex-none flex items-center align-middle justify-center w-10 sm:w-16">
						<div className="w-full h-10 sm:h-16 rounded-md sm:rounded-lg bg-pink-600 hover:bg-pink-800 hover:pink-800 p-1 sm:p-2">
							<button type="button" onClick={(e) => changeCalendar("DOWN")}>
								<ArrowSmLeftIcon className="h-8 sm:h-12 w-8 sm:w-12 text-white" aria-hidden="true" />
							</button>
						</div>
					</div>
					<div className="flex-grow flex">
						<div className="flex-1 flex items-center align-middle justify-center">
							<div className="flex flex-col items-center">
								<div className="text-2xl text-darkww-700 font-bold">{currentWeek?.first.day.padStart(2, "0")}</div>
								<div className="text-md text-gray-600 uppercase">{currentWeek?.first.monthShortDesc}</div>
							</div>
						</div>
						<div className="flex-1 flex items-center align-middle justify-center">
							<ArrowNarrowRightIcon className="text-gray-600 h-12 w-12" aria-hidden="true" />
						</div>
						<div className="flex-1 flex items-center align-middle justify-center">
							<div className="flex flex-col items-center">
								<div className="text-2xl text-darkww-700 font-bold">{currentWeek?.last.day.padStart(2, "0")}</div>
								<div className="text-md text-gray-600 uppercase">{currentWeek?.last.monthShortDesc}</div>
							</div>
						</div>
					</div>
					<div className="flex-none flex items-center align-middle justify-center w-10 sm:w-16">
						<div className="w-full h-10 sm:h-16 rounded-md sm:rounded-lg bg-pink-600 hover:bg-pink-800 p-1 sm:p-2">
							<button type="button" onClick={() => changeCalendar("UP")}>
								<ArrowSmRightIcon className="h-8 sm:h-12 w-8 sm:w-12 text-white" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>
				<div className="w-full flex flex-row justify-center items-center max-w-3xl px-2 mx-auto h-fit mt-2 sm:mt-6">
					<div className="w-full flex flex-col justify-center items-center max-w-3xl px-1 mx-auto h-fit mt-2 md:mt-6">
						<div className="text-2xl sm:text-4xl font-bold text-darkww-700">{totalHours}</div>
						<div className="text-sm text-gray-700">Total hours</div>
					</div>
					{!!person.salary && person.currentOrganisation?.staffInputhourlyRate === "YES" && (
						<div className="w-full flex flex-col justify-center items-center max-w-3xl px-1 mx-auto h-fit mt-2 md:mt-6">
							<div className="text-2xl sm:text-4xl font-bold text-darkww-700">{"€" + (person.salary * totalHours).toFixed(2)} </div>
							<div className="text-sm text-gray-700">Wages (est.)</div>
						</div>
					)}
				</div>
				<div className="w-full max-w-3xl px-2 mx-auto flex h-fit mt-2">
					<ul role="listitem" className="w-full" aria-busy="true">
						{weekDays.length > 0 &&
							weekDays.map((weekDay, index) => {
								return (
									<li key={index} className="my-5" role="group">
										<div className="relative">
											<div className="absolute inset-0 flex items-center" aria-hidden="true">
												<div className="w-full border-t border-gray-300" />
											</div>
											<div className="relative flex justify-start">
												<span
													className={`pr-3 bg-white text-lg font-medium ${
														weekDay?.shifts.length === 0 ? "text-gray-500" : "text-darkww-900"
													} `}
												>
													{weekDay.dayDesc}
												</span>
											</div>
										</div>
										<ul role="presentation" className="w-full">
											{weekDay?.shifts.map((shift, index) => {
												return (
													<li role="presentation" key={index} className="h-32 my-5">
														<ShiftCard
															shift={shift}
															currentDay={weekDay}
															noteParticularDay={noteParticularDay}
															onConfirm={onConfirm}
															ChooseModule={ChooseModule}
															currentWeek={currentWeek}
															buildCalendar={buildCalendar}
															setActiveDay={setActiveDay}
															setEditShift={setEditShift}
															setModuleType={setModuleType}
															setOverLayOpen={setOverLayOpen}
															setModelType={setModelType}
															punchData={punchData}
														/>
													</li>
												);
											})}
											{weekDay?.shifts.length === 0 && <li className="text-md text-gray-500">NO SHIFTS TODAY</li>}
										</ul>
									</li>
								);
							})}
					</ul>
				</div>
			</div>
		</>
	);
};

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, memo, useCallback, useContext, useEffect, useState } from "react";

import { BriefcaseIcon } from "@heroicons/react/outline";
import { ChatIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import {
	Button,
	ConfirmModal,
	// Transition,
	Form,
	InputField,
	Loading,
	epochToTimeStr,
	formateEpochToShortMonthDate,
	formateEpochToShortMonthDate2,
	notifyUser,
	showFailure,
	showSuccess,
	useLocation,
	useNavigate,
	useSimpleMessage
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import ApproveRequests from "./ApproveRequests";
import Background from "./Background";
import GiveAwayRequests from "./GiveAwayRequests";
import SwapShiftRequests from "./SwapShiftRequests";
import TimeOffRequests from "./TimeOffRequests";

const TimeOffRequestsMemo = memo(TimeOffRequests);
const SwapShiftRequestsMemo = memo(SwapShiftRequests);
const GiveAwayRequestsMemo = memo(GiveAwayRequests);
const ApproveRequestsMemo = memo(ApproveRequests);

const StaffMembers = () => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const { members } = organisation;
	const [pendingtimeOff, setPendingTimeOff] = useState([]);
	const [approveTimeOff, setApproveTimeOff] = useState([]);
	const [mySwapShifts, setmySwapShifts] = useState([]);
	const [myGiveAway, setmyGiveAway] = useState([]);
	const [specialShifts, setSpecialShifts] = useState([]);
	const [allGiveAway, setAllGiveAway] = useState([]);
	const [myRequests, setMyRequests] = useState([]);
	const [pendingSwapShiftRequest, setPendingSwapShiftRequest] = useState([]);
	const [pendingGiveAwayRequest, setPendingGiveAwayRequest] = useState([]);
	const [approveSwapShiftRequest, setApproveSwapShiftRequest] = useState([]);
	const [approveGiveAwayRequest, setApproveGiveAwayRequest] = useState([]);
	const [perndingClockInOut, setPerndingClockInOut] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { setMessage } = useSimpleMessage();
	const [memberID, setMemberId] = useState("");
	let navigate = useNavigate();
	let location = useLocation();
	const [openTab, setOpenTab] = useState(1);
	// const [openAllGiveAway, setOpenAllGiveAway] = useState(false);
	const [openGiveAway, setOpenGiveAway] = useState({ state: false });
	const [openSwap, setOpenSwap] = useState({ state: false });
	const [shiftDataAllGiveAway, setShiftDataAllGiveAway] = useState();
	const [swap, setSwap] = useState({});
	const [open, setOpen] = useState(false);
	const [memberAllowedHours, setMemberAllowedHours] = useState([]);
	const color = "darkww";
	const request = [
		{ name: "My Swap/Give Away", vars: ["mySwapShifts", "myGiveAway"], href: "#my-requests" },
		{ name: "Time Off", vars: ["pendingtimeOff"], href: "#time-off" },
		{ name: "Swap Shift", vars: ["pendingSwapShiftRequest", "specialShifts"], href: "#swap-shift" },
		{ name: "Give Away", vars: ["pendingGiveAwayRequest", "allGiveAway"], href: "#give-away" },
		{ name: "Previous Requests", vars: ["approveTimeOff", "approveSwapShiftRequest", "approveGiveAwayRequest"], href: "#previous-requests" }
	];
	const [shiftsAvailableForSwap, setShiftsAvailableForSwap] = useState([]);
	const [noteMoule, setNoteModuleOpen] = useState(false);
	const [noteInState, setNoteInState] = useState(false);
	const [typeAndDetail, setTypeAndDetail] = useState({});
	useEffect(() => {
		person && setMemberId(members?.find((m) => m.person === person?.person)?.orgUserId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members, person]);

	useEffect(() => {
		if (memberID && person) person.isAdmin || person.assignedRequests ? loadRoles() : loadRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberID, person]);

	useEffect(() => {
		if (organisation.id) loadPendingClockIns(organisation.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);
	const calculateTotalTime = (shiftArray) => {
		return shiftArray.reduce((previousTotal, currentShift) => {
			return (
				previousTotal +
				(currentShift.shiftEnd -
					currentShift.shiftStart -
					(currentShift.unPaidBreak &&
						(currentShift.breakDuration ? currentShift.breakDuration * 60 : currentShift.breakEnd - currentShift.breakStart))) /
					3600
			);
		}, 0);
	};
	const loadPendingClockIns = useCallback(
		async (organisationId) => {
			if ((person.isAdmin || person.assignedRequests) && organisation.id) {
				const pendingShifts = await orgUtils.getAllPendingClockIns(organisationId);
				setPerndingClockInOut(pendingShifts?.getOrgShifts?.items ?? []);
			}
		},
		[person.isAdmin, person.assignedRequests, organisation.id]
	);
	const loadRequests = useCallback(async () => {
		const isAdminOrAssignedRequests = person.isAdmin || person.assignedRequests;
		const data = await orgUtils.getTimeOff(organisation.id, null, null, isAdminOrAssignedRequests, memberID);
		const { items: timeOffData } = data;
		if (person.isAdmin || person.assignedRequests) {
			setApproveTimeOff(timeOffData.filter((k) => k.status !== "PENDING"));
			setPendingTimeOff(timeOffData.filter((k) => k.status === "PENDING"));
		} else {
			setPendingTimeOff(timeOffData.filter((k) => k.memberID === memberID));
		}
		const memberToRequests = await orgUtils.getMemberShiftSwapRequests(organisation.id, memberID);
		const memberFromRequests = await orgUtils.getFromMemberShiftSwapRequests(memberID);
		const memberRequests = [...memberToRequests.items, ...memberFromRequests.items];
		const memberGiveAway = await orgUtils.getMemberGiveAwayRequests(organisation.id, null);
		const mySwapShifts = memberRequests.filter((i) => {
			const isInvolved = i.memberID === memberID || i.fromMemberID === memberID;
			const hasFromAndToShift = i.fromShift && i.toShift;
			return isInvolved && hasFromAndToShift;
		});

		const myGiveAway = memberGiveAway.items.filter((i) => {
			return (i.fromMemberID === memberID || i.memberID === memberID) && i.shift;
		});
		const combinedRequests = [...mySwapShifts, ...myGiveAway];
		const myRequests = combinedRequests.sort((a, b) => {
			return new Date(b.updatedAt) - new Date(a.updatedAt);
		});
		setMyRequests(myRequests);
		try {
			if (person.isAdmin || person.assignedRequests) {
				const orgRequests = await orgUtils.getOrgShiftSwapRequests(organisation.id);
				const orgGiveAway = await orgUtils.getMemberGiveAwayRequests(organisation.id, null);
				setPendingSwapShiftRequest(
					orgRequests?.items?.filter(
						(i) =>
							i.status === "APPROVE_BY_MEMBER" ||
							(i.status === "PENDING" && (i.fromMemberID === memberID || i.memberID === memberID) && i.fromShift && i.toShift)
					)
				);
				setPendingGiveAwayRequest(
					orgGiveAway?.items?.filter(
						(i) =>
							i.status === "APPROVE_BY_MEMBER" ||
							(i.status === "PENDING" && (i.fromMemberID === memberID || i.memberID === memberID) && i.shift)
					)
				);
				setApproveSwapShiftRequest(orgRequests?.items?.filter((i) => i.status === "APPROVE_BY_MANAGER" && i.fromShift && i.toShift));
				setApproveGiveAwayRequest(orgGiveAway?.items?.filter((i) => i.status === "APPROVE_BY_MANAGER" && i.shift));
			}

			if (!person.isAdmin && !person.assignedRequests) {
				const pendingSwapShiftReq = memberRequests?.filter((i) => i.status === "PENDING" && i.fromShift && i.toShift);
				setPendingSwapShiftRequest(pendingSwapShiftReq);

				const pendingGiveAwayReq = memberGiveAway?.items?.filter(
					(i) => i.status === "PENDING" && (i.fromMemberID === memberID || i.memberID === memberID) && i.shift
				);
				setPendingGiveAwayRequest(pendingGiveAwayReq);

				const approveSwapShiftReq = memberRequests?.filter((i) => i.status === "APPROVE_BY_MEMBER" && i.fromShift && i.toShift);
				setApproveSwapShiftRequest(approveSwapShiftReq);

				const approveGiveAwayReq = memberGiveAway?.items?.filter(
					(i) => i.status === "APPROVE_BY_MEMBER" && i.shift && (i.fromMemberID || i.memberID) === memberID
				);
				setApproveGiveAwayRequest(approveGiveAwayReq);
			}

			if (organisation.id) {
				// Logic for fetching requests for all members
				const forAllMembersRequests = await orgUtils.getAllRequest(
					members?.find((m) => m.person === person?.person)?.roleIDs ?? [],
					organisation.id
				);
				setSpecialShifts(forAllMembersRequests.items.filter((k) => k.fromMemberID !== memberID));

				const forAllMembersGiveAway = await orgUtils.getAllGiveAway(
					members?.find((m) => m.person === person?.person)?.roleIDs,
					organisation.id,
					memberID
				);
				setAllGiveAway(forAllMembersGiveAway?.items);
			}
		} catch (error) {
			console.error(error);
		}
	}, [organisation.id, person.isAdmin, person.assignedRequests, person?.person, memberID, members]);

	const loadRoles = useCallback(async () => {
		try {
			if (organisation.id && memberID) {
				const data = await orgUtils.getTimeOff(organisation.id, null, null, person.isAdmin, memberID);
				const { items: timeOffData } = data;
				setApproveTimeOff(timeOffData.filter((k) => k.status !== "PENDING"));
				setPendingTimeOff(timeOffData.filter((k) => k.status === "PENDING"));
				await loadRequests();
				const isPaid = timeOffData.filter((k) => k.status === "PENDING" && k.isPaid);
				if (isPaid.length > 0) {
					let lastPaidDay = Math.max(...isPaid.map((o) => o.toDate));
					const wholeYearS = await orgUtils.getCurrentYearShifts(organisation.id, lastPaidDay);
					let alowedHours = [];
					// let membersAppliedForPaidOff = [...new Set(isPaid.map((item) => item.memberID))];
					timeOffData
						.filter((k) => k.status === "PENDING")
						.forEach((k) => {
							let d = wholeYearS.items.filter((y) => y.memberID === k.memberID);
							let totalHours = calculateTotalTime(d) * 0.08;
							let previousHoursTaken = timeOffData
								.filter((of) => of.memberID === k.memberID && of.isPaid === true && of.status === "APPROVE")
								.reduce((previousTotal, currentOff) => {
									return previousTotal + (currentOff.toDate - currentOff.fromDate) / (60 * 60 * 24);
								}, 0);
							alowedHours.push({ memberId: k, allowedHours: totalHours - previousHoursTaken * 8, id: k.id });
						});
					setMemberAllowedHours(alowedHours);
				}
			}
		} catch (err) {
			console.error(err);
		}
	}, [organisation.id, memberID, person.isAdmin, loadRequests]);
	useEffect(() => {
		if (specialShifts.length > 0) {
			// fetch week's shifts for all unique week day
			let uniqueWeekData = [...new Set(specialShifts.map((item) => item?.fromShift?.weekStartDay))];
			fetchWeeksShift(uniqueWeekData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [specialShifts]);

	const fetchWeeksShift = async (uniqueWeekData) => {
		const weekShift = async (date) => {
			let shiftDates = [];
			specialShifts.forEach((s) => {
				if (s.fromShift.weekStartDay === date) shiftDates.push(s.fromShift.baseDay);
			});
			const { items } = await orgUtils.memberShiftByWeek(organisation.id, memberID, date, shiftDates);
			console.log("shift date", date);
			console.log("shift items", items);
			return { date: date, shifts: items };
		};
		const getWeekShifts = uniqueWeekData.map((date) => {
			return weekShift(date);
		});

		return Promise.allSettled(getWeekShifts).then((results) => setShiftsAvailableForSwap(results.map((result) => result.value)));
	};

	const approveSwapShift = async (e, swapShift, type = null, personID = null, status = "APPROVE_BY_MEMBER") => {
		try {
			setIsLoading(true);

			const shiftData = {
				...{
					id: swapShift.id,
					organisationID: swapShift.organisationID,
					fromMemberID: swapShift.fromMemberID,
					fromShiftId: swapShift.fromShiftId,
					toShiftId: swapShift.toShiftId,
					baseDay: swapShift.baseDay,
					memberID: swapShift.memberID,
					note: swapShift?.note || null,
					status: status
				}
			};

			const swap = { state: false, shiftData, person, status, swapShift };
			setOpenSwap(swap);

			const hasAlreadyShift = await orgUtils.getShiftOnDayOfMember(
				swapShift.toShift?.baseDay,
				organisation.id,
				swapShift?.fromMemberID,
				swapShift.fromShiftId
			);

			if (hasAlreadyShift.items.length > 0) {
				setOpenSwap({ state: true, shiftData, person, status, swapShift });
				setMessage(
					showFailure({
						title: "Can't swap these shifts.",
						subTitle: "Employee already rostered that day."
					})
				);
			} else {
				await handlerModalSwap(e, "confirm", swap);
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};

	// const revertSwapShift = async (e, swapShift, type = null, personID = null, status = "APPROVE_BY_MANAGER") => {
	//   try {
	//     setIsLoading(true);
	//     const shiftData = {
	//       ...{
	//         id: swapShift.id,
	//         organisationID: swapShift.organisationID,
	//         fromMemberID: swapShift.toMemberID,
	//         fromShiftId: swapShift.toShiftId,
	//         toShiftId: swapShift.fromShiftId,
	//         baseDay: swapShift.baseDay,
	//         memberID: swapShift.memberID,
	//         note: swapShift?.note || null,
	//         status: status
	//       }
	//     };
	//     const swap = { state: false, shiftData, person, status, swapShift };
	//     setOpenSwap(swap);

	//     const hasAlreadyShift = await orgUtils.getShiftOnDayOfMember(swapShift.toShift?.baseDay, organisation.id, swapShift?.memberID);

	//     if (hasAlreadyShift.items.length > 0) {
	//       setOpenSwap({ state: true, shiftData, person, status, swapShift });
	//     } else {
	//       await handlerModalSwap(e, "confirm", swap);
	//     }
	//     setIsLoading(false);
	//   } catch (error) {
	//     console.log(error);
	//     setMessage(
	//       showFailure({
	//         title: "Unable to change preference.",
	//         subTitle: error.message
	//       })
	//     );
	//   }
	// };
	const handlerModalSwap = async (e, type, swap) => {
		try {
			if (type === "confirm" && (swap?.shiftData ? swap : openSwap)?.shiftData) {
				await orgUtils.saveShiftSwapRequest((swap?.shiftData ? swap : openSwap)?.shiftData);
				(swap?.shiftData ? swap : openSwap)?.status === "APPROVE_BY_MEMBER" &&
					(await notifyUser(
						[person],
						`${personUtils.displayName(
							(swap?.shiftData ? swap : openSwap)?.swapShift.fromMember?.person
						)} would like you to approve a swap with ${personUtils.displayName(
							(swap?.shiftData ? swap : openSwap)?.swapShift.toMember?.person
						)}`
					));
				if ((swap?.shiftData ? swap : openSwap)?.status === "APPROVE_BY_MANAGER") {
					const shiftData = {
						...{
							id: (swap?.shiftData ? swap : openSwap)?.swapShift.fromShiftId,
							member: (swap?.shiftData ? swap : openSwap)?.swapShift.memberID,
							toShiftId: (swap?.shiftData ? swap : openSwap)?.swapShift.toShiftId,
							memberID: (swap?.shiftData ? swap : openSwap)?.swapShift.fromMemberID
						}
					};

					// Delete "requests-ALL" if there are any with any of the above shifts
					await orgUtils.swapRequest(shiftData);
					await orgUtils.dstroyAllRequestByShift((swap?.shiftData ? swap : openSwap)?.swapShift.fromShiftId);
					await orgUtils.dstroyAllRequestByShift((swap?.shiftData ? swap : openSwap)?.swapShift.toShiftId);
				}
				person.isAdmin || person.assignedRequests ? await loadRoles() : await loadRequests();
			}
			setMessage(
				showSuccess({
					title: "Shifts Swapped successfully."
				})
			);
			setOpenSwap({ state: false });
		} catch (error) {
			console.log(error);
		}
	};

	const acceptGiveAway = async (e, giveAway, type = null, person = null, status = "APPROVE_BY_MEMBER") => {
		try {
			setIsLoading(true);
			const shiftData = {
				id: giveAway.id,
				organisationID: giveAway.organisationID,
				fromMemberID: giveAway.fromMemberID,
				fromShiftId: giveAway.fromShiftId,
				toShiftId: giveAway.toShiftId,
				memberID: giveAway.memberID,
				baseDay: giveAway.baseDay,
				status: status,
				note: giveAway?.note || null
			};
			const k = { state: false, shiftData, person, status, giveAway };
			setOpenGiveAway(k);
			const hasAlreadyShift = await orgUtils.getShiftOnDayOfMember(giveAway?.shift?.baseDay, organisation.id, giveAway.memberID, giveAway?.id);

			if (hasAlreadyShift.items.length > 0) {
				setMessage(
					showFailure({
						title: "Can't give shift away.",
						subTitle: "Employee already has a shift on the same day."
					})
				);
				setOpenGiveAway({ state: true, shiftData, person, status, giveAway });
			} else {
				await handlerModalGiveAway(e, "confirm", k);
			}
			setIsLoading(false);
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};
	const manageShifts = (month = "") => {
		navigate(`${location.pathname === "/" ? "" : location.pathname}/calendar?mode=M&month=${month}`, {
			state: {
				from: location.pathname || "/",
				backgroundLocation: location.pathname || "/",
				file: "/Organisation/Calendar",
				fullScreen: true
			}
		});
	};

	const acceptAllGiveAway = async (e, giveAway, type = "Give Away", organisation) => {
		try {
			setIsLoading(true);
			const shiftData = {
				...{
					organisationID: person.currentOrganisation.id,
					fromMemberID: giveAway.shift.memberID,
					shiftId: giveAway.shift.id,
					memberID: memberID,
					status: "APPROVE_BY_MEMBER",
					giveAwayId: giveAway.id,
					note: giveAway?.note || null
				}
			};
			setShiftDataAllGiveAway(shiftData);
			const hasAlreadyShift = await orgUtils.getShiftOnDayOfMember(giveAway.shift.baseDay, organisation.id, memberID, giveAway.shift.id);

			if (hasAlreadyShift.items.length > 0) {
				setMessage(
					showFailure({
						title: "Can't give shift away.",
						subTitle: "Employee already has a shift on the same day."
					})
				);
			} else {
				await handlerModalAllRequest(e, "confirm", shiftData);
			}
			setIsLoading(false);
		} catch (error) {
			console.log("Error accepting giveaway", error);
		}
	};

	const handlerModalAllRequest = async (e, type, shiftData = {}) => {
		if (type === "confirm" && (shiftData?.giveAwayId || shiftDataAllGiveAway?.giveAwayId)) {
			await orgUtils.saveGiveAwayRequest(shiftData?.giveAwayId ? shiftData : shiftDataAllGiveAway);
			await orgUtils.destroyAllGiveaway(shiftData?.giveAwayId ? shiftData?.giveAwayId : shiftDataAllGiveAway?.giveAwayId);
			await loadRoles();
		}
		// setOpenAllGiveAway(false);
		setShiftDataAllGiveAway({});
	};
	const handlerModalGiveAway = async (e, type, k) => {
		try {
			if (type === "confirm" && (k?.shiftData ? k : openGiveAway)?.shiftData) {
				await orgUtils.saveGiveAwayRequest((k?.shiftData ? k : openGiveAway)?.shiftData);
				(k?.shiftData ? k : openGiveAway).status === "APPROVE_BY_MEMBER" &&
					(await notifyUser(
						[person],
						`${personUtils.displayName(
							(k?.shiftData ? k : openGiveAway)?.giveAway.fromMember?.person
						)} would like you to approve giving a shift to ${personUtils.displayName(
							(k?.shiftData ? k : openGiveAway)?.giveAway.memberID?.person
						)}`
					));
				if ((k?.shiftData ? k : openGiveAway).status === "APPROVE_BY_MANAGER") {
					const shiftData = {
						id: (k?.shiftData ? k : openGiveAway)?.giveAway?.shift?.id,
						member: (k?.shiftData ? k : openGiveAway)?.giveAway?.memberID
					};
					await orgUtils.giveAwayShift(shiftData);
				}
				person.isAdmin || person.assignedRequests ? await loadRoles() : await loadRequests();
			}
			setOpenGiveAway({ state: false });
		} catch (error) {}
	};
	const approveClockIn = async (shift) => {
		try {
			setIsLoading(true);
			await orgUtils.approveClockIn(shift);
			await notifyUser([shift?.member?.person?.person], `Your Clock In/Out has been Approved`);
			await loadPendingClockIns(organisation.id);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	const denyClockIn = async (shift) => {
		try {
			setIsLoading(true);
			await orgUtils.denyClockIn(shift);
			await notifyUser([shift?.member?.person?.person], `Your Clock In/Out has been Denied`);
			await loadPendingClockIns(organisation.id);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	const swapWithShift = async () => {
		try {
			if (swap.fromShift && swap.id) {
				const { toShift, fromShift, note, id } = swap;
				setIsLoading(true);
				const shiftData = {
					...{
						organisationID: person.currentOrganisation.id,
						fromMemberID: fromShift.memberID,
						fromShiftId: fromShift.id,
						toShiftId: toShift.id || " ",
						memberID: toShift.memberID,
						expirationTime: fromShift?.shiftStart + 172800,
						note: (note ? note + " | " : "") + "Accepting from Request to All"
					}
				};
				await orgUtils.saveShiftSwapRequest(shiftData);
				await orgUtils.destroyAllRequest(id);
				const forAllMembersRequests = await orgUtils.getAllRequest(
					members?.find((m) => m.person === person?.person)?.roleIDs,
					organisation.id
				);
				setSpecialShifts(forAllMembersRequests.items.filter((k) => k.fromMemberID !== memberID));
				await notifyUser(
					[swap.toShift.member.personID],
					`${
						personUtils.displayName(swap.fromShift.member.person) || "Someone"
					} has accepted your Request to All for swap of ${formateEpochToShortMonthDate(
						toShift.baseDay
					)} in exchange for ${formateEpochToShortMonthDate(swap.fromShift.baseDay)}`
				);
				setSwap({});
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handlerChooseModuleModal = (e, type) => {
		setNoteModuleOpen(false);
	};
	const addNote = async (detail, type, admin, status) => {
		setTypeAndDetail({ detail: detail, type: type, admin: admin, status: status });
		setNoteModuleOpen(true);
	};
	const NoteModule = ({ open, handlerModal, setNoteModuleOpen }) => {
		return (
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
					<div className="flex items-center justify-center h-full w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="bg-white transform rounded-lg w-fit w-72">
								<span
									className="inline-block absolute top-0 right-0 mr-2 mt-4 cursor-pointer"
									onClick={(e) => handlerModal(e, "close")}
								>
									<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								<div className="w-full flex flex-col px-2 py-3">
									<div className="flex flex justify-center gap-2">
										<Form
											enableReinitialize={true}
											initialValues={{ note: "" }}
											disabled={false}
											onSubmit={async (data) => {
												try {
													setNoteInState(true);
													if (typeAndDetail.type === "Swap Shift") {
														if (typeAndDetail.status === "APPROVE_BY_MANAGER") {
															const shiftData = {
																...{
																	id: typeAndDetail.detail.id,
																	organisationID: typeAndDetail.detail.organisationID,
																	fromMemberID: typeAndDetail.detail.fromMemberID,
																	fromShiftId: typeAndDetail.detail.fromShiftId,
																	toShiftId: typeAndDetail.detail.toShiftId,
																	memberID: typeAndDetail.detail.memberID,
																	note: data.note || null,
																	status: typeAndDetail.status
																}
															};
															await orgUtils.saveShiftSwapRequest(shiftData);
															const swapShiftData = {
																...{
																	id: typeAndDetail.detail.fromShiftId,
																	member: typeAndDetail.detail.memberID,
																	toShiftId: typeAndDetail.detail.toShiftId,
																	memberID: typeAndDetail.detail.fromMemberID,
																	note: typeAndDetail.detail?.note || null
																}
															};
															await orgUtils.swapRequest(swapShiftData);
															await orgUtils.dstroyAllRequestByShift(typeAndDetail.detail.fromShiftId);
															await orgUtils.dstroyAllRequestByShift(typeAndDetail.detail.toShiftId);
														}
														if (typeAndDetail.status === "DENIED_BY_MANAGER") {
															const shiftData = {
																...{
																	id: typeAndDetail.detail?.id,
																	organisationID: typeAndDetail.detail?.organisationID,
																	fromMemberID: typeAndDetail.detail?.fromMemberID,
																	fromShiftId: typeAndDetail.detail?.fromShiftId,
																	toShiftId: typeAndDetail.detail?.toShiftId,
																	memberID: typeAndDetail.detail?.memberID,
																	status: "DENIED_BY_MANAGER",
																	note: data.note || typeAndDetail.detail?.note || null
																}
															};
															await orgUtils.saveShiftSwapRequest(shiftData);
															typeAndDetail.admin &&
																(await notifyUser([typeAndDetail.admin], `Your Swap Shift request has been denied`));
														}
													}
													if (typeAndDetail.type === "Give Away") {
														if (typeAndDetail.status === "DENIED_BY_MANAGER") {
															const shiftData = {
																...{
																	id: typeAndDetail.detail?.id,
																	organisationID: typeAndDetail.detail?.organisationID,
																	fromMemberID: typeAndDetail.detail?.fromMemberID,
																	fromShiftId: typeAndDetail.detail?.fromShiftId,
																	toShiftId: typeAndDetail.detail?.toShiftId,
																	memberID: typeAndDetail.detail?.memberID,
																	status: "DENIED_BY_MANAGER",
																	note: data.node || typeAndDetail.detail?.note || null
																}
															};
															await orgUtils.saveGiveAwayRequest(shiftData);
														}
														if (typeAndDetail.status === "APPROVE_BY_MANAGER") {
															const shiftData = {
																...{
																	id: typeAndDetail.detail.id,
																	organisationID: typeAndDetail.detail.organisationID,
																	fromMemberID: typeAndDetail.detail.fromMemberID,
																	fromShiftId: typeAndDetail.detail.fromShiftId,
																	toShiftId: typeAndDetail.detail.toShiftId,
																	memberID: typeAndDetail.detail.memberID,
																	status: "APPROVE_BY_MANAGER",
																	note: data.note || typeAndDetail.detail?.note || null
																}
															};
															await orgUtils.saveGiveAwayRequest(shiftData);

															const give = {
																...{
																	id: typeAndDetail.detail?.shift?.id,
																	member: typeAndDetail.detail?.memberID
																}
															};
															await orgUtils.giveAwayShift(give);
															await orgUtils.destroyGiveaway(typeAndDetail.detail.id);

															person.isAdmin || person.assignedRequests ? await loadRoles() : await loadRequests();
														}
													}
													if (typeAndDetail.type === "Time Off") {
														if (typeAndDetail.status === "APPROVE") {
															await orgUtils.changeStatusTimeOff(typeAndDetail.detail, typeAndDetail.status, data.note);
															typeAndDetail.admin &&
																(await notifyUser(
																	[typeAndDetail.admin],
																	`Your ${typeAndDetail.type} request has been approved by your manager`
																));
															// await loadRoles();
														}
														if (typeAndDetail.status === "NOT_APPROVE") {
															await orgUtils.changeStatusTimeOff(typeAndDetail.detail, typeAndDetail.status, data.note);
															typeAndDetail.admin &&
																(await notifyUser(
																	[typeAndDetail.admin],
																	`Your ${typeAndDetail.type} request has been denied`
																));
														}
													}
													person.isAdmin || person.assignedRequests ? await loadRoles() : await loadRequests();
													setNoteInState(false);
													setTypeAndDetail({});
													setNoteModuleOpen(false);
												} catch (error) {
													setNoteInState(false);
													setTypeAndDetail({});
													setMessage(
														showFailure({
															title: "Unable to save Organisation.",
															subTitle: error.message
														})
													);
												}
											}}
										>
											<div className="w-full h-full pt-4">
												<div className="flex w-full">
													<div className="w-3/4 md:w-full">
														<InputField label="Add a note?" name="note" placeholder="Any reaon you'd like to add?" />
													</div>
												</div>
												<div className="flex gap-2">
													<div className="flex justify-end pt-4">
														<Button type="submit" label="Notify Employee" disabled={noteInState} />
													</div>
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};
	const handlerModal = async (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			await swapWithShift();
		}
		setOpen(false);
		setSwap({});
	};
	const confirmPopup = (toShift, fromShift, note, id) => {
		setSwap({ toShift: toShift, fromShift: fromShift, note: note, id: id });
		setOpen(true);
	};
	const changeTimeOffType = async (off) => {
		setIsLoading(true);
		const timeOff = {
			id: off.id,
			organisationID: off.organisationID,
			fromDate: off.fromDate,
			toDate: off.toDate,
			memberID: off.memberID,
			status: off.status,
			isPaid: !off?.isPaid
		};
		await orgUtils.saveTimeOff(timeOff);
		await loadRoles();
		setIsLoading(false);
	};
	return (
		<Background currentRoute={"requests"}>
			<>
				<ConfirmModal
					handlerModal={handlerModal}
					open={open}
					className="text-base font-medium p-1 text-gray-900"
					title={`Offer your ${swap?.fromShift?.role?.name} shift on ${
						swap?.fromShift?.baseDay ? formateEpochToShortMonthDate2(swap.fromShift.baseDay) : ""
					} for a ${swap?.toShift?.role?.name} shift on ${
						swap?.toShift?.baseDay ? formateEpochToShortMonthDate2(swap.toShift.baseDay) : ""
					}`}
					subTitle="Are you sure?"
				/>
				<NoteModule open={noteMoule} handlerModal={handlerChooseModuleModal} setNoteModuleOpen={setNoteModuleOpen} />

				{/* <ReactTooltip effect="solid" /> */}
				<div className="mx-2 flex flex-wrap">
					<div className={`w-full`}>
						<ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" role="tablist">
							{request.map((r, index) => {
								// eslint-disable-next-line
								let count = r.vars.reduce((p, c) => p + eval(c)?.length, 0);
								return (
									<li className="-mb-px mr-2 last:mr-0 flex-auto text-center" key={index}>
										<a
											className={
												"flex items-center justify-center gap-2 text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
												(openTab === index + 1 ? "text-white bg-" + color + "-600" : "text-gray-600 bg-white")
											}
											onClick={(e) => {
												e.preventDefault();
												setOpenTab(index + 1);
											}}
											data-toggle="tab"
											href={`${r.href}`}
											role="tablist"
										>
											{r.name}
											{count > 0 && (
												<div className="rounded-full bg-pink-600 w-5 h-5 text-white center flex items-center justify-center top-0 left-6">
													{count}
												</div>
											)}
										</a>
									</li>
								);
							})}
							{(person.isAdmin || person.assignedRequests) && (
								<li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
									<a
										className={
											"flex items-center justify-center gap-2 text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
											(openTab === 6 ? "text-white bg-" + color + "-600" : "text-gray-600 bg-white")
										}
										onClick={(e) => {
											e.preventDefault();
											setOpenTab(6);
										}}
										data-toggle="tab"
										href="#link3"
										role="tablist"
									>
										Clock In / Out
										{perndingClockInOut.length > 0 ? (
											<div className="rounded-full bg-pink-600 w-5 h-5 text-white center flex items-center justify-center top-0 left-6">
												{perndingClockInOut.length}
											</div>
										) : null}
									</a>
								</li>
							)}
						</ul>
						<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
							<div className="px-4 py-3 flex-auto">
								<div className={`tab-content tab-space`}>
									<div
										style={{ visibility: isLoading ? "visible" : "hidden" }}
										className="bg-white w-full h-full opacity-50 absolute"
									>
										<Loading text="Fetching Pending Requests..." />
									</div>
									<div className={openTab === 1 ? "block" : "hidden"} id="my-requests">
										{myRequests.length > 0 ? (
											<ul className="flex flex-col divide-y w-full">
												{myRequests.map((request, index) => (
													<li key={index} className="flex flex-row">
														<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
															<div className="flex-1">
																{/* Display Request Type (Swap Shift or Give Away) */}
																<span className="text-gray-600">
																	{request.fromShift ? "Swap Shift: " : "Give Away: "}
																	<span className="font-medium">
																		{request.fromMemberID === memberID
																			? "You"
																			: personUtils.displayName(
																					request.fromShift
																						? request.fromShift.member.person
																						: request.fromMember.person
																			  ) ||
																			  (request.fromShift
																					? request.fromShift.member.orgUsername
																					: request.fromMember.orgUsername) ||
																			  "User Removed"}
																	</span>
																	{" ("}
																	{request.fromShift ? request.fromShift.role.name : request.shift.role.name}
																	{") "}
																</span>
																<br className="sm:hidden" />
																<span className="text-gray-600 text-xs">
																	{request.fromShift
																		? formateEpochToShortMonthDate2(request.fromShift.shiftStart, true)
																		: formateEpochToShortMonthDate2(request.shift.shiftStart, true)}{" "}
																	{request.fromShift ? "asked for" : "to"}
																</span>
																<span className="font-medium mr-1 ">
																	{" "}
																	{request.memberID === memberID && request.toShift
																		? "your"
																		: request.memberID === memberID
																		? "you"
																		: personUtils.displayName(
																				request.toMember ? request.toMember.person : request.member.person
																		  ) ||
																		  (request.toMember
																				? request.toMember.orgUsername
																				: request.member.orgUsername) ||
																		  "User Removed"}
																</span>
																<span className="text-gray-600 text-xs">
																	{request.toShift
																		? formateEpochToShortMonthDate2(request.toShift.shiftStart, true)
																		: ""}
																	<span className="text-blue-900 inline-flex ml-1">
																		{request.note && (
																			<Tooltip trigger={["click", "hover"]} overlay={request.note}>
																				<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
																			</Tooltip>
																		)}
																	</span>
																</span>
															</div>
															<div className="flex flex-row w-1/3 justify-center">
																<span className="text-blue-900 ml-auto">
																	{request.status === "APPROVE_BY_MEMBER"
																		? `Accepted by ${
																				request.memberID === memberID
																					? "you"
																					: personUtils.displayName(
																							request.toMember
																								? request.toMember.person
																								: request.member.person
																					  ) ||
																					  (request.toMember
																							? request.toMember.orgUsername
																							: request.member.orgUsername) ||
																					  "User Removed"
																		  }`
																		: request.status === "APPROVE_BY_MANAGER"
																		? `${request.fromShift ? "Swap" : "Give Away"} approved`
																		: request.status === "PENDING"
																		? `Pending from ${
																				request.memberID === memberID
																					? "you"
																					: personUtils.displayName(
																							request.toMember
																								? request.toMember.person
																								: request.member.person
																					  ) ||
																					  (request.toMember
																							? request.toMember.orgUsername
																							: request.member.orgUsername) ||
																					  "User Removed"
																		  }`
																		: request.status === "DENIED_BY_MEMBER"
																		? `Denied by ${
																				request.memberID === memberID
																					? "you"
																					: personUtils.displayName(
																							request.toMember
																								? request.toMember.person
																								: request.member.person
																					  ) ||
																					  (request.toMember
																							? request.toMember.orgUsername
																							: request.member.orgUsername) ||
																					  "User Removed"
																		  }`
																		: request.status === "DENIED_BY_MANAGER" && "Request denied"}
																</span>
															</div>
														</div>
													</li>
												))}
											</ul>
										) : (
											<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
												No Pending My Requests
											</h3>
										)}
									</div>
									<div className={openTab === 2 ? "block" : "hidden"} id="time-off">
										{pendingtimeOff.length > 0 ? (
											<div className={openTab === 2 ? "block" : "hidden"} id="time-off">
												<TimeOffRequestsMemo
													loadRoles={loadRoles}
													pendingTimeOff={pendingtimeOff}
													setPendingTimeOff={setPendingTimeOff}
													approveTimeOff={approveTimeOff}
													setApproveTimeOff={setApproveTimeOff}
													memberAllowedHours={memberAllowedHours}
													setMemberAllowedHours={setMemberAllowedHours}
													isLoading={isLoading}
													setIsLoading={setIsLoading}
												/>
											</div>
										) : (
											<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
												No Pending Time Off Request
											</h3>
										)}
									</div>
									<div className={openTab === 3 ? "block" : "hidden"} id="swap-shift">
										{pendingSwapShiftRequest.length + specialShifts.length > 0 ? (
											<SwapShiftRequestsMemo
												specialShifts={specialShifts}
												pendingSwapShiftRequest={pendingSwapShiftRequest}
												shiftsAvailableForSwap={shiftsAvailableForSwap}
												approveSwapShift={approveSwapShift}
												setIsLoading={setIsLoading}
												loadRoles={loadRoles}
												addNote={addNote}
												members={members}
												memberID={memberID}
												confirmPopup={confirmPopup}
											/>
										) : (
											<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
												No Pending Swap Shift Request
											</h3>
										)}
									</div>
									<div className={openTab === 4 ? "block" : "hidden"} id="give-away">
										{pendingGiveAwayRequest?.length + allGiveAway?.length > 0 ? (
											<GiveAwayRequestsMemo
												allGiveAway={allGiveAway}
												pendingGiveAwayRequest={pendingGiveAwayRequest}
												addNote={addNote}
												acceptAllGiveAway={acceptAllGiveAway}
												setIsLoading={setIsLoading}
												loadRoles={loadRoles}
												acceptGiveAway={acceptGiveAway}
												memberID={memberID}
											/>
										) : (
											<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
												No Pending Give Away Request
											</h3>
										)}
									</div>

									<div className={openTab === 5 ? "block" : "hidden"} id="previous-requests">
										{approveTimeOff.length > 0 || approveSwapShiftRequest.length > 0 || approveGiveAwayRequest.length ? (
											<ApproveRequestsMemo
												approveTimeOff={approveTimeOff}
												approveSwapShiftRequest={approveSwapShiftRequest}
												approveGiveAwayRequest={approveGiveAwayRequest}
												approveSwapShift={approveSwapShift}
												changeTimeOffType={changeTimeOffType}
												manageShifts={manageShifts}
												loadRoles={loadRoles}
												setIsLoading={setIsLoading}
											/>
										) : (
											<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
												No Previous Request
											</h3>
										)}
									</div>
									<div className={openTab === 6 ? "block" : "hidden"} id="link3">
										{perndingClockInOut.length > 0 ? (
											<ul className="flex flex-col divide-y w-full">
												{perndingClockInOut.map((shift, index) => {
													let updatedBreak = JSON.parse(shift.clockInBreak);
													return (
														<li key={index} className="flex flex-row">
															<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
																<div className="flex flex-1 pl-1 gap-2">
																	<div>
																		<span className="text-gray-600">Clock In/Out: </span>
																		<br className="sm:hidden" />
																		<span className="font-medium">
																			{shift?.member?.id
																				? personUtils.displayName(shift?.member?.person) ||
																				  shift?.member?.orgUsername
																				: shift?.member?.orgUsername || "User Removed"}{" "}
																		</span>
																	</div>
																	<div className="inline-flex flex-col">
																		<span className="text-gray-600 text-xs">
																			{" "}
																			Shift Timings - {formateEpochToShortMonthDate(
																				shift.shiftStart,
																				true
																			)} to{" "}
																			{shift.setAsClose
																				? "Close"
																				: formateEpochToShortMonthDate(shift.shiftEnd, true)}{" "}
																			{shift.hasBreak && (
																				<span className="inline-flex">
																					{" "}
																					Break :
																					<BriefcaseIcon className="h-4 w-4" />
																					{shift.breakDuration
																						? `${shift.breakDuration} Mins`
																						: `${epochToTimeStr(shift.breakStart)} - ${epochToTimeStr(
																								shift.breakEnd
																						  )}`}
																				</span>
																			)}
																		</span>
																		<hr />
																		<span className="text-gray-600 text-xs">
																			{" "}
																			Clock Timings - {formateEpochToShortMonthDate(
																				shift.clockIn,
																				true
																			)} to {formateEpochToShortMonthDate(shift.clockOut, true)}{" "}
																			{updatedBreak.clockInHasBreak && (
																				<span className="inline-flex">
																					{" "}
																					Break :
																					<BriefcaseIcon className="h-4 w-4" />
																					{updatedBreak.clockInBreakDuration
																						? `${updatedBreak.clockInBreakDuration} Mins`
																						: `${epochToTimeStr(
																								updatedBreak.clockInBreakstart
																						  )} - ${epochToTimeStr(updatedBreak.clockInBreakend)}`}
																				</span>
																			)}
																		</span>
																	</div>
																</div>
																{(person.isAdmin || person.assignedRequests) && (
																	<div className="flex sm:flex-row flex-col gap-1">
																		<button
																			className="bg-darkww-500 ml-2 inline-flex items-center py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
																			onClick={() => approveClockIn(shift)}
																		>
																			<CheckCircleIcon className="w-6 h-6" />
																			<span className="sm:block hidden pl-1">Approve</span>
																		</button>
																		<button
																			className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
																			onClick={() => denyClockIn(shift)}
																		>
																			<XCircleIcon className="w-6 h-6" />
																			<span className="sm:block hidden pl-1">Deny</span>
																		</button>
																	</div>
																)}
															</div>
														</li>
													);
												})}
											</ul>
										) : (
											<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
												No Clock In/Out Request
											</h3>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</Background>
	);
};

export default memo(StaffMembers);

import React from "react";
import HeaderMenu, { PrimaryLink } from "../components/headers/light";
import logoImage from "ww-images/workwall-logo.svg";
import sustainableHospitalityImage from "ww-images/sustainible-hospitality.png";

const financialAidPosts = [
	{
		id: 4,
		title: "Financial Aid and Grants Guide for Irish Hospitality Venues",
		href: "#",
		imageUrl: sustainableHospitalityImage,
		date: "Apr 2, 2024",
		datetime: "2024-04-2",
		category: { title: "Finance", href: "#" },
		author: {
			name: "Richard Goggin",
			role: "Founder",
			href: "#",
			imageUrl: logoImage
		}
	}
];

export default function Blog4({ scrollTo }) {
	return (
		<>
			<HeaderMenu roundedHeaderButton={PrimaryLink} scrollTo={scrollTo} />
			{financialAidPosts.map((post) => (
				<article key={post.id} className="flex flex-col mx-4 items-center justify-between">
					<div className="relative w-full">
						<img src={post.imageUrl} alt="Irish hospitality financial aid" className="w-1/3 h-auto mx-auto mt-20" />
						<div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
					</div>
					<div className="max-w-2xl">
						<div className="mt-8 flex items-center text-md">
							<time dateTime={post.datetime} className="text-gray-500">
								{post.date}
							</time>
							<span className="mx-8 text-gray-500 text-md">4 mins</span>
							<a
								href={post.category.href}
								className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
							>
								{post.category.title}
							</a>
						</div>
						<div className="group relative text-center">
							<div className="mt-3 flex justify-center text-lg text-3xl font-semibold leading-9 text-gray-900 group-hover:text-gray-600">
								<a href={post.href}>{post.title}</a>
							</div>
						</div>
						<div className="mt-3 text-left">
							<p>
								The hospitality industry in Ireland faces unprecedented challenges in the wake of the COVID-19 pandemic, increased VAT
								rates, and rising costs across wages, energy, and food supplies. This guide aims to assist bars and restaurants in
								navigating and securing financial aid and grants to alleviate these pressures, focusing on sustainability, energy
								efficiency, and online trading enhancements. These supports are available to you now as a business in the hospitality
								and tourism sector (restaurants, bars, cafes, hotels, etc.). Remember that you can start out getting an energy audit
								and advice on how you can save considerably on your energy bills and planning fully funded.
							</p>

							<div className="mt-3 text-xl font-semibold text-center underline">Available Grants and Financial Aid</div>

							<p className="my-3 text-lg text-center">
								<strong>Local Enterprise Office (LEO) Supports</strong>
							</p>
							<p>
								<strong>Overview:</strong> LEOs provide a first-stop shop for those looking to start, grow, and develop a business.
								With 31 offices across Ireland, they offer financial support, advice, and guidance to small businesses.
							</p>
							<p className="my-3">
								<strong>Grants for Sustainability and Energy Efficiency:</strong>
							</p>
							<ul className="list-disc space-y-4 ml-4">
								<li>
									<strong>Energy Efficiency Grants:</strong> Aimed at businesses looking to reduce their energy use and costs, these
									grants can cover a portion of the expenses involved in upgrading facilities or processes. This is a grant for 50%
									of costs up to €5,000. For hospitality businesses, we expect an announcement increasing this grant to €8000 at a
									rate of 75%.
								</li>
								<li>
									<strong>Sustainability Mentoring:</strong> Beyond financial grants, LEOs provide mentoring programs focused on
									sustainability practices. These programs pair businesses with experienced mentors who can guide them in
									implementing sustainable business practices, improving energy efficiency, and reducing environmental impact.
								</li>
								<li>
									<strong>Trading Online Vouchers:</strong> To assist small businesses in enhancing their online trading presence,
									LEOs offer vouchers of up to €2,500. This is crucial for businesses aiming to expand their market reach and
									increase sales through online platforms. Eligibility includes having no more than 10 employees, less than €2m ARR,
									attend a Trading Online Seminar, and the business must have a limited or no e-commerce presence.
								</li>
								<li>
									<strong>Green for Micro:</strong> This initiative is designed to help small businesses transition towards a more
									sustainable and environmentally friendly operation. The program offers 2 days of expert advice and technical
									support from consultants in areas such as reducing energy consumption, minimizing waste, and leveraging
									sustainable resources. The support is tailored to businesses with up to fifty employees and aims to prepare them
									for the low-carbon, more resource-efficient economy of the future.
								</li>
							</ul>
							<p className="mt-3">
								<strong>How to Apply: </strong>Visit your local LEO website to find application forms and submission guidelines. It's
								recommended to attend one of their information sessions or workshops for detailed guidance on the application process:{" "}
								<a href="https://www.localenterprise.ie/" target="_blank" rel="noopener noreferrer">
									https://www.localenterprise.ie/
								</a>
							</p>
						</div>

						{/* SEAI Supports */}
						<div>
							<p className="mt-3 text-lg text-center">
								<strong>Sustainable Energy Authority of Ireland (SEAI) Supports</strong>
							</p>
							<p className="my-2">
								<strong>Overview:</strong> SEAI offers various grants for businesses to improve their energy efficiency and
								sustainability, including:
							</p>
							<ul className="list-disc space-y-3 ml-4">
								<li>
									<strong>Energy Audit Grants:</strong> This helps identify cost-saving opportunities and reduce energy use and will
									cover €2,000 of the cost for that audit, which should cover the entire thing for SMEs and help you save
									considerably moving forward.
								</li>
								<li>
									<strong>EXEED Certified Grant:</strong> The EXEED Certified grant scheme, available until December 31, 2024,
									supports energy projects adhering to the EXEED Certified standard, promoting energy efficiency, cost reduction,
									competitiveness, and sustainability. Open to all organizations planning energy investments, it offers up to
									€3,000,000 per project, determined by grant intensity levels based on SME status and value-for-money assessments.
									The grant covers professional services and capital required for EXEED processes, including design-stage and
									strategic input from Energy Efficient Design Experts.
								</li>
							</ul>
							<p className="mt-3">
								<strong>How to Apply: </strong>Visit the SEAI website:{" "}
								<a href="www.seai.ie" target="_blank" rel="noopener noreferrer">
									www.seai.ie
								</a>{" "}
								to find specific grants and their application processes. Engaging an energy consultant to assist with the application
								can be beneficial.
							</p>
						</div>

						{/* Growth and Sustainability Loan Scheme */}
						<div>
							<p className="mt-3 text-lg text-center">
								<strong>Growth and Sustainability Loan Scheme</strong>
							</p>
							<p>
								This €500m Scheme, launched in September 2023 and funded by the Department of Enterprise, Trade and Employment and the
								Department of Agriculture, Food and the Marine, targets a minimum of 30% of the lending volume towards environmental
								sustainability purposes to encourage Irish businesses, including hospitality, to take positive actions in support of
								the{" "}
								<a
									href="https://www.gov.ie/en/publication/67104-climate-action-plan/"
									target="_blank"
									rel="noopener noreferrer"
									className="text-darkww-600 hover:text-blue-700"
								>
									Climate Change Agenda
								</a>
								. Up to 70% of lending is for strategic investments to increase productivity and competitiveness and underpin future
								business sustainability and growth.
							</p>
							<p className="mt-3">
								<strong>Key features of the Scheme:</strong>
							</p>
							<ul className="list-disc space-y-2 ml-4">
								<li>Loan amounts from €25,000 to €3,000,000</li>
								<li>Repayment terms from 7 to 10 years</li>
								<li>Unsecured loans up to €500,000</li>
								<li>
									Reduced interest rates, with an additional 0.25% discount applied to businesses with climate action and
									environmentally sustainable eligibility.
								</li>
								<li>Available until 30 June 2026 or until fully subscribed (whichever is earlier)</li>
							</ul>

							<p className="mt-3">
								<strong>Loan Purpose:</strong>
							</p>
							<ul>
								<li>
									Investments to encourage the growth and resilience of the business or improve its performance in terms of climate
									action and environmental sustainability.
								</li>
								<li className="mt-2">
									To qualify for an additional ‘green discount’ a project/activity being undertaken by an SME must be on the list of
									eligible Scheme measures. Further information is available at{" "}
									<a
										href="https://sbci.gov.ie/products/growth-and-sustainability-loan-scheme"
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
									>
										https://sbci.gov.ie/products/growth-and-sustainability-loan-scheme
									</a>{" "}
									and an applicant can check the eligibility of specific at{" "}
									<a
										href="https://sbci.eib-greenchecker.preview.adelphi.app/selection"
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
									>
										https://sbci.eib-greenchecker.preview.adelphi.app/selection
									</a>
									.
								</li>
							</ul>
						</div>

						{/* Microfinance Ireland */}
						<div>
							<p className="mt-3 text-lg text-center">
								<strong>Microfinance Ireland</strong>
							</p>
							<p>
								<strong>Overview:</strong> Provides small loans up to €25,000 to start-ups and small businesses across all sectors,
								including hospitality, that may be having difficulty in securing finance from banks and other commercial lending
								providers.
							</p>
							<p>
								<strong>Qualification: </strong>Businesses with fewer than ten employees and a turnover of up to €2 million.
							</p>
							<p className="mt-3">
								<strong>How to Apply: </strong>Directly through the Microfinance Ireland website, where you can download an
								application form and submit it along with your business plan and financial statements.
							</p>
						</div>

						{/* Conclusion */}
						<div>
							<p className="mt-3 text-lg text-center">
								<strong>Conclusion</strong>
							</p>
							<p>
								This guide provides a starting point for bars and restaurants and other hospitality businesses in Ireland to explore
								and apply for financial aid and grants. By taking advantage of these opportunities, businesses can mitigate some of
								the financial challenges they face, invest in sustainable practices, and position themselves for future growth. If you
								think WorkWall can help you with advice on any of these or to improve the digitilisation of your business and help you
								reduce your staff turnover please feel free to get in touch. We are here to help.
							</p>
						</div>
						<div className="relative mt-8 flex items-center gap-x-4">
							<img src={logoImage} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
							<div className="text-sm leading-6">
								<p className="font-semibold text-gray-900">
									<a href={post.author.href}>{post.author.name}</a>
								</p>
								<p className="text-gray-600">{post.author.role}</p>
							</div>
						</div>
					</div>
				</article>
			))}
		</>
	);
}

import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	CheckBox,
	ConfirmModal,
	Form,
	InputField,
	LogoPicker,
	ReactTooltip,
	Yup,
	showFailure,
	showSuccess,
	useNavigate,
	useSimpleMessage
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

const orgSchema = Yup.object().shape({
	name: Yup.string().required().min(2),
	StaffCost: Yup.number().nullable().max(100).min(1)
});

const General = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const { person, setPerson } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const [orgSaving, setOrgSaving] = useState(false);
	const [showVat, setShowVat] = useState(false);
	const [paidBreak, setPaidBreak] = useState(false);
	const [allPaidBreak, setAllPaidBreak] = useState(false);
	const [disableClockIn, setDisableClockIn] = useState(false);
	const [enablePhoto, setEnablePhoto] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [windowReload, setwindowReload] = useState(false);
	const [memberHourlyRate, setMemberHourlyRate] = useState(false);
	const [memberClockInView, setMemberClockInView] = useState(false);
	const [allowPaidHol, setAllowPaidHol] = useState(false);
	const [startDay, setStartDay] = useState(1); // New state for start day
	const [enableStaffTimeOff, setEnableStaffTimeOff] = useState(true);
	const [enableStaffPreferences, setEnableStaffPreferences] = useState(true);

	let navigate = useNavigate();

	const logoUpdated = async (newURI) => {
		const updatedLogo = { ...organisation, ...{ logoURI: newURI } };
		const updatedOrg = await orgUtils.save(updatedLogo);
		orgUtils.loadLogo(updatedOrg).then((logoDetails) => {
			const newOrg = {
				...updatedOrg,
				...{
					maxAllowedMembers:
						window.sessionStorage.getItem("mam") ?? window.sessionStorage.setItem("mam" ?? organisation?.items?.[0]?.quantity ?? 20)
				},
				...{ logo: logoDetails },
				...{ logoURI: newURI },
				...{ members: orgUtils.mapMembers(updatedOrg.members) },
				...{ report: orgUtils.mapReport(updatedOrg?.report?.items || [], updatedOrg.id) },
				...{ departments: updatedOrg?.departments?.items || [] }
			};
			setOrganisation(newOrg);
		});
	};

	useEffect(() => {
		if (organisation.vat === true) setShowVat(true);
		if (organisation?.paidBreak === true) setPaidBreak(true);
		if (organisation?.enablePhoto === true) setEnablePhoto(true);
		if (organisation?.allPaidBreak === true) setAllPaidBreak(true);
		if (organisation?.disableClockIn === "YES") setDisableClockIn(true);
		if (organisation.staffInputhourlyRate === "YES") setMemberHourlyRate(true);
		if (organisation.staffClockInView === "YES") setMemberClockInView(true);
		if (organisation.allowPaidHol === "YES") setAllowPaidHol(true);
		if (organisation.startDay !== null) {
			setStartDay(organisation.startDay);
		} else {
			setStartDay(1); // Default to Monday
		}
		setEnableStaffTimeOff(organisation.enableStaffTimeOff ?? true);
		setEnableStaffPreferences(organisation.enableStaffPreferences ?? true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation?.id]);

	const handlerConfirmModal = async (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			await orgUtils
				.save({ name: "New Blank" }) //kept static name for now
				.then(async (org) => await orgUtils.addMember(person, org.id, person.email))
				.then(async (org) => {
					const { person: updatedPerson, organisation: updatedOrg } = org;
					const mergedOrg = {
						...updatedOrg,
						...(await orgUtils.mapOrgMembers(updatedOrg)),
						...{ report: await orgUtils.mapReport(updatedOrg?.report?.items || [], updatedOrg.id) },
						...{ departments: updatedOrg?.departments?.items || [] },
						...{
							maxAllowedMembers:
								window.sessionStorage.getItem("mam") ??
								window.sessionStorage.setItem("mam", organisation?.subscription?.items?.[0]?.quantity ?? 20)
						}
					};
					setPerson({ ...person, ...updatedPerson, ...{ isAdmin: true }, ...{ currentOrganisation: mergedOrg } });
					setOrganisation(mergedOrg);
				})
				.then(
					setMessage(
						showSuccess({
							title: "New Location has been added successfully."
						})
					)
				);
		}
		setOpenConfirm(false);
		navigate("/dashboard");
	};

	return (
		<>
			<ConfirmModal
				handlerModal={handlerConfirmModal}
				open={openConfirm}
				className="text-base font-medium text-gray-900"
				title="Add New Location?"
				subTitle="Clicking confirm will create a new location that will act independently of this one (within the same subscription). You can share employees in these locations once added."
			/>
			<ReactTooltip />
			<div className="divide-y divide-gray-200 lg:col-span-9 min-h-96 h-full">
				<div className="py-6 px-4 sm:p-6 lg:pb-8">
					<div className="flex justify-between">
						<div>
							<h2 className="text-lg leading-6 font-medium text-darkww-800">General</h2>
							<p className="mt-1 text-sm text-gray-500">Just some general information about your organsiation.</p>
						</div>
						{person.organisations?.items?.filter((k) => k?.owner === person?.currentOrganisation?.owner)?.length < 8 && (
							<button
								className="bg-darkww-500  text-white w-36 sm:w-28 h-10 px-2 py-0 font-bold text-sm rounded-md"
								onClick={() => setOpenConfirm(true)}>
								Add Location
							</button>
						)}
					</div>
					<Form
						validationSchema={orgSchema}
						enableReinitialize={true}
						initialValues={{ ...organisation, startDay }}
						disabled={false}
						onSubmit={async (data) => {
							// Ensure startDay defaults to 1 (Monday) if not set
							data.startDay = data.startDay != null ? data.startDay : 1;
							try {
								setOrgSaving(true);
								let updatedPerson = null;
								// If the person doesn't already have a profile then create one for them/ They'll be added as a member so will need it.
								if (!person.id) {
									updatedPerson = await personUtils.saveProfile(person);
								}

								data.StaffCost = data.StaffCost ? parseInt(data.StaffCost) : null;
								if (data.vat) {
									data.foodPercentage = data.foodPercentage ? parseFloat(data.foodPercentage) : null;
									data.drinkPercentage = data.drinkPercentage ? parseFloat(data.drinkPercentage) : null;
								}
								if (data.memberHourlyRate) {
									data.staffInputhourlyRate = "YES";
								} else {
									data.staffInputhourlyRate = "NO";
								}
								if (data.memberClockInView) {
									data.staffClockInView = "YES";
								} else {
									data.staffClockInView = "NO";
								}

								if (data.allowPaidHol) {
									data.staffHolidayPay = "YES";
								} else {
									data.staffHolidayPay = "NO";
								}
								if (data.disableClockIn) {
									data.disableClockIn = "YES";
								} else {
									data.disableClockIn = "NO";
								}
								if (data.enablePhoto) {
									data.enablePhoto = true;
								} else {
									data.enablePhoto = false;
								}
								if (data.enableStaffTimeOff) {
									data.enableStaffTimeOff = true;
								} else {
									data.enableStaffTimeOff = false;
								}
								if (data.enableStaffPreferences) {
									data.enableStaffPreferences = true;
								} else {
									data.enableStaffPreferences = false;
								}

								// Save the org details.
								var org = await orgUtils.save(data);
								/**
								 * Setting windowReload to false first, if there are no organisation and payment does not done till now then after first organisation added the page will redirected to the payment page, and if already payment added then only organisation save.
								 *
								 */
								let windowReload = false;
								if (person?.organisations?.items?.length > 0) {
									// if user have more then 0
									windowReload = false;
								} else {
									windowReload = true;
								}

								/* 
                                If there's no organisation Id then this is a user creating an org for the first time. Need to add them as a member.
                                This will also make them the owner.
                                If the organisation Id already exists then we just need to retrieve and update anywhere the "name" shows on screen.
                            */
								if (!organisation.id) {
									const member = updatedPerson || person; // updatedPerson will be then newly created profile if needed.
									const { person: savedPerson, organisation: updatedOrg } = await orgUtils.addMember(member, org.id, member.email);
									updatedPerson = savedPerson;
									org = { ...org, ...updatedOrg };
								} else {
									updatedPerson = await personUtils.getProfile(person);
								}
								const mergedOrg = {
									...org,
									...(await orgUtils.mapOrgMembers(org)),
									...{ report: await orgUtils.mapReport(org?.report?.items || [], org.id) },
									...{ departments: org?.departments?.items || [] },
									...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? org?.subscription?.items?.[0]?.quantity }
								};
								setPerson({ ...person, ...updatedPerson, ...{ isAdmin: true }, ...{ currentOrganisation: mergedOrg } });
								setOrganisation(mergedOrg);
								setOrgSaving(false);
								setMessage(
									showSuccess({
										title: "Organisation saved successfully."
									})
								);
								/* 
                                    if number of organizations are 0 then we are reloading the page so it could be go into payment page, client wants once first time organization created then it should be go to the payment page, so new user could not access website till the subscription purchase.
                                */
								if (windowReload) window.location.reload();
							} catch (error) {
								setOrgSaving(false);
								setMessage(
									showFailure({
										title: "Unable to save Organisation.",
										subTitle: error.message
									})
								);
							}
						}}>
						<div className="w-full h-full pt-4">
							<div className="flex flex-wrap w-full">
								<div className="w-full md:w-2/3">
									<div className="w-full">
										<div className="sm:flex gap-6 sm:space-x-1">
											<div className="w-full">
												<InputField label="Organisation Name" name="name" placeholder="Enter organisation name" />
											</div>
											<InputField label="Payroll Email" name="hrEmail" placeholder="Enter HR email" />
										</div>
										<div className="sm:flex mt-6 gap-6 sm:space-x-1">
											<InputField label="Organisation Phone Number" name="phone" placeholder="Enter organisation phone number" />
											<InputField
												label="Staff Cost %"
												name="StaffCost"
												placeholder="Enter your % of takings for staff costs"
												toolTip="What percentage of your total takings would you like your staff cost to be?"
											/>
										</div>
									</div>
								</div>
								<div className="w-full md:w-1/3 flex justify-center md:justify-end mt-6 md:mt-0">
									<LogoPicker organisation={organisation} onLogoUpload={logoUpdated} />
								</div>
								<div className="w-full mt-4">
									<div className="flex gap-2 justify-between">
										<div className="sm:w-2/3">
											<InputField label="Organisation Address" name="address" placeholder="Enter an Organisation address" />
										</div>
										<div className="flex justify-end pt-7">
											<Button type="submit" label="Save" disabled={orgSaving} />
										</div>
									</div>
									<SectionHeader title="Organisation Preferences" />
									<div className="mt-4 flex">
										<label className="text-sm content-end font-medium text-gray-700">Our week starts on: </label>
										<select
											className="mt-1 sm:w-1/4 ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
											value={startDay}
											onChange={(e) => setStartDay(parseInt(e.target.value))}>
											<option value={0}>Sunday</option>
											<option value={1}>Monday</option>
											<option value={2}>Tuesday</option>
											<option value={3}>Wednesday</option>
											<option value={4}>Thursday</option>
											<option value={5}>Friday</option>
											<option value={6}>Saturday</option>
										</select>
									</div>

									<div className="sm:flex my-6 sm:space-x-1 content-end">
										<div className="flex mr-1 sm:w-9/12 w-full">
											<CheckBox
												label="Standard breaks are unpaid"
												name="paidBreak"
												type="checkbox"
												onChange={() => setPaidBreak(!paidBreak)}
												checked={paidBreak}
												toolTip="You can change this for individual shifts later"
											/>
										</div>
										<div className="flex mr-1 mt-6 sm:mt-0 sm:w-9/12 w-full">
											<CheckBox
												label="All breaks are unpaid"
												name="allPaidBreak"
												type="checkbox"
												onChange={() => setAllPaidBreak(!allPaidBreak)}
												checked={allPaidBreak}
												toolTip="All breaks are deducted, even if marked as paid. This will also deduct the shift breaks if none have been clocked in."
											/>
										</div>
									</div>
									<div className="sm:flex mt-4 sm:space-x-1 content-end">
										<div className="flex mb-2 mr-1 sm:w-5/12 w-full">
											<CheckBox
												label="exclude VAT from food & drink totals"
												name="vat"
												type="checkbox"
												onChange={() => setShowVat(!showVat)}
												checked={showVat}
												placeholder="Enter your % of food"
												toolTip="This will show an additional ex-VAT total"
											/>
										</div>
										{showVat && (
											<div className="sm:flex gap-2 mr-1 -mt-3">
												<span className="sm:w-2/5 ml-1 sm:w-full">
													<InputField
														label="Food VAT %"
														isFlex={true}
														width="10"
														name="foodPercentage"
														placeholder="%"
														labelTextColour="whitespace-nowrap text-gray-700 self-center mr-2"
													/>
												</span>
												<span className="sm:w-2/5 w-full ml-3">
													<InputField
														label="Drinks VAT %"
														isFlex={true}
														name="drinkPercentage"
														placeholder="%"
														labelTextColour="whitespace-nowrap text-gray-700 self-center mr-2"
													/>
												</span>
											</div>
										)}
									</div>
									<SectionHeader title="Staff Options" />
									<div className="sm:flex mt-4 mb-2 sm:space-x-1 content-end">
										<div className="flex mr-1 sm:w-9/12 w-full">
											<CheckBox
												label="We would like our staff to be able to multiply their hours by a wage rate."
												name="memberHourlyRate"
												type="checkbox"
												onChange={() => setMemberHourlyRate(!memberHourlyRate)}
												checked={memberHourlyRate}
												toolTip="This allows staff to calculate their wage by multiplying by an input of their choice."
											/>
										</div>
										<div className="flex mr-1 mt-4 sm:mt-0 sm:w-9/12 w-full">
											<CheckBox
												label="We would like our staff to be able to see their personal clock-in/out times."
												name="memberClockInView"
												type="checkbox"
												onChange={() => setMemberClockInView(!memberClockInView)}
												checked={memberClockInView}
												toolTip="This will display the clock-in & out time on the employees home page."
											/>
										</div>
									</div>

									<div className="sm:flex mt-4 sm:space-x-1 content-end">
										<div className="flex mr-1 sm:w-9/12 w-full">
											<CheckBox
												label="Permit staff to request time off."
												name="enableStaffTimeOff"
												type="checkbox"
												onChange={() => setEnableStaffTimeOff(!enableStaffTimeOff)}
												checked={enableStaffTimeOff}
												toolTip="This will allow staff to indicate time off they would like to take on Roster."
											/>
										</div>
										<div className="flex mr-1 mt-4 sm:mt-0 sm:w-9/12 w-full">
											<CheckBox
												label="Allow staff to set preferred days to work."
												name="enableStaffPreferences"
												type="checkbox"
												onChange={() => setEnableStaffPreferences(!enableStaffPreferences)}
												checked={enableStaffPreferences}
												toolTip="This will let staff set days they prefer or not, to work on Roster."
											/>
										</div>
									</div>
									<div className="sm:flex mt-4 mb-2 sm:w-1/2 sm:space-x-1 content-end">
										<div className="flex mr-1">
											<CheckBox
												label="Include option for staff to make their time-off requests to be paid."
												name="allowPaidHol"
												type="checkbox"
												onChange={() => setAllowPaidHol(!allowPaidHol)}
												checked={allowPaidHol}
												toolTip="This allows staff to include a time-off request to be paid, you can still change each request from paid to unpaid before and after they are accepted."
											/>
										</div>
									</div>
									<SectionHeader title="Clock-In System" />
									<div className="sm:flex mt-6 sm:space-x-1 content-end">
										<div className="flex mr-1 sm:w-9/12 w-full">
											<CheckBox
												label="Disable additional clock-in system"
												name="disableClockIn"
												type="checkbox"
												onChange={() => setDisableClockIn(!disableClockIn)}
												checked={disableClockIn}
												toolTip="Turn this off if you don't want to use an in-house clock-in system on tablets"
											/>
										</div>
										<div className="flex mr-1 mt-4 sm:mt-0 sm:w-9/12 w-full">
											<CheckBox
												label="Enable Photo on Clock-in"
												name="enablePhoto"
												type="checkbox"
												onChange={() => setEnablePhoto(!enablePhoto)}
												checked={enablePhoto}
												toolTip="Takes a photo of employee on clock-in & out"
											/>
										</div>
									</div>
									{!disableClockIn && (
										<div className="mt-4 sm:flex sm:space-x-1 content-end">
											<div className="flex mr-1 sm:w-9/12 w-full">
												<InputField
													label="Show late clock out notification after (hours):"
													type="number"
													step="0.1"
													min="0"
													max="24"
													name="lateClockOutNotificationHours"
													placeholder="Enter time in hours"
													toolTip="When a shift is not clocked out past it's end time, a notification will be shown to the manager after this duration."
												/>
											</div>
											<div className="mt-4 flex mr-1 sm:w-9/12 w-full"></div>
										</div>
									)}
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};

export default General;

const SectionHeader = ({ title }) => {
	return (
		<>
			<div className="text-base text-xl text-darkww-700 font-medium mt-8 font-bold">{title}</div>
			<hr className="mt-1 border-b-1 border-darkww-700" />
		</>
	);
};

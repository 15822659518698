import { ArrowNarrowRightIcon, BriefcaseIcon, PencilIcon } from "@heroicons/react/outline";
import { TagIcon, TrendingDownIcon, TrendingUpIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import {
	Avatar,
	Button,
	ConfirmModal,
	LinkButton,
	SearchableList,
	arrayDiff,
	epochToTimeStr,
	formateCurrency,
	nth,
	showSuccess,
	useSimpleMessage
} from "ww-framework";
import { CalendarDataContext, OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import { getOtherLocationShifts } from "./Calendar/calendar_utils";
import ReportForm from "./ReportForm";
import ShiftForm from "./ShiftForm";

export const ShiftCard = ({ shift, orgMembers, editing = false, preferencesColor, memberTimeOff }) => {
	const { role, shiftStart, shiftEnd, memberID, setAsClose, hasBreak, newPay, breakStart, breakEnd, breakDuration } = shift;
	const memberDetails = orgMembers.find((orgMember) => orgMember.orgUserId === memberID);
	return (
		<div
			className={`bg-white group p-2 w-full h-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightww-300 ${preferencesColor} ${
				memberTimeOff && "border-red-500 border-4"
			}`}
		>
			<span className="min-w-0 flex-1 flex items-center space-x-3">
				<span className="block flex-shrink-0">
					<div style={{ borderColor: role?.labelColour }} className="rounded-full border-4 border-darkww-600 h-16 w-16 pt-1 pl-1">
						<Avatar user={memberDetails} size="h-12 w-12" />
					</div>
					<div className="relative">
						<div className="absolute bottom-0 left-12">
							<TagIcon style={{ color: role?.labelColour }} className="h-8 w-8" aria-hidden="true" />
						</div>
					</div>
				</span>
				<span className="block min-w-0 flex-1 pl-2">
					<span className="block text-sm font-medium text-gray-900 truncate">{personUtils.displayName(memberDetails)}</span>
					<span className="block text-sm font-medium text-gray-500 truncate">{role?.name}</span>
				</span>
			</span>
			<span className="flex-1 h-full hidden sm:block">
				<div className="relative">
					<div
						className={`bg-darkww-700 absolute ${
							editing ? "-top-12" : "-top-4"
						} left-0 border-2 border-white shadow-lg rounded-lg px-2 w-fit`}
					>
						<div className="text-white text-lg flex flex-row">
							<div className="py-1 px-1">{epochToTimeStr(shiftStart)}</div>
							<div>
								<ArrowNarrowRightIcon className="text-white h-8 w-8 px-1" aria-hidden="true" />
							</div>
							<div className="py-1 px-1">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
						</div>
					</div>
					<div className={`absolute ${editing ? "top-2" : "top-10"}`}>
						{hasBreak || newPay ? (
							<div className="text-gray-500 text-sm flex flex-row items-center">
								{hasBreak ? (
									<>
										<BriefcaseIcon className="h-6 w-6 px-1" />
										{breakDuration ? `${breakDuration} Mins` : `${epochToTimeStr(breakStart)} - ${epochToTimeStr(breakEnd)}`}
									</>
								) : null}
								{newPay > 0 ? (
									<div className="text-gray-500 text-sm flex flex-row items-center">
										{" "}
										<div className="text-pink-600 -mb-4 text-xs">+{newPay} %</div>
									</div>
								) : null}
							</div>
						) : null}
					</div>
				</div>
			</span>
			<span className="flex-shrink-0 h-10 w-10 items-center justify-center hidden sm:inline-flex">
				{!editing && <PencilIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
			</span>

			<span className="flex-shrink-0 h-10 w-10 pr-10 items-center justify-center inline-flex sm:hidden">
				<div className="text-gray-500 divide-y divide-gray-200">
					<div className="py-1">{epochToTimeStr(shiftStart)}</div>
					<div className="py-1">{epochToTimeStr(shiftEnd)}</div>
				</div>
			</span>
		</div>
	);
};
export const ReportCard = ({ activeDay, report, editing = false }) => {
	return (
		<div
			className={`bg-white group p-2 w-full h-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightww-300"
			}`}
		>
			<span className="min-w-0 flex-1 flex items-center space-x-3">
				<span className="block min-w-0 pl-2">
					<span className="block text-sm font-medium text-gray-900">
						{report?.target && (
							<>
								<span className="text-gray-500">Target: €</span>
								{report.target}
							</>
						)}
						<span className="text-gray-500 ml-2">Takings: </span>
						{report.takings}
						<span className="text-gray-500 ml-2">Avg: </span>
						{/* <span className="flex"> */}
						{activeDay?.average?.takings ? formateCurrency(activeDay?.average?.takings || 0) : 0}
						{activeDay?.average?.takings > activeDay?.report[0]?.takings ? (
							<TrendingDownIcon className="w-5 h-5 text-red-500 inline" />
						) : (
							activeDay?.average?.takings < activeDay?.report[0]?.takings && (
								<TrendingUpIcon className="w-5 h-5 text-green-500 inline" />
							)
						)}
						{/* </span> */}
					</span>
					<span className="block text-sm font-medium text-gray-900">{report.note}</span>
				</span>
			</span>
			<span className="flex-shrink-0 h-10 w-10 items-center justify-center hidden sm:inline-flex">
				{!editing && <PencilIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
			</span>
		</div>
	);
};

const ShiftsList = ({ shifts, setShiftRecord, orgMembers, preferences, timeOff }) => {
	const { setEditShift } = useContext(CalendarDataContext);
	const sortedShifts = shifts
		.map((shift) => ({
			...shift,
			time: new Date(shift.shiftStart * 1000),
			length: shift.shiftEnd - shift.shiftStart
		}))
		.sort((a, b) => {
			const timeDifference = a.time.getHours() * 60 + a.time.getMinutes() - (b.time.getHours() * 60 + b.time.getMinutes());
			if (timeDifference !== 0) {
				return timeDifference;
			}
			// If start times are the same, sort by length of shift
			return a.length - b.length;
		});

	return (
		<div>
			<h3 className="text-xs font-semibold mt-2 text-white uppercase tracking-wide">{`Total Shifts (${sortedShifts.length})`}</h3>
			<ul role="presentation" className="mt-2 grid grid-cols-1 gap-4">
				{sortedShifts.map((shift, index) => {
					const { memberID } = shift;
					const memberPreference = preferences && preferences.find((value) => value.memberID === memberID);
					const memberTimeOff = timeOff && timeOff.find((k) => k.memberID === memberID);
					return (
						<li key={index}>
							<button
								type="button"
								className={`w-full h-full`}
								onClick={() => {
									if (setShiftRecord) {
										setEditShift(shift);
										setShiftRecord(shift);
									}
								}}
							>
								<ShiftCard
									shift={shift}
									orgMembers={orgMembers}
									preferencesColor={
										memberPreference?.status === "PREFER_NOT"
											? "bg-pink-200"
											: memberPreference?.status === "PREFER"
											? "bg-green-200"
											: ""
									}
									memberTimeOff={memberTimeOff || null}
								/>
							</button>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const ReportsList = ({ activeDay, report, setEditReport }) => {
	const reportToDisplay = report && report.length > 0 ? [report[0]] : [];
	return (
		<div className="mt-1 sm:mt-5 sm:mb-2">
			<ul role="presentation" className="mt-4 grid grid-cols-1 gap-4">
				{reportToDisplay?.map((r, index) => (
					<li key={index}>
						<button
							type="button"
							className={`w-full h-full`}
							onClick={() => {
								if (setEditReport) setEditReport(r);
							}}
						>
							<ReportCard report={r} activeDay={activeDay} />
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

const LoadShiftsFromPreviousDay = ({ activeDay, orgId, orgMembers, rebuildCalendar, isAdmin, shift, loggedInPerson, selectedDepartmentIds }) => {
	const { setMessage } = useSimpleMessage();
	const [daysList, setDaysList] = useState(
		shift
			?.filter((s) => s.baseEpoch < activeDay.baseEpoch)
			?.map((k) => {
				return {
					id: k.baseEpoch * 1000,
					description: new Intl.DateTimeFormat("en", { dateStyle: "full" }).format(k.baseEpoch * 1000),
					shifts: k.shifts
				};
			})
			.reverse()
	);

	const [shiftsList, setShiftsList] = useState([]);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		async function loadShifts() {
			try {
				if (activeDay) {
					const endDate = activeDay?.baseEpoch;
					const startDate = activeDay?.baseEpoch - 86400 * 21;

					let shifts = await orgUtils.getShifts(orgId, startDate, endDate, isAdmin);

					// Filter shifts by selected department(s)
					if (selectedDepartmentIds && selectedDepartmentIds !== "ALL" && selectedDepartmentIds.length > 0) {
						shifts = shifts.filter((shift) => {
							const shiftMember = orgMembers.find((member) => member.orgUserId === shift.memberID);
							return shiftMember && shiftMember.departmentIDs.some((id) => selectedDepartmentIds.includes(id));
						});
					}
					const member = orgMembers?.find((k) => k?.person === loggedInPerson?.person);
					if (loggedInPerson?.assignedAdmin) {
						shifts = shifts.filter((s) =>
							s.member?.departmentID !== null && member?.departmentIDs.length > 0
								? arrayDiff(s.member?.departmentID || [], member?.departmentIDs)
								: s?.memberID === member?.orgUserId
						);
					}

					const daysWithShifts = [...new Set(shifts.map((shift) => shift.baseDay))].reverse();

					const data = daysWithShifts.map((baseDay) => {
						const baseDate = new Date(baseDay * 1000);
						const shiftsForDay = shifts.filter((shift) => shift.baseDay === baseDay);

						return {
							id: baseDay,
							description: new Intl.DateTimeFormat("en", { dateStyle: "full" }).format(baseDate),
							shifts: shiftsForDay
						};
					});

					setDaysList(data);
				}
			} catch (err) {}
		}

		loadShifts();
	}, [activeDay, orgId, isAdmin, loggedInPerson?.assignedAdmin, loggedInPerson?.person, orgMembers, selectedDepartmentIds]);

	const onSelection = (selection) => {
		setShiftsList(selection?.shifts || []);
	};

	const saveShifts = async () => {
		const shiftsToSave = shiftsList.map(async (shift, i) => {
			let otherLocationShifts = [];
			if (i === 0) {
				otherLocationShifts = await getOtherLocationShifts({
					orgMembers,
					loggedInPerson,
					startDate: activeDay.baseEpoch,
					endDate: activeDay.baseEpoch + 23 * 60 * 60 + 59 * 60
				});
			}
			return await saveShift(shift, activeDay, orgId, otherLocationShifts);
		});

		return Promise.allSettled(shiftsToSave);
	};

	const confirmSave = async (e) => {
		setSaving(true);
		try {
			const copied = await saveShifts();
			const alreadyRosteredCount = copied.filter((s) => s.status === "fulfilled" && s.value === 2).length;
			const emptyShiftsCount = copied.filter((s) => s.status === "fulfilled" && s.value === 1).length;
			if (alreadyRosteredCount > 0 && emptyShiftsCount > 0) {
				setMessage(
					showSuccess({
						title: `${alreadyRosteredCount ?? "Few"} shifts not copied as the employee is already rostered in another location`,
						subTitle: `${emptyShiftsCount + alreadyRosteredCount ?? "A few"} shifts not yet assigned to an employee`,
						duration: 8
					})
				);
			} else if (emptyShiftsCount > 0) {
				setMessage(
					showSuccess({
						title: `${emptyShiftsCount ?? "Few"} shifts not yet assigned to an employee`
					})
				);
			} else if (alreadyRosteredCount > 0) {
				setMessage(
					showSuccess({
						title: `${alreadyRosteredCount ?? "Few"} shifts not copied as the employee is already rostered in another location`
					})
				);
			}
			setTimeout(() => setSaving(false), 1500);
			rebuildCalendar(activeDay.date);
		} catch (err) {
			console.error("Error in confirmSave:", err);
			setSaving(false);
		}
	};

	return (
		<div style={{ minHeight: 260 }}>
			<div className="bg-white sm:mt-6 sm:shadow sm:rounded-lg">
				<div className="px-4 py-3 sm:p-6">
					<h3 className="text-lg leading-6 font-medium text-gray-900">Copy shifts from a previous day</h3>
					<div className="mt-5">
						<SearchableList list={daysList} onSelect={onSelection} />
						{shiftsList.length === 0 && (
							<p className="mt-2 text-sm text-gray-500" id="email-description">
								Use the dropdown below to load shifts from a previous day (only last 21 days available). Press
								<span className="font-bold ml-1">confirm</span> to save those shifts, then you can edit them as normal.
							</p>
						)}
					</div>

					<div className="flex justify-end">
						{shiftsList.length > 0 && (
							<div className="mt-3 w-40 flex flex-row justify-end">
								<LinkButton label="Cancel" className="text-gray-700 hover:text-gray-800 pr-3" onClick={() => onSelection()} />
								<Button label="Confirm" disabled={saving} onClick={(e) => confirmSave(e)} />
							</div>
						)}
					</div>
				</div>
			</div>
			{shiftsList.length > 0 && <ShiftsList shifts={shiftsList} orgMembers={orgMembers} />}
		</div>
	);
};

export const saveShift = async (shift, activeDay, orgId, otherLocationShifts) => {
	let shiftDetails = {
		id: "",
		breakend: shift?.breakEnd ? shift?.breakEnd - shift?.baseDay : "",
		breakstart: shift?.breakStart ? shift?.breakStart - shift?.baseDay : "",
		end: shift?.shiftEnd - shift?.baseDay || "",
		setAsClose: shift?.setAsClose,
		hasBreak: shift?.hasBreak,
		person: shift?.member?.id || null,
		role: shift.role?.id,
		start: shift?.shiftStart - shift?.baseDay || "",
		organisationID: orgId,
		day: activeDay,
		breakDuration: shift?.breakDuration || 0,
		unPaidBreak: shift?.unPaidBreak || false
	};

	if (otherLocationShifts.length > 0) {
		const found = await otherLocationShifts.find((k) => k.baseDay === activeDay.baseEpoch && k?.member?.email === shift?.member?.email);
		if (found) {
			shiftDetails = {
				...shiftDetails,
				person: null,
				alreadyRostered: true
			};
		}
	}

	try {
		await orgUtils.saveShift(shiftDetails);
		if (shiftDetails.person === null) {
			if (shiftDetails.alreadyRostered) {
				return 2;
			}
			return 1;
		}
	} catch (err) {
		console.error("Error in saveShift:", err);
	}
};

const DaySummary = ({ rebuildCalendar, shiftsThisWeek, preferences, isAdmin, timeOff, report, setOrg, shift, punchData, selectedDepartmentIds }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);
	const { activeDay, editShift } = useContext(CalendarDataContext);
	const [shiftFormVisible, setShiftFormVisible] = useState(false);
	const [reportFormVisible, setReportFormVisible] = useState(false);
	const [open, setOpen] = useState(false);
	const [shiftRecord, setShiftRecord] = useState(editShift);
	const [editReport, setEditReport] = useState({});
	const [average] = useState(activeDay?.average || 0);
	const arrayUniqueByKey = [...new Map(shiftsThisWeek?.map((item) => [item["id"], item])).values()];
	const [shiftPunch, setShiftPunch] = useState({});
	const memberList = arrayUniqueByKey.reduce((group, product) => {
		const { memberID } = product;
		group[memberID] = group[memberID] ?? [];
		group[memberID].push(product);
		return group;
	}, {});
	const addShift = () => {
		setShiftFormVisible(true);
	};
	const addReport = () => {
		setReportFormVisible(true);
	};
	useEffect(() => {
		async function setShift() {
			setShiftRecord(editShift);
			setShiftPunch(punchData.find((l) => l?.shiftID === editShift?.id));
		}
		setShift();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editShift]);
	const confirmPopup = () => {
		setOpen(true);
	};
	const handlerModal = async (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			activeDay?.shifts.length > 0 &&
				activeDay.shifts.map(async (shift) => {
					await deleteShift(shift.id);
					const punch = punchData.find((l) => l?.shiftID === shift.id);
					if (punch) {
						await orgUtils.deletePunch(punch.id);
					}
					rebuildCalendar(activeDay.date);
				});
		}
		setOpen(false);
	};
	const deleteShift = async (shiftId) => {
		try {
			await orgUtils.deleteShift(shiftId);
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title="Clear All shift for the day?"
				subTitle="Are you sure?"
			/>
			<div className="overflow-y-scroll h-full pl-3 bg-darkww-900 shadow-lg rounded-lg mt-1 ml-4 p-3">
				{activeDay?.day && (
					<div className="flex mt-4">
						<div className="font-bold text-gray-700 text-white text-xl py-3">{`${activeDay?.dayDesc},  ${activeDay?.monthShortDesc} ${
							activeDay?.day
						}${nth(activeDay?.day)}`}</div>
						{!(shiftFormVisible || shiftRecord?.id || editReport?.id || reportFormVisible) && (
							<div className="mt-2 mr-4 max-w-sm mx-auto">
								<Button type="button" onClick={() => confirmPopup()} label="Clear" />
							</div>
						)}
					</div>
				)}
				{shiftRecord?.id && (
					<div className="max-h-40 my-3">
						<ShiftCard shift={shiftRecord} orgMembers={organisation.members || []} editing={true} />
					</div>
				)}
				{shiftFormVisible || shiftRecord?.id ? (
					<ShiftForm
						activeDay={activeDay}
						record={shiftRecord}
						cancelCB={() => {
							setShiftRecord({});
							setShiftFormVisible(false);
						}}
						rebuildCalendar={rebuildCalendar}
						memberList={memberList}
						preferences={preferences}
						timeOff={timeOff}
						shiftPunch={shiftPunch}
					/>
				) : reportFormVisible || editReport?.id ? (
					<ReportForm
						activeDay={activeDay}
						average={average || 0}
						record={editReport}
						cancelCB={() => {
							setEditReport({});
							setReportFormVisible(false);
						}}
						rebuildCalendar={rebuildCalendar}
						setOrg={() => setOrg()}
					/>
				) : (
					<div className="flex">
						<div className="mt-5 w-full mx-2 max-w-sm mx-auto">
							<Button type="button" onClick={addShift} label="Add Shift" />
						</div>
						{report?.length < 1 && (
							<div className="mt-5  w-1/2 mx-2 max-w-sm mx-auto">
								<Button type="button" onClick={addReport} label="Add Report" />
							</div>
						)}
					</div>
				)}

				{activeDay?.shifts?.length > 0 && !(shiftRecord?.id || editReport?.id) && !(shiftFormVisible || reportFormVisible) ? (
					<>
						<ReportsList report={report} setEditReport={setEditReport} activeDay={activeDay} />
						<ShiftsList
							shifts={activeDay.shifts}
							setShiftRecord={setShiftRecord}
							orgMembers={organisation.members || []}
							preferences={preferences}
							timeOff={timeOff}
						/>
					</>
				) : (
					!(shiftRecord?.id || editReport?.id) &&
					!(shiftFormVisible || reportFormVisible) && (
						<div className="w-full pt-5">
							<ReportsList report={report} setEditReport={setEditReport} activeDay={activeDay} />
							<LoadShiftsFromPreviousDay
								activeDay={activeDay}
								orgId={organisation.id}
								orgMembers={organisation.members || []}
								rebuildCalendar={rebuildCalendar}
								isAdmin={isAdmin}
								shift={shift}
								loggedInPerson={loggedInPerson}
								selectedDepartmentIds={selectedDepartmentIds}
							/>
						</div>
					)
				)}
			</div>
		</>
	);
};

export default DaySummary;

import { useState, Fragment } from "react";
import {
	ComboBox,
	Form,
	Button,
	Yup,
	useSimpleMessage,
	showFailure,
	showSuccess,
	epochToTimeStr,
	formateEpoch,
	notifyUser,
	InputField
} from "ww-framework";
import { orgUtils, personUtils } from "ww-stores";
import { Dialog, Transition } from "@headlessui/react";

const GiveAwayForm = ({ shift, suitableMembersOptions, handlerModal, suitableShiftOptions, person, buildCalendar }) => {
	const { setMessage } = useSimpleMessage();
	const initValues = {
		name: "",
		person: null,
		toShift: null,
		member: "ALL",
		note: null
	};
	const [templateSaving, setTemplateSaving] = useState(false);
	const [member, setMember] = useState("");
	// const [isNotForAll, setIsNotForAll] = useState(false);
	const [personID, setPersonID] = useState([]);
	const updateShiftList = async (c) => {
		setMember(personUtils.displayName(person));
		if (c.id !== "ALL") {
			// setIsNotForAll(false);
			const currentMember = person?.currentOrganisation?.members?.find((m) => c?.id === m?.orgUserId);
			// setMember(personUtils.displayName(currentMember) || currentMember?.username);
			setPersonID([currentMember?.person]);
		} else {
			// setIsNotForAll(true);
			// setMember("All Org Members");
			setPersonID(person.currentOrganisation.members.filter((l) => l.id !== person.id).map((k) => k.person));
		}
	};
	const templateSchema = Yup.object().shape({
		member: Yup.string().required()
	});
	return (
		<>
			<h4 className="text-xs pl-2 font-semibold text-dark tracking-wide">Select Staff to give this shift to:</h4>
			<Form
				validationSchema={templateSchema}
				enableReinitialize={true}
				disabled={templateSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						setTemplateSaving(true);
						if (data.member !== "ALL") {
							const shiftData = {
								...{
									organisationID: person.currentOrganisation.id,
									fromMemberID: shift.memberID,
									shiftId: shift.id,
									memberID: data.member,
									status: "PENDING",
									expirationTime: shift?.shiftStart + 172800,
									note: data?.note || null
								}
							};
							await orgUtils.saveGiveAwayRequest(shiftData);
							setTemplateSaving(false);
						} else {
							const shiftData = {
								...{
									organisationID: person.currentOrganisation.id,
									fromMemberID: shift.memberID,
									shiftId: shift.id,
									roleID: shift?.roleID,
									expirationTime: shift?.shiftStart + 172800,
									note: data?.note || null
								}
							};
							await orgUtils.saveAllGiveAway(shiftData);
							setTemplateSaving(false);
						}
						resetForm();
						setMessage(
							showSuccess({
								title: "Give Away Requested."
							})
						);
						handlerModal("", false);
						await buildCalendar();
						await notifyUser(personID, `${member || "Someone"} would like you to take a shift`);
					} catch (error) {
						console.log(error);
						setMessage(
							showFailure({
								title: "Unable to give this shift away.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-72 sm:w-full">
					<div className="mt-2 px-2 with-full ">
						<ComboBox
							label="Staff Member"
							name="member"
							labelTextColour="text-dark"
							options={suitableMembersOptions}
							initValue={initValues.person}
							getSelectedValueOnChange={(value) => updateShiftList(value)}
						/>
					</div>
					<div className="mt-2 px-2 with-full ">
						<InputField label="Note / Comment" name="note" labelTextColour="text-dark" />
					</div>
					<div className="mt-5 pl-3 flex flex-row justify-between">
						<div className="flex justify-end gap-5">
							<Button type="submit" label="Save" disabled={templateSaving} />
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};
const GiveAway = ({ open, handlerModal, activeWeek, organisation, shift, person, currentWeek, currentDay, buildCalendar }) => {
	const currentDayShifts = currentWeek.allShifts.filter((k) => k.baseDay === currentDay.baseEpoch);

	const suitableMembersByRole = person?.currentOrganisation?.members?.filter(
		(member) =>
			member?.roleIDs &&
			member?.roleIDs.includes(shift?.roleID) &&
			member?.id !== person.id &&
			!currentDayShifts.find((m) => m.memberID === member.orgUserId)
	);
	const otherShifts = currentDay.allShifts.filter((k) => {
		return k.baseDay !== currentDay.baseEpoch && k.memberID !== person.id && !currentWeek?.shifts.map((m) => m.baseDay).includes(k.baseDay);
	});

	const suitableMembersOptions = suitableMembersByRole?.map((m) => {
		return {
			...{
				id: m.orgUserId,
				description: personUtils.displayName(m),
				name: personUtils.displayName(m)
			}
		};
	});
	suitableMembersOptions?.unshift({ id: "ALL", description: "Send to All", name: "All Members" });
	const suitableShiftOptions = otherShifts.map((m) => {
		return {
			...{
				id: m.id,
				description: `${formateEpoch(m.baseDay)} | ${epochToTimeStr(m.shiftStart)} - ${epochToTimeStr(m.shiftEnd)} (${personUtils.displayName(
					m.member
				)})`,
				name: `${formateEpoch(m.baseDay)} | ${epochToTimeStr(m.shiftStart)} - ${epochToTimeStr(m.shiftEnd)} (${personUtils.displayName(
					m.member
				)})`,
				member: m.member
			}
		};
	});
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-72">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3">
								<div className="justify-center gap-2">
									<GiveAwayForm
										activeWeek={activeWeek}
										organisation={organisation}
										shift={shift}
										person={person}
										currentWeek={currentWeek}
										suitableMembersOptions={suitableMembersOptions}
										suitableShiftOptions={suitableShiftOptions}
										handlerModal={handlerModal}
										buildCalendar={buildCalendar}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default GiveAway;

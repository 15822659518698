import { ChatIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React, { useCallback, useContext, useState } from "react";
import { formateEpochToShortMonthDate2, notifyUser, showFailure, useSimpleMessage } from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

const requestTypes = {
	all: "All",
	timeOff: "Time Off",
	swapShift: "Swap Shift",
	giveAway: "Give Away"
};

const ApproveRequests = ({
	approveGiveAwayRequest,
	approveSwapShiftRequest,
	approveTimeOff,
	approveSwapShift,
	changeTimeOffType,
	manageShifts,
	loadRoles,
	setIsLoading
}) => {
	const personObject = useContext(PersonContext);
	const person = personObject.person;
	const { organisation } = useContext(OrganisationContext);
	const { setMessage } = useSimpleMessage();
	const [filterRequestLists, setFilterRequestLists] = useState(false);
	const [typeFilter, setTypeFilter] = useState("");
	const [nameFilter, setNameFilter] = useState("");
	const [filteredTimeOff, setFilteredTimeOff] = useState([]);
	const [filteredSwapShift, setFilteredSwapShift] = useState([]);
	const [filteredGiveAway, setFilteredGiveAway] = useState([]);
	const [loadMoreSearchResults, setLoadMoreSearchResults] = useState(false);

	const approve = useCallback(
		async (e, id, type = null, person = null, status = "APPROVE") => {
			try {
				setIsLoading(true);
				await orgUtils.changeStatusTimeOff(id, status);
				person && (await notifyUser([person], `Your ${type} request has been approved by your manager`));
				await loadRoles();
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: "Unable to change preference.",
						subTitle: error.message
					})
				);
			}
		},
		[setIsLoading, loadRoles, setMessage]
	);
	const deny = useCallback(
		async (e, id, type = null, m = null, status = "NOT_APPROVE") => {
			try {
				setIsLoading(true);
				if (type === "Swap Shift") {
					const shiftData = {
						...{
							id: id?.id,
							organisationID: id?.organisationID,
							fromMemberID: id?.fromMemberID,
							fromShiftId: id?.fromShiftId,
							toShiftId: id?.toShiftId,
							memberID: id?.memberID,
							status: person.isAdmin || person.assignedRequests ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
							note: id?.note || null
						}
					};
					await orgUtils.saveShiftSwapRequest(shiftData);
				} else if (type === "Give Away") {
					const shiftData = {
						...{
							id: id.id,
							organisationID: id.organisationID,
							fromMemberID: id.fromMemberID,
							fromShiftId: id.fromShiftId,
							toShiftId: id.toShiftId,
							memberID: id.memberID,
							status: person.isAdmin ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
							note: id?.note || null
						}
					};
					await orgUtils.saveGiveAwayRequest(shiftData);
				} else {
					await orgUtils.changeStatusTimeOff(id, status);
				}
				m && (await notifyUser([m], `Your ${type} request has been denied`));
				await loadRoles();
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: "Unable to change preference.",
						subTitle: error.message
					})
				);
			}
		},
		[setIsLoading, loadRoles, person.isAdmin, person.assignedRequests, setMessage]
	);
	const renderTimeOffRequests = () => {
		return (filterRequestLists ? filteredTimeOff : approveTimeOff).map((off, index) => (
			<li key={index} className="flex flex-row">
				<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
					<div className="flex flex-1 pl-1 items-center">
						<span className="text-gray-600 mr-1 inline-flex gap-1">
							{off.isPaid && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6 text-green-700 "
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
									/>
								</svg>
							)}
							Time Off:{" "}
						</span>
						<br className="sm:hidden" />
						<span className="font-medium">
							{off?.member?.id
								? personUtils.displayName(off?.member?.person) || off?.member?.orgUsername
								: off?.member?.orgUsername || "User Removed"}
						</span>
						<span className="text-gray-600 text-xs ml-1 mb-1.5" onClick={() => manageShifts(off.fromDate)}>
							{off?.repeatDay && `Every ${off?.repeatDay} from `} {formateEpochToShortMonthDate2(off.fromDate, true)} -{" "}
							{formateEpochToShortMonthDate2(off.toDate, true)}
							<span className="text-blue-900 inline-flex ml-1">
								{off?.note && (
									<Tooltip trigger={["click", "hover"]} overlay={off.note}>
										<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
									</Tooltip>
								)}
							</span>
						</span>
					</div>
					{(person.isAdmin || person.assignedRequests) && !off?.repeatDay && (
						<div className="flex flex-row mr-1 content-end">
							<button
								className="self-end inline-flex content-end mt-4 align-end items-center bg-darkww-400 pl-1 pr-1 border ring-white text-xs h-6 rounded-md shadow-sm text-white text-right flex justify-end"
								onClick={(e) => changeTimeOffType(off)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-4 h-4"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
									/>
								</svg>

								<span className="sm:block hidden"> {off.isPaid ? "Unpaid" : "Paid"}</span>
							</button>
						</div>
					)}
					{(person.isAdmin || person.assignedRequests) && off.status === "PENDING" && (
						<div className="flex flex-row">
							<button
								className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
								onClick={(e) => approve(e, off.id, "Time Off", off?.member?.person?.person, "APPROVE")}
							>
								<CheckCircleIcon className="w-6 h-6" />
								<span className="sm:block hidden pl-1">Approve</span>
							</button>
							<button
								className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
								onClick={(e) => deny(e, off.id, "Time Off", off?.member?.person?.person, "NOT_APPROVE")}
							>
								<XCircleIcon className="w-6 h-6" />
								<span className="sm:block hidden pl-1">Deny</span>
							</button>
						</div>
					)}
					{!person.isAdmin && !person.assignedRequests && (
						<div className="flex flex-row">
							<span className="capitalize">{off.status.replace(/_/g, " ").toLowerCase() + "d"}</span>
						</div>
					)}
					{(person.isAdmin || person.assignedRequests) && off.status === "NOT_APPROVE" && (
						<div className="flex flex-row">
							<button
								className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
								onClick={(e) => approve(e, off.id, "Time Off", off?.member?.person?.person, "APPROVE")}
							>
								<CheckCircleIcon className="w-6 h-6" />
								<span className="sm:block hidden pl-1">Approve</span>
							</button>
						</div>
					)}
					{(person.isAdmin || person.assignedRequests) && off.status === "APPROVE" && (
						<div className="flex flex-row">
							<button
								className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
								onClick={(e) => deny(e, off.id, "Time Off", off?.member?.person?.person, "NOT_APPROVE")}
							>
								<XCircleIcon className="w-6 h-6" />
								<span className="sm:block hidden pl-1">Deny</span>
							</button>
						</div>
					)}
				</div>
			</li>
		));
	};
	const renderSwapShiftRequest = () => {
		return (filterRequestLists ? filteredSwapShift : approveSwapShiftRequest)
			.map((swapShift, index) => (
				<li key={`${index}-requests`} className="flex flex-row">
					<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
						<div className="flex-1 pl-1">
							<span className="text-gray-600">Swap Shift: </span>
							<br className="sm:hidden" />
							<span className="font-medium">
								{swapShift?.fromMember?.id
									? personUtils.displayName(swapShift?.fromMember?.person) || swapShift?.fromMember?.orgUsername
									: swapShift?.fromMember?.orgUsername || "User Removed"}
								{" ("}
								{swapShift?.fromShift?.role?.name}
								{")"}
							</span>
							<span className="text-gray-600 text-xs">
								<span className="text-green-600 ml-1">
									{swapShift?.fromShift?.shiftStart && formateEpochToShortMonthDate2(swapShift?.fromShift?.shiftStart, true)}{" "}
								</span>
								<span className="text-darkww-800 mr-0.5">asked for</span>
							</span>
							<span className="font-medium mr-0.5">
								{" "}
								{swapShift?.toMember?.id
									? personUtils.displayName(swapShift?.toMember?.person) || swapShift?.toMember?.orgUsername
									: swapShift?.toMember?.orgUsername || "User Removed"}{" "}
								{" ("}
								{swapShift?.toShift?.role?.name}
								{")"}
							</span>

							<span className="text-gray-600 text-xs">
								{" "}
								{swapShift?.toShift?.shiftStart && formateEpochToShortMonthDate2(swapShift?.toShift?.shiftStart, true)}
								<span className="text-blue-900 inline-flex ml-1">
									{swapShift?.note && (
										<Tooltip trigger={["click", "hover"]} overlay={swapShift?.note}>
											<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
										</Tooltip>
									)}
								</span>
							</span>
						</div>
						{(person.isAdmin || person.assignedRequests) && swapShift?.status === "APPROVE_BY_MEMBER" ? (
							<div className="flex flex-row">
								<button
									className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
									onClick={(e) => {
										approveSwapShift(e, swapShift, "Swap Shift", organisation.owner, "APPROVE_BY_MANAGER");
									}}
								>
									<CheckCircleIcon className="w-6 h-6" />
									<span className="sm:block hidden pl-1">Confirm</span>
								</button>
								<button
									className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
									onClick={(e) => {
										deny(e, swapShift, "Swap Shift", organisation.owner);
									}}
								>
									<XCircleIcon className="w-6 h-6" />
									<span className="sm:block hidden pl-1">Deny</span>
								</button>
							</div>
						) : (
							<div className="flex flex-row">
								{swapShift.status === "APPROVE_BY_MANAGER" ? (
									<button
										className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
										onClick={(e) => {
											deny(e, swapShift, "Swap Shift", organisation.owner);
										}}
									>
										<CheckCircleIcon className="w-6 h-6" />
										<span className="sm:block hidden pl-1">Clear</span>
									</button>
								) : (
									<>
										<button
											className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
											onClick={(e) => {
												approveSwapShift(e, swapShift, "Swap Shift", organisation.owner, "APPROVE_BY_MEMBER");
											}}
										>
											<CheckCircleIcon className="w-6 h-6" />
											<span className="sm:block hidden pl-1">Accept</span>
										</button>
										<button
											className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
											onClick={(e) => {
												deny(e, swapShift, "Swap Shift", organisation.owner);
											}}
										>
											<XCircleIcon className="w-6 h-6" />
											<span className="sm:block hidden pl-1">Refuse</span>
										</button>
									</>
								)}
							</div>
						)}
					</div>
				</li>
			))
			.reverse();
	};
	const renderGiveAwayRequest = () => {
		return (filterRequestLists ? filteredGiveAway : approveGiveAwayRequest).map((giveAway, index) => (
			<li key={`${index}-requests`} className="flex flex-row">
				<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
					<div className="flex-1 pl-1">
						<span className="text-gray-600">Give Away: </span>
						<br className="sm:hidden" />
						<span className="font-medium">
							{giveAway?.member?.id
								? personUtils.displayName(giveAway?.member.person) || giveAway?.member?.orgUsername
								: giveAway?.member?.orgUsername || "User Removed"}
						</span>
						<span className="text-gray-600 text-xs">
							{" "}
							<span className="text-green-600 ml-1">
								{giveAway?.fromShift?.shiftStart && formateEpochToShortMonthDate2(giveAway?.fromShift?.shiftStart, true)}{" "}
							</span>
							<span className="text-darkww-800 mr-0.5">is covering</span>
						</span>
						<span className="font-medium mr-0.5">
							{" "}
							{giveAway?.fromMember?.id
								? personUtils.displayName(giveAway?.fromMember?.person) || giveAway?.fromMember?.orgUsername
								: giveAway?.fromMember?.orgUsername || "User Removed"}{" "}
							{" ("}
							{giveAway?.shift?.role?.name}
							{")"}
							<span className="text-gray-600 text-xs">
								{" "}
								{giveAway?.shift?.shiftStart && formateEpochToShortMonthDate2(giveAway?.shift?.shiftStart, true)}
								<span className="text-blue-900 inline-flex ml-1">
									{giveAway?.note && (
										<Tooltip trigger={["click", "hover"]} overlay={giveAway.note}>
											<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
										</Tooltip>
									)}
								</span>
							</span>
						</span>
					</div>
				</div>
			</li>
		));
	};

	function filterByName(name, filterKey) {
		return name.toLowerCase().includes(filterKey.toLowerCase());
	}

	const getMemberIdForSearchTerm = (searchTerm) => {
		// const membersWithoutCurrentUser = organisation.members.filter((member) => member.email !== person.email);
		const member = organisation.members.find((member) =>
			member ? personUtils.displayName(member)?.toLowerCase().includes(searchTerm.toLowerCase()) : null
		);
		return member?.orgUserId;
	};

	const getStaffForSearchTerm = (searchTerm) => {
		return organisation.members.filter((member) =>
			member ? personUtils.displayName(member)?.toLowerCase().includes(searchTerm.toLowerCase()) : null
		);
	};

	const fetchTimeOffRequestsForMember = async (memberId) => {
		try {
			setIsLoading(true);
			const data = await orgUtils.getTimeOffForMember(organisation.id, memberId, "ACTIONED");
			if (data) {
				return data.items;
			}
			setIsLoading(false);
			return [];
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to fetch time off requests.",
					subTitle: error.message
				})
			);
			setIsLoading(false);
			return [];
		}
	};

	const fetchSwapRequestsForMember = async (memberId) => {
		try {
			setIsLoading(true);
			const memberToRequests = await orgUtils.getMemberShiftSwapRequests(organisation.id, memberId);
			const memberFromRequests = await orgUtils.getFromMemberShiftSwapRequests(memberId);
			const memberRequests = [...memberToRequests.items, ...memberFromRequests.items];
			setIsLoading(false);
			return memberRequests;
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to fetch swap shift requests.",
					subTitle: error.message
				})
			);
			setIsLoading(false);
			return [];
		}
	};

	const fetchGiveAwayRequestsForMember = async (memberId) => {
		try {
			setIsLoading(true);
			const data = await orgUtils.getGiveAwayRequestsForOrgMember(organisation.id, memberId);
			if (data) {
				return data.items;
			}
			setIsLoading(false);
			return [];
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to fetch give away requests.",
					subTitle: error.message
				})
			);
			setIsLoading(false);
			return [];
		}
	};

	const fetchGiveAwaysForMembers = async (memberIds) => {
		try {
			setIsLoading(true);
			const data = await orgUtils.getGiveAwayRequestsForOrgMembers(organisation.id, memberIds);
			if (data) {
				return data.items;
			}
			setIsLoading(false);
			return [];
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to fetch give away requests.",
					subTitle: error.message
				})
			);
			setIsLoading(false);
			return [];
		}
	};

	// const fetchRequestsForMembers = async (memberIds, reqType) => {
	// 	if (reqType === "timeOff") {
	// 		const timeOff = [];
	// 		for (let i = 0; i < memberIds.length; i++) {
	// 			const result = await fetchTimeOffRequestsForMember(memberIds[i]);
	// 			timeOff.push(...result);
	// 		}
	// 		return timeOff;
	// 	} else if (reqType === "swapShift") {
	// 		const swapShift = [];
	// 		for (let i = 0; i < memberIds.length; i++) {
	// 			const result = await fetchSwapRequestsForMember(memberIds[i]);
	// 			swapShift.push(...result);
	// 		}
	// 		return swapShift;
	// 	} else if (reqType === "giveAway") {
	// 		const giveAway = [];
	// 		for (let i = 0; i < memberIds.length; i++) {
	// 			const result = await fetchGiveAwayRequestsForMember(memberIds[i]);
	// 			giveAway.push(...result);
	// 		}
	// 		return giveAway;
	// 	} else {
	// 		return [];
	// 	}
	// };

	const handleFilter = async (requestType, nameFilterKey, fetch = false) => {
		if (nameFilterKey === "" && typeFilter === "") {
			setTypeFilter(requestType);
			setNameFilter(nameFilterKey);
			setFilterRequestLists(false);
			return;
		}
		setTypeFilter(requestType);
		setNameFilter(nameFilterKey);
		setFilterRequestLists(true);
		let timeOff = [];
		let swapShift = [];
		let giveAway = [];
		const memberId = getMemberIdForSearchTerm(nameFilterKey);
		const membersSearched = getStaffForSearchTerm(nameFilterKey);
		const memberIds = membersSearched.map((member) => member.orgUserId);

		if (requestType === "" || requestType === "timeOff") {
			if (fetch) {
				setIsLoading(true);
				if (memberId) {
					const result = await fetchTimeOffRequestsForMember(memberId);
					result.sort((a, b) => b.fromDate - a.fromDate);
					timeOff = result;
				}
				setIsLoading(false);
				setLoadMoreSearchResults(false);
			} else {
				timeOff = approveTimeOff.filter((off) => {
					if (nameFilterKey === "") {
						return true;
					} else {
						const memberName = off?.member?.id
							? personUtils.displayName(off?.member?.person) || off?.member?.orgUsername
							: off?.member?.orgUsername;
						return filterByName(memberName, nameFilterKey);
					}
				});
			}
		}
		if (requestType === "" || requestType === "swapShift") {
			if (fetch) {
				setIsLoading(true);
				if (memberId) {
					const result = await fetchSwapRequestsForMember(memberId);
					result.sort((a, b) => b.fromShift.baseDay - a.fromShift.baseDay);
					swapShift = result;
				}
				setIsLoading(false);
				setLoadMoreSearchResults(false);
			} else {
				swapShift = approveSwapShiftRequest.filter((swapShift) => {
					if (nameFilterKey === "") {
						return true;
					} else {
						const fromMemberName = swapShift?.fromMember?.id
							? personUtils.displayName(swapShift?.fromMember?.person) || swapShift?.fromMember?.orgUsername
							: swapShift?.fromMember?.orgUsername;
						const toMemberName = swapShift?.toMember?.id
							? personUtils.displayName(swapShift?.toMember?.person) || swapShift?.toMember?.orgUsername
							: swapShift?.toMember?.orgUsername;
						return filterByName(fromMemberName, nameFilterKey) || filterByName(toMemberName, nameFilterKey);
					}
				});
			}
		}
		if (requestType === "" || requestType === "giveAway") {
			if (fetch) {
				setIsLoading(true);
				if (memberId) {
					const result = await fetchGiveAwaysForMembers(memberIds);
					result.sort((a, b) => b.shift.baseDay - a.shift.baseDay);
					giveAway = result;
				}
				setIsLoading(false);
				setLoadMoreSearchResults(false);
			} else {
				giveAway = approveGiveAwayRequest.filter((giveAway) => {
					if (nameFilterKey === "") {
						return true;
					} else {
						const memberName = giveAway?.member?.id
							? personUtils.displayName(giveAway?.member.person) || giveAway?.member?.orgUsername
							: giveAway?.member?.orgUsername;
						const fromMemberName = giveAway?.fromMember?.id
							? personUtils.displayName(giveAway?.fromMember?.person) || giveAway?.fromMember?.orgUsername
							: giveAway?.fromMember?.orgUsername;
						return filterByName(memberName, nameFilterKey) || filterByName(fromMemberName, nameFilterKey);
					}
				});
			}
		}
		setFilteredTimeOff(timeOff);
		setFilteredSwapShift(swapShift);
		setFilteredGiveAway(giveAway);
	};

	const getResultsCount = () => {
		let count = 0;
		if (!filterRequestLists) {
			return null;
		}
		if (typeFilter === "" || typeFilter === "timeOff") {
			count += filteredTimeOff.length;
		}
		if (typeFilter === "" || typeFilter === "swapShift") {
			count += filteredSwapShift.length;
		}
		if (typeFilter === "" || typeFilter === "giveAway") {
			count += filteredGiveAway.length;
		}
		return count;
	};

	return (
		<>
			<ul className="flex flex-col mt-4divide-y w-full">
				<FilterBar
					typeFilter={typeFilter}
					handleFilter={handleFilter}
					setTypeFilter={setTypeFilter}
					nameFilter={nameFilter}
					setNameFilter={setNameFilter}
					setFilterRequestLists={setFilterRequestLists}
					resultsCount={getResultsCount()}
				/>
				{typeFilter === "" || typeFilter === "timeOff" ? renderTimeOffRequests() : null}
				{typeFilter === "" || typeFilter === "swapShift" ? renderSwapShiftRequest() : null}
				{typeFilter === "" || typeFilter === "giveAway" ? renderGiveAwayRequest() : null}
			</ul>
			{nameFilter && !loadMoreSearchResults && (
				<div className="flex justify-center mt-5">
					<button
						className="inline-flex items-center py-1 px-4 font-medium border border-transparent rounded-md shadow-sm text-white bg-darkww-500 text-right flex justify-end"
						onClick={() => {
							setLoadMoreSearchResults(true);
							handleFilter(typeFilter, nameFilter, true);
						}}
					>
						<span className="sm:block hidden">Load More</span>
					</button>
				</div>
			)}
		</>
	);
};

export default React.memo(ApproveRequests);

const FilterBar = ({ typeFilter, handleFilter, setTypeFilter, nameFilter, setNameFilter, setFilterRequestLists, resultsCount }) => {
	return (
		<div className="py-3 pt-2 -mt-4 bg-blue-50 rounded-t-lg">
			<div className="flex flex-wrap items-end gap-4">
				<div className="ml-2 font-bold text-gray-600 text-lg">Filter:</div>
				<div className="flex flex-col">
					<label htmlFor="filterType" className="block text-sm font-medium text-gray-700">
						Request Type
					</label>
					<select
						id="filterType"
						value={typeFilter}
						onChange={(e) => handleFilter(e.target.value, nameFilter)}
						className="mt-1 block w-48 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-darkww-500 focus:border-darkww-500 sm:text-sm"
					>
						{Object.keys(requestTypes).map((key) => (
							<option key={key} value={key === "all" ? "" : key}>
								{requestTypes[key]}
							</option>
						))}
					</select>
				</div>

				<div className="flex flex-col">
					<label htmlFor="filterName" className="block text-sm font-medium text-gray-700">
						Staff Name
					</label>
					<input
						type="text"
						id="filterName"
						value={nameFilter}
						onChange={(e) => handleFilter(typeFilter, e.target.value)}
						className="mt-1 block w-48 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-darkww-500 focus:border-darkww-500 sm:text-sm"
						placeholder="Enter staff name"
						autoComplete="off"
					/>
				</div>
				{resultsCount && <div className="flex items-center justify-center w-8 h-8 bg-white text-black rounded-full">{resultsCount}</div>}

				<div className="flex items-end">
					<button
						onClick={() => {
							setNameFilter("");
							setTypeFilter("");
							setFilterRequestLists(false);
						}}
						className="inline-flex items-center px-4 py-1 text-sm font-medium rounded-md shadow-sm text-white bg-darkww-600 hover:bg-darkww-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500"
					>
						Clear
					</button>
				</div>
			</div>
		</div>
	);
};

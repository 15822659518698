import { ClockIcon, DocumentTextIcon, DownloadIcon, ExclamationCircleIcon, PencilAltIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, ComboBox, ConfirmModal, Form, TextArea, Tooltip, Yup, dateMeta } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";
import { calculateCheckInDate, getDueCheckIns, getLateCheckIns } from "../../../../../ww-stores/Organisation/checkIns";
import { deleteFileById, deleteFileByIdFromStorage, getSignedUrl, saveFileToStorage } from "../../../files";

const checkInSchema = Yup.object().shape({
	checkIn: Yup.object().shape({
		id: Yup.string().required(),
		name: Yup.string().required(),
		description: Yup.string().required()
	}),
	checkInNote: Yup.string().required().min(2)
});

const CompleteCheckIns = ({ currentMemberOrgUserId, handleChange }) => {
	const { organisation } = useContext(OrganisationContext);
	const [currentMember, setCurrentMember] = useState(organisation.members.find((member) => member.orgUserId === currentMemberOrgUserId));
	const [orgCheckIns, setOrgCheckIns] = useState([]);
	const [selectedCheckIn, setSelectedCheckIn] = useState(null);
	const [completedSelectedCheckIn, setCompletedSelectedCheckIn] = useState(null);
	const memberStartDate = currentMember?.startDate ? new Date(currentMember.startDate * 1000) : undefined;
	const [startDate, setStartDate] = useState(memberStartDate);
	const [isSavingStartDate, setIsSavingStartDate] = useState(false);
	const [isSavingCheckIn, setIsSavingCheckIn] = useState(false);

	const formattedStartDate = startDate ? startDate.toISOString().split("T")[0] : undefined;

	const initValues = { checkIn: selectedCheckIn, checkInNote: "" };
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	useEffect(() => {
		const m = organisation.members.find((member) => member.orgUserId === currentMemberOrgUserId);
		setCurrentMember(m);
		let checkIns = organisation.checkIns.map((checkIn) => ({
			id: checkIn.id,
			name: checkIn.name,
			duration: checkIn.duration,
			unit: checkIn.unit,
			description: `After ${checkIn.duration} ${checkIn.unit}`
		}));
		checkIns = checkIns.sort((a, b) => {
			const checkInDateA = calculateCheckInDate(new Date(), a.duration, a.unit);
			const checkInDateB = calculateCheckInDate(new Date(), b.duration, b.unit);
			return checkInDateA - checkInDateB;
		});
		setOrgCheckIns(checkIns);
	}, [currentMemberOrgUserId, organisation]);

	useEffect(() => {
		updateCompletedSelectedCheckInState(selectedCheckIn, currentMember);
	}, [currentMember, selectedCheckIn]);

	const checkInsList = ({ selected, active, option: cI }) => {
		const completedCI = currentMember.completedCheckIns.find((cCheckIn) => cCheckIn.checkInID === cI.id);
		const isComplete = completedCI ? true : false;
		return (
			<div
				onClick={() => {
					setSelectedCheckIn(cI);
					updateCompletedSelectedCheckInState(cI, currentMember);
				}}
			>
				<Tooltip trigger={isComplete ? ["hover"] : []} overlay={"Check-In is complete"} placement="top">
					<div className={`flex items-center ${isComplete ? "text-green-500" : ""}`}>
						{isComplete && <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />}
						<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-1 block truncate")}>{cI.name}</span>
						<span className="px-2">{"-"}</span>
						<span className="text-xs sm:text-xs text-gray-500">{cI.description}</span>
					</div>
				</Tooltip>

				{selected ? (
					<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center px-4")}>
						{/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
					</span>
				) : null}
			</div>
		);
	};

	const updateCompletedSelectedCheckInState = async (cI, currentMember) => {
		const completedCI = currentMember.completedCheckIns.find((cCheckIn) => cCheckIn.checkInID === cI?.id);
		const isComplete = completedCI ? true : false;
		setCompletedSelectedCheckIn(isComplete ? completedCI : null);
	};

	if (!currentMember) {
		return null;
	}

	return (
		<>
			{!memberStartDate && (
				<div className="bg-rose-100 rounded-lg p-4 text-sm flex inline-block">
					<ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1" aria-hidden="true" />
					<span>To track check-ins, please add the start date from this staff's contract</span>
				</div>
			)}
			<div className="w-full mt-4 pt-2 pb-4 px-2 bg-gray-100 rounded-lg shadow-md">
				<div className="flex flex-col lg:flex-row items-start justify-center">
					<ContractFileView currentMemberDetail={currentMember} className="lg:w-1/2 lg:mr-4" />
					<div className="lg:w-1/2 lg:ml-4 flex flex-row items-start justify-center">
						<div className="flex flex-1 flex-col justify-start">
							<label className="text-xs font-medium text-gray-900">Start Date</label>
							<input
								type="date"
								className="mt-1 block pl-3 py-1 text-base border-gray-300 focus:outline-none focus:ring-lightww-500 focus:border-lightww-500 sm:text-sm rounded-md"
								value={formattedStartDate}
								onChange={(e) => setStartDate(new Date(e.target.value))}
							/>
						</div>
						<div className="flex flex-1 flex-row justify-end mt-3">
							{startDate && memberStartDate?.getTime() !== startDate.getTime() && (
								<Button
									type="button"
									label="Save"
									loading={isSavingStartDate}
									onClick={async () => {
										setIsSavingStartDate(true);
										await handleChange(dateMeta(new Date(startDate)).baseEpoch, currentMember, "Start Date");
										setIsSavingStartDate(false);
									}}
								/>
							)}
						</div>
					</div>
				</div>

				{memberStartDate && (
					<div className="flex flex-1 flex-col justify-start mt-5">
						<LateCheckInsComponent
							startDate={startDate}
							checkIns={organisation.checkIns}
							completedCheckIns={currentMember.completedCheckIns}
						/>
						<DueCheckInsComponent
							startDate={startDate}
							checkIns={organisation.checkIns}
							completedCheckIns={currentMember.completedCheckIns}
						/>
						<Form
							validationSchema={checkInSchema}
							enableReinitialize={true}
							disabled={isSavingCheckIn}
							initialValues={initValues}
							onSubmit={async (data, { resetForm }) => {
								try {
									setIsSavingCheckIn(true);
									const completedCheckInData = {
										checkInID: data.checkIn.id,
										checkInNote: data.checkInNote,
										date: dateMeta(new Date()).baseEpoch,
										organisationID: organisation.id,
										memberID: currentMember.orgUserId
									};
									await handleChange(completedCheckInData, currentMember, "completed-checkIn");
									setIsSavingCheckIn(false);
									resetForm();
								} catch (error) {
									setIsSavingCheckIn(false);
								}
							}}
						>
							<span className="text-xs sm:text-xs text-gray-500">Select a check-in to update the staff check-in details</span>
							<ComboBox name="checkIn" options={orgCheckIns} ComboBoxOption={checkInsList} initValue={initValues.checkIn} />
							{selectedCheckIn && !completedSelectedCheckIn && (
								<div>
									<div className="h-full flex pt-1 pb-2 items-end col-span-9 sm:col-span-9 lg:col-span-3">
										<div className="w-full">
											<TextArea label="Check-In Notes" name="checkInNote" />
										</div>
									</div>
									<div className="inline-block pt-4 flex justify-end">
										<Button type="submit" label="Save" loading={isSavingCheckIn} />
									</div>
								</div>
							)}
							{completedSelectedCheckIn && (
								<div>
									<div className="flex items-center text-green-500 mb-2 ">
										<CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
										<span className="font-normal ml-1 block truncate">{selectedCheckIn.name}</span>
										<span className="text-sm ml-1 block truncate text-gray-700">- is complete</span>
									</div>
									<div className="bg-white border border-gray-300 p-2 rounded-lg">
										<div className="flex items-center text-gray-900 mb-1">
											<PencilAltIcon className="h-5 w-5" aria-hidden="true" />
											<span className="text-base sm:text-sm">· Check-In Notes</span>
										</div>
										<span className="text-sm sm:text-sm text-gray-500">{completedSelectedCheckIn.note}</span>
									</div>
								</div>
							)}
						</Form>
					</div>
				)}
			</div>
		</>
	);
};

const ContractFileView = ({ currentMemberDetail }) => {
	const { organisation } = useContext(OrganisationContext);
	const [contractFile, setContractFile] = useState(null);
	const [activeFolder, setActiveFolder] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [openDownloadConfirm, setOpenDownloadConfirm] = useState({ flag: false, id: null });
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState({ flag: false, id: null });

	useEffect(() => {
		getContractFolderAndFile();
	}, []);

	async function getContractFolderAndFile() {
		const folderExist = await orgUtils.getStoredFolderByFilter(organisation.id, { name: { eq: "_contracts" } }, "protected");
		const contractFileName = `_${currentMemberDetail.person}`;
		if (folderExist.items.length === 0) {
			const folder = await orgUtils.storeFolder({
				name: "_contracts",
				organisationID: organisation.id
			});
			setActiveFolder(folder);
			if (folder.files.items.length > 0) {
				const file = folder.files.items.find((file) => file.name.startsWith(contractFileName));
				setContractFile(file);
			} else {
				setContractFile(null);
			}
		} else {
			setActiveFolder(folderExist?.items[0]);
			if (folderExist.items[0].files.items.length > 0) {
				const file = folderExist.items[0].files.items.find((file) => file.name.startsWith(contractFileName));
				setContractFile(file);
			} else {
				setContractFile(null);
			}
		}
		setIsLoading(false);
	}

	const uploadStaffContract = async ({ selectedFile }) => {
		if (!selectedFile) {
			console.error("No file selected");
			return;
		}
		// const fileName = selectedFile.name.split(".")[0];
		const fileName = `_${currentMemberDetail.person}`; //underscore to protect the file
		try {
			const fileId = uuidv4();
			const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."));
			const filePath = `${currentMemberDetail.id}/${activeFolder.id}/${fileName}${ext}`;
			const fileURI = await saveFileToStorage(selectedFile, filePath);
			const fileDetail = {
				id: fileId,
				folderId: activeFolder.id,
				name: `${fileName}${ext}`,
				organisationID: organisation.id,
				type: selectedFile.type,
				size: selectedFile.size,
				fileURI: fileURI,
				createdAt: new Date()
			};
			await orgUtils.storeFile(fileDetail);
			await getContractFolderAndFile();
		} catch (error) {
			console.error("Error during contract upload:", error);
		}
	};

	const deleteContractFile = async (id) => {
		try {
			await deleteFileById(id);
			await deleteFileByIdFromStorage(contractFile.fileURI);
			getContractFolderAndFile();
		} catch (err) {
			console.log("error in deleteFile", err);
		}
	};

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		setIsLoading(true);
		await uploadStaffContract({ selectedFile: file });
		setIsLoading(false);
	};

	const handlerDownloadConfirmModal = async (e, type) => {
		e.preventDefault();

		if (type === "confirm") {
			let toRedirect = await getSignedUrl(contractFile.fileURI);
			window.open(toRedirect, "_blank", "noopener,noreferrer");
		}
		setOpenDownloadConfirm({ flag: false, id: null });
	};

	const handlerDeleteConfirmModal = async (e, type) => {
		e.preventDefault();

		if (type === "confirm") {
			await deleteContractFile(openDeleteConfirm.id);
		}
		setOpenDeleteConfirm({ flag: false, id: null });
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center ">
				<div
					className="h-8 w-8 mr-2 animate-spin rounded-full border-4 border-solid border-blue-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
					role="status"
				></div>
				<div className="flex justify-center relative inline-block mr-4 mt-2">
					<div>Loading Contract</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{!contractFile && !isLoading && (
				<div className="flex mr-auto justify-between items-end mt-3">
					<Button type="button" label="Upload Staff Contract" onClick={() => document.getElementById("fileUpload").click()} />
					<input type="file" id="fileUpload" style={{ display: "none" }} onChange={handleFileUpload} />
				</div>
			)}
			{contractFile && (
				<div
					style={{ width: "50%" }}
					className=" mt-1 mb-4 shadow overflow-hidden divide-y divide-gray-200 border-b border-gray-200 rounded-md sm:rounded-lg"
				>
					<ConfirmModal
						handlerModal={handlerDownloadConfirmModal}
						open={openDownloadConfirm?.flag}
						className="text-base font-medium text-gray-900"
						title={`Do you want to download this file ?`}
					/>
					<ConfirmModal
						handlerModal={handlerDeleteConfirmModal}
						open={openDeleteConfirm?.flag}
						className="text-base font-medium text-gray-900"
						title={`Do you want to delete this file ?`}
					/>
					<div className="grid grid-cols-12 gap-6 pl-2 py-1">
						<div className="ml-1 sm:ml-0 py-1 mt-1 col-span-6 sm:col-span-6 flex flex-col">
							<span className="px-2 text-sm sm:text-md flex items-center text-blue-500">
								<DocumentTextIcon className="h-6 w-6 mr-1" />
								Staff Contract
							</span>
						</div>
						<div className="col-span-2 px-2 lg:px-6 flex justify-center">
							<button
								className="focus:outline-none font-medium rounded-lg mr-2 text-sm py-0.5 px-1"
								onClick={() => setOpenDownloadConfirm({ flag: true, id: contractFile.id })}
							>
								<DownloadIcon className="h-6 w-6 text-gray" />
							</button>
						</div>
						<div className="col-span-4 px-2 lg:px-6 text-sm font-medium flex justify-center items-center">
							<button
								className="focus:outline-none text-white bg-red-700 font-medium rounded-lg mr-2 text-sm py-0.5 px-1"
								onClick={() => setOpenDeleteConfirm({ flag: true, id: contractFile.id })}
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const DueCheckInsComponent = ({ startDate, checkIns, completedCheckIns }) => {
	let dueCheckIns = getDueCheckIns({ startDate, checkIns, completedCheckIns });
	if (dueCheckIns.length === 0) {
		return null;
	}

	return (
		<div className="my-2 px-1 py-1.5 border border-green-500 rounded">
			<div className="flex items-center font-bold mb-1">
				<ClockIcon className="h-5 w-5" aria-hidden="true" />
				<span className="text-base sm:text-sm ml-1">Due Check-Ins</span>
			</div>
			<ul className="text-sm sm:text-sm">
				{dueCheckIns.map((checkIn, index) => (
					<li key={index}>
						<span>- </span>
						<span>{checkIn.name}</span>
						<span className="ml-1 text-xs">{`(${checkIn.description})`}</span>
						<span
							className={`ml-1 px-1 py-0.5 text-xs rounded ${
								checkIn.daysLeft < 3 ? "bg-red-600" : checkIn.daysLeft < 14 ? "bg-yellow-600" : "bg-green-600"
							} text-white`}
						>{`in ${checkIn.daysLeft} days`}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

const LateCheckInsComponent = ({ startDate, checkIns, completedCheckIns }) => {
	let lateCheckIns = getLateCheckIns({ startDate, checkIns, completedCheckIns });

	if (lateCheckIns.length === 0) {
		return null;
	}

	return (
		<div className="my-2 px-1 py-1.5 border border-red-500 rounded">
			<div className="flex items-center text-red-700 font-bold mb-1">
				<ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />
				<span className="text-base sm:text-sm ml-1">Late Check-Ins</span>
			</div>
			<ul className="text-sm sm:text-sm text-red-600">
				{lateCheckIns.map((checkIn, index) => (
					<li key={index}>
						<span>- </span>
						<span>{checkIn.name}</span>
						<span className="ml-1 text-xs">{`(${checkIn.description})`}</span>
						<span className="ml-1 px-1 py-0.5 text-xs rounded bg-red-600 text-white">{`${checkIn.daysLate} days late`}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default CompleteCheckIns;

import React from "react";
import { AppProviders, AuthRoute, BrowserRouter, PageNotFound, Route, RouteModal, Routes, useLocation } from "ww-framework";
import Home from "./Views/General/Home";
import Administration from "./Views/Organisation/Administration";
import GeneralBoard from "./Views/Organisation/Board";
import Drive from "./Views/Organisation/Drive";
import LocationsReport from "./Views/Organisation/LocationsReport";
import Messages from "./Views/Organisation/Messages/Messages";
import StaffMembers from "./Views/Organisation/Requests";
import { LabelColourProvider } from "./Views/Organisation/Settings/Roles";
import Subscribe from "./Views/Organisation/Subscribe";
import ForgotPassword from "./Views/Person/ForgotPassword";
import InvitationsList from "./Views/Person/InvitationsList";
import Profile from "./Views/Person/Profile";
import SignIn from "./Views/Person/SignIn";
import SignUp from "./Views/Person/SignUp";
import Support from "./Views/Person/Support";
import { LandingPage, PrivacyPoclicy, TermsOfService } from "./Views/Web_Page";
import TechBlog3 from "./Views/Web_Page/blogs//getting-ready-for-a-challenging-2024-in-hospitality-retail";
import TechBlog4 from "./Views/Web_Page/blogs/Financial-Aid-and-Grants-Guide-for-Irish-Hospitality-Venues";
import TechBlog1 from "./Views/Web_Page/blogs/How-Technology-is-Revolutionising-Employee-Management-in-the-Hospitality-Industry";
import Blogs from "./Views/Web_Page/blogs/blog";
import TechBlog2 from "./Views/Web_Page/blogs/the-hidden-costs-of-staff-turnover-and-top-3-ways-to-prevent-it";

const RouteNavigation = () => {
	const location = useLocation();
	const { pathname } = location;

	return (
		<>
			<Routes location={location.state?.backgroundLocation || location}>
				<Route path="/" element={<LandingPage />} />
				<Route path="/bar-restaurant-retail-blogs" element={<Blogs />} />
				<Route path="/How-Technology-is-Revolutionising-Employee-Management-in-the-Hospitality-Industry" element={<TechBlog1 />} />
				<Route path="/the-hidden-costs-of-staff-turnover-and-top-3-ways-to-prevent-it" element={<TechBlog2 />} />
				<Route path="/Financial-Aid-and-Grants-Guide-for-Irish-Hospitality-Venues" element={<TechBlog4 />} />
				<Route path="/getting-ready-for-a-challenging-2024-in-hospitality-retail" element={<TechBlog3 />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
				<Route path="/privacy" element={<PrivacyPoclicy />} />
				<Route path="/employee-manager-signin" element={<SignIn />} />
				<Route path="/employee-manager-signup" element={<SignUp />} />
				<Route path="/forgot" element={<ForgotPassword />} />
				<Route path="/dashboard" element={<AuthRoute component={Home} forAdmin={false} />} />
				<Route path="/profile" element={<AuthRoute component={Profile} forAdmin={false} />} />
				<Route path="/requests" element={<AuthRoute component={StaffMembers} forAdmin={false} />} />
				<Route path="/messages/*" element={<AuthRoute component={Messages} forAdmin={false} />} />
				<Route path="/administration/report" element={<AuthRoute component={LocationsReport} forAdmin={true} />} />
				<Route path="/administration/*" element={<AuthRoute component={Administration} forAdmin={true} />} />
				<Route path="/subs/" element={<AuthRoute component={Subscribe} forAdmin={true} />} />
				<Route path="/clock-in-out/*" element={<AuthRoute component={GeneralBoard} forAdmin={false} />} />
				<Route path="/invitations" element={<AuthRoute component={InvitationsList} forAdmin={false} />} />
				<Route path="/drive" element={<AuthRoute component={Drive} forAdmin={false} />} />
				<Route path="/support" element={<AuthRoute component={Support} forAdmin={false} />} />
				<Route path="*" element={<PageNotFound forAdmin={false} />} />
			</Routes>
			{location.state?.backgroundLocation && (
				<Routes>
					<Route
						path={pathname}
						element={
							<RouteModal file={location.state?.file} fullScreen={location.state?.fullScreen} compProps={location.state?.compProps} />
						}
					/>
				</Routes>
			)}
		</>
	);
};

function App() {
	return (
		<>
			<BrowserRouter>
				<AppProviders>
					<LabelColourProvider>
						<RouteNavigation />
					</LabelColourProvider>
				</AppProviders>
			</BrowserRouter>
		</>
	);
}

export default App;
